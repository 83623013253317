import moment from 'moment';
import { TimeEntry } from '../models/Task';

export function parseJSONToLowerCaseFirstChar(jsonString) {
  let jsonObject = JSON.parse(jsonString);
  if (Array.isArray(jsonObject)) {
    return jsonObject.map((item) =>
      parseJSONToLowerCaseFirstChar(JSON.stringify(item))
    );
  } else if (typeof jsonObject === 'object') {
    let newObject = {};
    for (let key in jsonObject) {
      let lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
      newObject[lowerCaseKey] = parseJSONToLowerCaseFirstChar(
        JSON.stringify(jsonObject[key])
      );
    }
    return newObject;
  } else {
    return jsonObject;
  }
}

export function getCurrentWeekStartEndDates(currentDate: Date) {
  const date = new Date(currentDate);

  const day = date.getDay();
  const diff = date.getDate() - day + (day == 0 ? -6 : 1); // Adjust to get the start date of the week
  const startDate = new Date(date.setDate(diff));
  const endDate = new Date(date.setDate(diff + 6)); // Adding 6 days to get the end date

  const dates = [];
  dates.push(startDate);
  dates.push(endDate);

  return dates;
}

export function getCurrentMonthStartEndDates(currentDate: Date) {
  const date = new Date(currentDate);

  const monthStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const monthEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const dates = [];
  dates.push(monthStartDate);
  dates.push(monthEndDate);

  return dates;
}

export function isSameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function isCurrentDay(date) {
  const currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  );
}

export function padZero(id, minLength): string {
  return id.toString().padStart(minLength, '0');
}

export function parseDateFromApi(dateString: any): Date {
  // Adjust parsing according to your date string format
  const dateParts = dateString.split('T')[0].split('-');
  const timeParts = dateString.split('T')[1].split(':');

  const year = +dateParts[2];
  const month = +dateParts[0] - 1; // Months are 0-based in JavaScript Date
  const day = +dateParts[1];
  const hours = +timeParts[0];
  const minutes = +timeParts[1];
  const seconds = +timeParts[2];
  return new Date(year, month, day, hours, minutes, seconds);
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function calculateTotalHours(entries: TimeEntry[]): string {
  const totalMinutes = entries.reduce((total, entry) => {
    const start = moment(entry.startTime);
    const end = moment(entry.endTime);

    if (start.isValid() && end.isValid()) {
      const duration = moment.duration(end.diff(start));
      return total + duration.asMinutes();
    }

    return total;
  }, 0);

  const totalRoundedMinutes = Math.round(totalMinutes);
  const hours = Math.floor(totalRoundedMinutes / 60);
  const minutes = totalRoundedMinutes % 60;
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export function printDiv(divId: string) {
  const css = `<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">`;
  const printContents = document.getElementById(divId).innerHTML;
  const pageContent = `<!DOCTYPE html><html><head>${css}</head><body onload="window.print()">${printContents}</html>`;
  let popupWindow: Window;
  if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
    popupWindow = window.open(
      '',
      '_blank',
      'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
    );
    popupWindow.window.focus();
    popupWindow.document.write(pageContent);
    popupWindow.document.close();
    popupWindow.onbeforeunload = (event) => {
      popupWindow.close();
    };
    popupWindow.onabort = (event) => {
      popupWindow.document.close();
      popupWindow.close();
    };
  } else {
    popupWindow = window.open('', '_blank', 'width=600,height=600');
    popupWindow.document.open();
    popupWindow.document.write(pageContent);
    popupWindow.document.close();
  }
}

export const randomColors = [
  {
    start: 'rgba(255, 99, 132, 0.2)',
    mid: 'rgba(255, 99, 132, 0.3)',
    end: 'rgba(255, 99, 132, 0.5)',
    hover: 'rgba(255, 99, 132, 1)',
    border: 'rgba(255, 99, 132, 0.2)',
  },
  {
    start: 'rgba(54, 162, 235, 0.2)',
    mid: 'rgba(54, 162, 235, 0.3)',
    end: 'rgba(54, 162, 235, 0.5)',
    hover: 'rgba(54, 162, 235, 1)',
    border: 'rgba(54, 162, 235, 0.2)',
  },
  {
    start: 'rgba(75, 192, 192, 0.2)',
    mid: 'rgba(75, 192, 192, 0.3)',
    end: 'rgba(75, 192, 192, 0.5)',
    hover: 'rgba(75, 192, 192, 1)',
    border: 'rgba(75, 192, 192, 0.2)',
  },
  {
    start: 'rgba(255, 206, 86, 0.2)',
    mid: 'rgba(255, 206, 86, 0.3)',
    end: 'rgba(255, 206, 86, 0.5)',
    hover: 'rgba(255, 206, 86, 1)',
    border: 'rgba(255, 206, 86, 0.2)',
  },
  {
    start: 'rgba(153, 102, 255, 0.2)',
    mid: 'rgba(153, 102, 255, 0.3)',
    end: 'rgba(153, 102, 255, 0.5)',
    hover: 'rgba(153, 102, 255, 1)',
    border: 'rgba(153, 102, 255, 0.2)',
  },
  {
    start: 'rgba(255, 159, 64, 0.2)',
    mid: 'rgba(255, 159, 64, 0.3)',
    end: 'rgba(255, 159, 64, 0.5)',
    hover: 'rgba(255, 159, 64, 1)',
    border: 'rgba(255, 159, 64, 0.2)',
  },
];
