<div class="modal-body">
  <form class="mt-3">
    <div class="form-group">
      <label for="name">Name</label>
      <input type="text" class="form-control" id="name" [(ngModel)]="project.name" name="name" required>
    </div>

    <div class="form-group">
      <label for="description">Description</label>
      <textarea class="form-control" id="description" [(ngModel)]="project.description" name="description"
        rows="5"></textarea>
    </div>

    <hr class="mb-4">
    <h5 class="mb-2">Project Logo</h5>

    <div class="d-flex flex-column">
      <div class="d-flex flex-row align-items-center">
        <img *ngIf="currentFile || project.icon; else noPhoto" [src]="preview" class="preview">
        <input type="file" name="fileInput" #fileInput accept="image/*" (change)="selectFile($event)" hidden />

        <ng-template #noPhoto>
          <svg class="preview"
            style="vertical-align: middle;fill: #b3b3b3;overflow: hidden;border-radius: 10px;"
            version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 350"
            xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 350 350">
            <path
              d="M5,350h340V0H5V350z M25,330v-62.212h300V330H25z M179.509,247.494H60.491L120,171.253L179.509,247.494z   M176.443,211.061l33.683-32.323l74.654,69.05h-79.67L176.443,211.061z M325,96.574c-6.384,2.269-13.085,3.426-20,3.426  c-33.084,0-60-26.916-60-60c0-6.911,1.156-13.612,3.422-20H325V96.574z M25,20h202.516C225.845,26.479,225,33.166,225,40  c0,44.112,35.888,80,80,80c6.837,0,13.523-0.846,20-2.518v130.306h-10.767l-104.359-96.526l-45.801,43.951L120,138.748  l-85.109,109.04H25V20z" />
          </svg>
        </ng-template>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-items-center mb-1">
            <button for="fileInput" class="btn btn-sm btn-danger mt-1" (click)="fileInput.click()"
              [disabled]="progress > 0 && progress < 99">{{!currentFile
              ?'Choose Photo' : 'Change Photo'}}</button>

            <button *ngIf="currentFile && progress === 0" class="btn btn-success btn-sm ms-2 mt-1"
              (click)="uploadPhoto()">
              Upload
            </button>
          </div>
          <div>
            <p-progressBar *ngIf="progress > 0" [value]="progress" [style]="{ height: '12px' }"></p-progressBar>
          </div>
        </div>
      </div>

    </div>
    <hr class="mb-4">

    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">{{project.id > 0 ?
        'Update Project' : 'Save Project'}}</button>
      <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
    </div>
  </form>
</div>
