export class Campaigns {
    id!: number;
    dateCreated: Date;
    name!: string | null;
    description!: string | null;
    startDate!: Date | null;
    endDate!: Date | null;
    createdBy!: number | null;
    statusId!: number;
    OrgId!: number;
    campaignTypeId: number;
    productId: number;
    clientId: number;
    isActive: boolean;
    allocatedBudget:number;
    campaignAssets: CampaignAssets[] = [];
    assetTypesJson:string;
    totalRecords:number;
    rowNumber:number;
    statusName: string;
    campaignTypeName: string;
    bgColor:string;
    color:string;
    projectId:number;
    groupIds: number[] = [];
    groups:string;
  }

  export class CampaignType {
    id!: number;
    typeName!: string | null;
    purpose!: string | null;
    useCase!: string | null;
  }

  export class CampaignAssets {
    id!: number;
    campaignId!: number;
    assetTypeId!: number;
    assetName!: string | null;
    assetURL!: string | null;
  }

  export class CampaignsFilter
{
  id!: number | null;
  query!: string | null;
  clientType!:string | null;
  fromDate!: Date | null;
  toDate!: Date | null;
  pageNumber!: number;
  pageSize!: number;
  projectId:number;
}