<div class="container">
  <div class="page-head-container" style="margin: -5px -5px 0.5rem -5px !important;">
    <h4 class="page-title"><i class="fa-regular fa-layer-group"></i> {{ "Groups" | translate }}
    </h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <button class="btn btn-primary action-button text-nowrap" (click)="addNewGroup(null)" [hasPermission]="{ access: 'addUpdateGroup', action: 'disable' }" ><i
          class="fa-light fa-plus"></i> {{ "New" | translate }}</button>
      <button class="btn btn-sm btn-outline-dark action-button-outline ml-1 " (click)="toggleFilter()"><i
          class="fa-light fa-filter-list"></i>
        {{ "Apply Filters" | translate }}</button>
    </div>
  </div>
  <div [@slide]="slideState" class="filter-container">
    <app-groups-filters (onHideRequest)="onHideFilterRequest()" (onSearchRequest)="onSearchRequest($event)" />
  </div>

  <div>
    <div class="table-responsive activity-tables" style="overflow-y: auto; height: calc(100vh - 265px);"
      *ngIf="leadGroups != null && leadGroups.length > 0 ; else !isLoading ? noData : loader">
      <table class="table table-hover">
        <thead class="sticky-header">
          <tr>
            <th>#</th>
            <th>Group Name</th>
            <th>Group Type</th>
            <th>Priority Level</th>
            <th>Created At</th>
            <th>Total leads</th>
            <th class="text-right"><span class="me-3">{{ "Actions" | translate }}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of leadGroups; let rowIndex = index">
            <td>
              <div class="">{{ rowIndex+1 }}</div>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <div> <a class="clientname" [hasPermission]="{ access: 'campaign', action: 'preventClick' }"
                    tooltipPosition="top" [pTooltip]="item?.description" title="View Campaign Overview">
                    {{ item.groupName }}
                  </a></div>
              </div>
            </td>
            <td>
              <div class="ellipsis" style="width: 150px;">{{ item.groupType }}</div>
            </td>
            <td>
              <div class="badge rounded-pill" [style.color]="item.foreColor" [style.backgroundColor]="item.bgColor">{{
                item.priorityName | translate }}</div>
            </td>
            <td>
              <div class="ellipsis" style="width: 85px;">{{ item.dateCreated | date }}</div>
            </td>
            <td>
              <div class="ellipsis" style="width: 85px;">{{ item.totalLeads}}</div>
            </td>
            <td class="text-right pr-4">
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  {{ "Options" | translate }}
                </button>
                <div class="dropdown-menu">

                  <button class="dropdown-item table-action-btn mb-2" (click)="bulkUploadLead(item.id)" [hasPermission]="{ access: 'bulk-data-import', action: 'disable' }"><i
                    class="fa-light fa-file-import"></i>
                  {{ "Bulk Upload Lead" | translate }}</button>
                  <button class="dropdown-item table-action-btn mb-2"
                     (click)="addNewGroup(item)" [hasPermission]="{ access: 'addUpdateGroup', action: 'disable' }" >
                    <i class="fa-regular fa-pencil mr-1"></i>
                    {{"Edit Lead Group" | translate}}</button>
                  <button class="dropdown-item table-action-btn" *ngIf="item.totalLeads === 0"
                    
                    (click)="deleteLeadGroupById(item.id)" [hasPermission]="{ access: 'deleteGroup', action: 'disable' }" ><i class="fa-regular fa-trash mr-1"></i>
                    {{"Delete Lead Group" | translate}}</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination DIV -->
    <div class="pagination-container">
      <div class="d-flex align-items-center">
        <span class="mr-3">Page {{ currentPage }} of {{ calculateTotalPages(totalCount, pageSize) }}</span>
        <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="
          width: 68px;
          text-align: center;
          font-size: 12px;">
          <option class="dropdown-item d-flex align-items-center" value="10">10</option>
          <option class="dropdown-item d-flex align-items-center" value="20">20</option>
          <option class="dropdown-item d-flex align-items-center" value="50">50</option>
          <option class="dropdown-item d-flex align-items-center" value="100">100</option>
        </select>
      </div>



      <div style="max-width: 500px;">
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
          <i class="fa-sharp fa-solid fa-angles-left"></i></button>
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
          [disabled]="currentPage === 1"><i class="fa-sharp fa-solid fa-angle-left"></i></button>
        <ng-container *ngFor="let page of displayedPages">
          <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
            [class.active]="page === currentPage">
            {{ page }}
          </button>
        </ng-container>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
            class="fa-sharp fa-solid fa-angle-right"></i></button>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
            class="fa-sharp fa-solid fa-angles-right"></i></button>
      </div>
    </div>
  </div>

  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="No group has been created yet." height="calc(100vh - 315px)"></app-nodatafound>
  </ng-template>
</div>
