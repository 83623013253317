import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})

export class Result<T = any> {
    status!: string;
    statuscode!: number;
    data!: T;
    message!: string;
    responseCode:number;
}
