import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Result } from 'src/app/models/Result';
import { User } from 'src/app/models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { UserSetupComponent } from '../user-setup/user-setup.component';
import { padZero } from 'src/app/utils/utils';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css'],
})
export class ManageUsersComponent {
  public users: User[] = [];
  public filteredUsers: User[] = [];
  searchKeyword: string = '';
  isLoading: boolean = true;

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    public sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
      this.getAllUsers();
    });
  }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.users = resp.data;
          this.filteredUsers = this.users;
        }

        this.isLoading = false;
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
        this.isLoading = false;
      },
    });
  }

  searchUsers(): void {
    // Filter users based on the searchKeyword
    this.filteredUsers = this.users.filter((user) => {
      // Assuming you want to search in user's name and username
      return (
        user.name?.toLowerCase().includes(this.searchKeyword.toLowerCase()) ||
        user.userName?.toLowerCase().includes(this.searchKeyword.toLowerCase())
      );
    });
  }

  addNewUser() {
    if (!this.checkPermission()) {
      return;
    }

    const modalRef = this.dialogService.open(UserSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'New User',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        user: new User(),
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllUsers();
    });
  }

  viewUserProfile(user: User) {}

  disableOrEnableUser(user: User) {
    if (!this.checkPermission()) {
      return;
    }
    var isActive
    if (user.isActive) {
      isActive= false;
    }
    else{
      isActive= true;
    }

    this.confirmationService.confirm({
      message: 'Are you sure you want to change status ' + user.name + '?',
      header: 'Confirmation',
      icon: 'fa-regular fa-circle-info',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.apiService.disableOrEnableUser(user.id,isActive).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.getAllUsers();
              this.messageService.add({
                severity: 'success',
                summary: 'User Deleted',
                detail: resp.message,
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warnig',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            this.messageService.add({
              severity: 'warn',
              summary: 'Warnig',
              detail: 'Something went wrong',
              life: 3000,
            });
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  editUser(user: User) {
    if (this.authService.currentUserValue.id !== user.id) {
      if (!this.checkPermission()) {
        return;
      }
    }

    const modalRef = this.dialogService.open(UserSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'Edit User',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        user: user,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllUsers();
    });
  }

  deleteUser(user: User) {
    if (!this.checkPermission()) {
      return;
    }

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + user.name + '?',
      header: 'Confirmation',
      icon: 'fa-regular fa-circle-info',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.apiService.deleteUser(user.id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.getAllUsers();
              this.messageService.add({
                severity: 'success',
                summary: 'User Deleted',
                detail: resp.message,
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warnig',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            this.messageService.add({
              severity: 'warn',
              summary: 'Warnig',
              detail: 'Something went wrong',
              life: 3000,
            });
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }

  checkPermission() {
    if (this.authService.currentUserValue.userTypeId > 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warnig',
        detail: 'You are not allowed to perform this action',
        life: 3000,
      });

      return false;
    }
    return true;
  }
  resendInvitationUser(user){
    this.apiService.resendUserInvitation(user).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.getAllUsers();
          this.messageService.add({
            severity: 'success',
            summary: 'User Invitation Send',
            detail: resp.message,
            life: 3000,
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warnig',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        this.messageService.add({
          severity: 'warn',
          summary: 'Warnig',
          detail: 'Something went wrong',
          life: 3000,
        });
        console.error('error:', error);
      },
    });
  }
}
