import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
  catchError,
  of,
} from 'rxjs';
import { AssetTypes, AssetTypesList } from 'src/app/Models/AssetType';
import {
  CampaignAssets,
  Campaigns,
  CampaignType,
} from 'src/app/Models/Campaigns';
import { Clients } from 'src/app/Models/Clients';
import { LeadGroup } from 'src/app/Models/LeadGroups';
import { Products } from 'src/app/Models/Products';
import { Result } from 'src/app/models/Result';
import { LeadStatus } from 'src/app/models/Status';
import { User } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment.development';

@Component({
  selector: 'app-add-update-campaign',
  templateUrl: './add-update-campaign.component.html',
  styleUrl: './add-update-campaign.component.css',
})
export class AddUpdateCampaignComponent {
  public campaigns: Campaigns = new Campaigns();
  public usersList: User[] = [];
  public selectedUers: User[] = [];
  public selectedUersFilter: User[] = [];
  public campaignStatusList: LeadStatus[] = [];
  public campaignTypeList: CampaignType[] = [];
  public selectedLeadGroups: LeadGroup[] = [];
  public clientList: Clients[] = [];
  public productList: Products[] = [];
  public tempAssetTypeList: AssetTypesList[] = [];
  public assetTypeDbList: AssetTypes[] = [];
  public leadGroupsList: LeadGroup[] = [];
  public projectId = 0;
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  isDataSaving: boolean;
  leadFormatter_search = (name: any) => name;

  constructor(
    private apiService: ApiService,
    private authService: AuthServiceService,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private sharedService: SharedService,
    private messageService: MessageService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
    });
    //this.usersList = this.dialogConfig.data.users;
    this.projectId = this.dialogConfig.data.projectId;
    this.campaigns = this.dialogConfig.data.campaign;
    this.selectedUers = this.dialogConfig.data.selectedUers;
    this.selectedLeadGroups = this.dialogConfig.data.selectedLeadGroup;
    this.campaignStatusList = this.dialogConfig.data.CampaignStatusList;
    if (
      !this.campaigns.campaignAssets ||
      this.campaigns.campaignAssets?.length === 0
    ) {
      this.campaigns.campaignAssets = [];
      this.addAssetTypes();
      this.tempAssetTypeList = [];
    }
  }

  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getLeadGroups();
    this.getCampaignType();
    this.getClients();
    this.getProducts();
    this.getAssetTypes();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.campaigns.id > 0) {
        if (this.campaigns.startDate) {
          this.campaigns.startDate = new Date(this.campaigns.startDate);
        }
        if (this.campaigns.endDate) {
          this.campaigns.endDate = new Date(this.campaigns.endDate);
        }
      } else {
        this.campaigns.startDate = new Date();
      }
    }, 0);
  }

  addAssetTypes() {
    if (this.campaigns.campaignAssets.length > 0) {
      this.campaigns.campaignAssets.push(new CampaignAssets());
    } else {
      this.campaigns.campaignAssets = [];
      this.campaigns.campaignAssets.push(new CampaignAssets());
    }
  }
getLeadGroups() {
    this.apiService.getAllLeadGroups(this.projectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadGroupsList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }

  getCampaignType() {
    this.apiService.getCampaignType().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.campaignTypeList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getClients() {
    this.apiService.getClientList().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.clientList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getProducts() {
    this.apiService.getProductsList().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.productList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }

  getAssetTypes() {
    this.apiService.getAssetTypes().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.assetTypeDbList = resp.data;
        } else {
          this.assetTypeDbList = [];
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  removeAssetType(i) {
    if (this.campaigns.campaignAssets.length !== 1) {
      this.campaigns.campaignAssets.splice(i, 1);
    }
  }

  public validateAndSetData() {
    if (!this.campaigns.name) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the campaign name',
        life: 3000,
      });
      return false;
    }
    if (
      !this.campaigns.campaignAssets[0].assetTypeId ||
      this.campaigns.campaignAssets.length === 0
    ) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select atleast 1 Asset Type',
        life: 3000,
      });
      return false;
    }
    this.campaigns.createdBy = this.authService.currentUserValue.id;
    this.campaigns.assetTypesJson = JSON.stringify(
      this.campaigns.campaignAssets
    );
    this.campaigns.projectId = this.projectId;
    this.campaigns.groupIds = this.selectedLeadGroups.map((x) => x.id);
    return true;
  }

  searchLead = (text$: Observable<string>) =>
    text$.pipe(
      // Wait for 200ms after each keystroke
      debounceTime(200),
      // Ignore if the term is the same as before
      distinctUntilChanged()
      // Switch to a new Observable of API results
    );


  public onSubmit() {
    // Your form submission logic here
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.addUpdateCampaign(this.campaigns).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close();
            //this.getAllLeads();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          // Handle error
          this.isDataSaving = false;
          console.error('error:', error);
        },
      });
    }
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
