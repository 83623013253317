<!-- Plugin Activation Section -->
<div *ngIf="!isPluginActivated && !isProcessing && !isLoading">
  <div class="modal-body p-3" style="height: 265px;">
    <h6><i class="fas fa-plug text-primary me-1"></i> Activate Plugin</h6>
    <div class="activation-alert">Enter your Client ID and Auth Key. Once activated, you can access and configure
      additional features.</div>
    <div class="mt-3">
      <div class="form-group">
        <label for="clientId">Client ID</label>
        <input type="text" id="clientId" class="form-control" [(ngModel)]="clientId">
      </div>
      <div class="form-group mt-2">
        <label for="authKey">Auth Key</label>
        <input type="text" id="authKey" class="form-control" [(ngModel)]="authKey">
      </div>
      <div class="modal-footer pt-3">
        <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
          (click)="modalClose()">Cancel</button>
        <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="activatePlugin()">Activate
          Plugin</button>
      </div>
    </div>
  </div>
</div>

<!-- Loading Animation Section -->
<div *ngIf="isLoading">
  <div class="modal-body p-3 text-center"
    style="height: 265px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="mt-3">Getting Plugin Configuration, please wait...</div>
  </div>
</div>

<!-- Processing Animation Section -->
<div *ngIf="isProcessing">
  <div class="modal-body p-3 text-center"
    style="height: 265px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Processing...</span>
    </div>
    <div class="mt-3">Activating plugin, please wait...</div>
  </div>
</div>

<!-- Plugin Configuration Section -->
<div *ngIf="isPluginActivated && !isProcessing && !isLoading">
  <div class="modal-body p-3">
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <h6><i class="fas fa-sync-alt text-primary me-1"></i> Import & Sync Employees</h6>
        <!-- <span class="badge bg-warning text-dark">Auto Sync is Premium Feature</span> -->
        <button type="button" class="btn btn-sm action-button-danger mr-2" style="font-size: 11px;padding: 5px 10px;"
          (click)="deletePluginConfig()">Deactivate Plugin</button>
      </div>
      <div class="d-flex align-items-center mt-2 ml-3">
        <button class="btn btn-sm btn-outline-dark action-button-outline me-2"
          (click)="loadHrmsBranches(hrmsUsers, $event)">Import
          Employees</button>
        <button class="btn btn-sm btn-outline-dark action-button-outline me-2" (click)="syncUsers()"
          [disabled]="isSyncing">Start
          Sync</button>
        <button class="btn btn-sm btn-outline-dark action-button-outline" (click)="syncUsers()" [disabled]="true"
          [pTooltip]="'Premium feature'">Enable Auto
          Sync</button>
      </div>
      <div class="alert alert-secondary" role="alert" style="padding: 3px 6px;margin-top: 13px;">
        <span *ngIf="!isSyncing"><i class="fa-regular fa-circle-info me-1"></i>Total {{totalImportedEmployees}}
          employees
          imported from
          HRMS</span>

        <div *ngIf="isSyncing" class="d-flex align-items-center">
          <div class="spinner-border text-danger me-2" role="status"
            style="height: 15px; width: 15px; border-width: 2px;">
            <span class="visually-hidden">Processing...</span>
          </div>
          <div class="">Employee data synchronization is currently in progress. Please wait...</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="pl-3 mt-2">
      <h6><i class="fas fa-calendar-check text-success me-2"></i>HRMS Attendance</h6>
      <div class="form-check mt-3">
        <p-checkbox [(ngModel)]="settings.allowMarkAttendance" label="Allow Mark Attendance from Client Track"
          [binary]="true" (onChange)="toggleAttendanceOptions()"></p-checkbox>
      </div>
      <div [class.disabled]="!settings.allowMarkAttendance">
        <h6 class="mt-3">Attendance Options</h6>
        <div class="form-check mt-3">
          <p-checkbox [(ngModel)]="settings.autoMarkAttendance" [disabled]="!settings.allowMarkAttendance"
            label="Automatically Mark Attendance with Task Start & Stop" [binary]="true"
            (onChange)="toggleAutoMarkAttendance()"></p-checkbox>
        </div>
        <div [class.disabled]="!settings.allowMarkAttendance || settings.autoMarkAttendance" class="form-check mt-3">
          <p-checkbox [(ngModel)]="settings.allowUserChoice"
            [disabled]="!settings.allowMarkAttendance || settings.autoMarkAttendance"
            label="Allow users to choose whether to mark attendance on task start" [binary]="true"></p-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-3">
    <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
      (click)="modalClose()">Close</button>
    <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="saveSetting()">Save
      Settings</button>
  </div>
</div>

<!-- HRMS Users -->
<p-overlayPanel #hrmsUsers appendTo="body" [dismissable]="false">
  <ng-container *ngIf="!isLoadingHrmsEmp; else userLoader">
    <div class="p-3">
      <div class="d-flex align-items-center">
        <div class="form-group d-flex align-items-center">
          <label for="locationSelect" class="me-2 fw-600">Branch:</label>
          <p-dropdown id="locationSelect" [options]="hrmsLocations" [(ngModel)]="selectedLocation"
            optionLabel="LocationName" placeholder="Select a branch" (onChange)="filterByLocation()">
          </p-dropdown>
        </div>

        <div class="form-group d-flex align-items-center ms-3">
          <label for="userSearch" class="me-2 fw-600">Search:</label>
          <input id="userSearch" type="text" class="form-control search-box" placeholder="Search employees..."
            [(ngModel)]="searchQuery" (input)="filterByKeyword()">
        </div>
      </div>

      <div class="table-responsive"
        style="height: 270px;width: 800px;border: 2px solid #ccc;border-radius: 4px;overflow-y: scroll;">
        <table class="table table-hover">
          <thead>
            <tr class="sticky-header">
              <th>
                <p-checkbox [(ngModel)]="selectAll" [binary]="true" (onChange)="toggleAllCheckboxes()"></p-checkbox>
              </th>
              <th>ID</th>
              <th style="width: 370px;text-align: left;padding-left: 1rem !important;">Employee</th>
              <th style="text-align: left;">Department</th>
              <th>HRMS Imported</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of filteredEmployees">
              <td>
                <p-checkbox [(ngModel)]="user.selected" [binary]="true" [value]="user.selected"></p-checkbox>
              </td>
              <td>{{ user.seriesID }}</td>
              <td class="profile-name">
                <img
                  [src]="user.imageUrl === '~/images/profilephoto/noimage.jpg' ? 'https://d1zoy1lzkhwt04.cloudfront.net/rwf/Images/ProfilePhoto/noimage.jpg' : user.imageUrl"
                  [alt]="user.name" class="profile-img">
                <div>
                  <p class="mb-0 fw-500">{{ user.name }}</p>
                  <p class="mb-0">{{ user.email }}</p>
                  <p class="mb-0" style="color: #533000;">{{ user.jobTitle }}</p>
                </div>
              </td>
              <td>{{ user.department }}</td>
              <td class="text-center">
                <p class="badge status bg fs-12px mb-1"
                  [class]="user.isImported || user.successMessage ? 'not-started' : 'inactive'">{{
                  user.isImported || user.successMessage ? 'YES' : 'NO' }}</p>
                <p *ngIf="user.successMessage" class="mb-0 text-info fw-500 font-italic"
                  [pTooltip]="user.successMessage">
                  Import Success</p>
                <p *ngIf="user.errorMessage" class="mb-0 text-danger fw-500 font-italic" [pTooltip]="user.errorMessage">
                  Import Error</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer mt-3">
        <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
          (click)="hrmsUsers.toggle($event)">Close</button>
        <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="importUsers()">Import
          Employees</button>
      </div>
    </div>
  </ng-container>
  <ng-template #userLoader>
    <div class="p-3">
      <app-project-loading-indicator [height]="'270px'" [width]="'600px'" [message]="'Fetching employees from HRMS'" />
    </div>
  </ng-template>

</p-overlayPanel>
