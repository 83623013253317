import { Component, ViewChild } from '@angular/core';
import { extend, addClass } from '@syncfusion/ej2-base';
import {
  KanbanComponent,
  ColumnsModel,
  CardSettingsModel,
  SwimlaneSettingsModel,
  DialogSettingsModel,
  CardRenderedEventArgs,
  KanbanModule,
} from '@syncfusion/ej2-angular-kanban';
import { cardData } from './data';

@Component({
  selector: 'app-kanban-board-view',
  templateUrl: './kanban-board-view.component.html',
  styleUrl: './kanban-board-view.component.css'
})
export class KanbanBoardViewComponent {
  @ViewChild('kanbanObj') kanbanObj: KanbanComponent;
  public kanbanData: Object[] = extend([], cardData, null, true) as Object[];
  public columns: ColumnsModel[] = [
    { headerText: 'To Do', keyField: 'Open', allowToggle: true },
    { headerText: 'In Progress', keyField: 'InProgress', allowToggle: true },
    { headerText: 'In Review', keyField: 'Review', allowToggle: true },
    { headerText: 'Done', keyField: 'Close', allowToggle: true },
  ];
  public cardSettings: CardSettingsModel = {
    headerField: 'Title',
    template: '#cardTemplate',
    selectionType: 'Multiple',
  };
  public dialogSettings: DialogSettingsModel = {
    fields: [
      { text: 'ID', key: 'Title', type: 'TextBox' },
      { key: 'Status', type: 'DropDown' },
      { key: 'Assignee', type: 'DropDown' },
      { key: 'RankId', type: 'TextBox' },
      { key: 'Summary', type: 'TextArea' },
    ],
  };
  public swimlaneSettings: SwimlaneSettingsModel = { keyField: 'Assignee' };

  constructor() { }

  public getString(assignee: string) {
    return assignee
      .match(/\b(\w)/g)
      .join('')
      .toUpperCase();
  }
  cardRendered(args: CardRenderedEventArgs): void {
    const val: string = (<{ [key: string]: Object; }>args.data)[
      'Priority'
    ] as string;
    addClass([args.element], val);
  }
}
