<div class="modal-body">
    <form class="mt-3">
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="role.roleName" name="name" appSpecialCharsBlocker inputValidator [required]=true
        [min]=2 [max]=30>
      </div>
  
      <div class="form-group">
        <label for="description">Description</label>
        <textarea class="form-control" id="description" [(ngModel)]="role.description" name="description"
          rows="5" appSpecialCharsBlocker inputValidator
          [min]=3 [max]=100></textarea>
      </div>
  
      <hr class="mb-4">
      
  
      <div class="text-right">
        <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">{{role.id > 0 ?
          'Update Role' : 'Save Role'}}</button>
        <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
      </div>
    </form>
  </div>
  