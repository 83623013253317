import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DbColumn } from 'src/app/Models/DbColumn';
import { Result } from 'src/app/models/Result';
import { ApiService } from 'src/app/services/api.service';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-export-options-dialog',
  templateUrl: './export-options-dialog.component.html',
  styleUrl: './export-options-dialog.component.css',
})
export class ExportOptionsDialogComponent {
  startDate: Date;
  endDate: Date;
  availableCols: DbColumn[] = [];
  selectedCols: DbColumn[];
  loading = false;
  downloadUrl: string;
  progress: number = 0;
  filename: string = 'leads.xlsx';

  constructor(
    private dialogRef: DynamicDialogRef,
    private apiService: ApiService,
    private messageService: MessageService
  ) {
    const today = new Date();
    this.endDate = today;
    this.startDate = new Date(today);
    this.startDate.setDate(today.getDate() - 30); // Set startDate to 30 days ago
    this.selectedCols = this.availableCols;
  }

  ngOnInit(): void {
    this.getAvailableColumns();
  }

  getAvailableColumns() {
    this.apiService.getAvailableColumnsToExportLeads().subscribe({
      next: (cols: DbColumn[]) => {
        if (cols.length > 0) {
          this.availableCols = cols;
          this.selectedCols = this.availableCols;
        } else {
          this.availableCols = [];
        }
      },
      error: (error) => {
        // Handle error
        this.availableCols = [];
        console.error('error:', error);
      },
      complete: () => {},
    });
  }

  exportLeads() {
    if (!this.validate()) {
      return;
    }

    this.loading = true;

    const requestBody = {
      statusId: 0,
      fromDate:
        this.startDate || new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), //default 30 days
      toDate: this.endDate || new Date(),
      projectId: GlobalVariables.selectedProjectId,
      pageNumber: 1,
      pageSize: 10000000000000,
      selectedColumns: this.selectedCols,
    };

    this.apiService.exportLeads(requestBody).subscribe({
      next: (data) => {
        if (data.state === 'progress') {
          this.progress = data.progress; // Update progress bar
        } else if (data.state === 'completed' && data.file) {
          const blob = new Blob([data.file], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          this.filename = this.generateFilename();
          const a = document.createElement('a');
          a.href = url;
          a.download = this.filename;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      },
      error: (error) => {
        this.loading = false;
        this.progress = 0;
        console.error('Download error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Oops!',
          detail: 'Something went wrong',
          life: 3000,
        });
      },
    });
  }

  validate() {
    if (!this.formatDate || !this.endDate) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select a valid date range',
        life: 3000,
      });
      return false;
    }

    if (!this.selectedCols || this.selectedCols.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail:
          'You must select at least one column to include in the export. Please choose the columns you want to export before proceeding',
        life: 3000,
      });
      return false;
    }

    return true;
  }

  getSelectedItemsLabel(): string {
    const selectedCount = this.selectedCols.length;
    const totalCount = this.availableCols.length;

    if (selectedCount === totalCount && selectedCount > 0) {
      return 'All columns selected';
    } else if (selectedCount > 0) {
      return `${selectedCount} column${selectedCount > 1 ? 's' : ''} selected`;
    } else {
      return '';
    }
  }

  formatDate(date: Date | null): string {
    if (!date) {
      return 'unknown'; // or return an empty string, or any default value
    }
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = d.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  generateRandomNumber(): number {
    return Math.floor(Math.random() * 1000000); // Generates a random number between 0 and 999999
  }

  generateFilename(): string {
    const startDateFormatted = this.formatDate(this.startDate);
    const endDateFormatted = this.formatDate(this.endDate);

    // Generate a random number for the filename
    const randomNumber = this.generateRandomNumber();

    if (startDateFormatted === 'unknown' || endDateFormatted === 'unknown') {
      return `Leads_${randomNumber}.xlsx`; // Include random number
    }

    return `Leads_${startDateFormatted}_to_${endDateFormatted}.xlsx`;
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
