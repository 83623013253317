import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { GlobalVariables } from 'src/environments/environment';
import { Campaigns,CampaignsFilter } from 'src/app/Models/Campaigns';

@Component({
  selector: 'app-campaign-filters',
  templateUrl: './campaign-filters.component.html',
  styleUrl: './campaign-filters.component.css',
  animations: [
    trigger('childSlide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left <=> right', animate('300ms')),
    ]),
  ],
})
export class CampaignFiltersComponent {
  @Input() data: any | undefined;
  @Input() slideState: string | undefined;
  @Output() onHideRequest = new EventEmitter<boolean>();
  @Output() onSearchRequest = new EventEmitter<CampaignsFilter>();

  public campaignsFilter: CampaignsFilter = new CampaignsFilter();
  public client: Campaigns = new Campaigns();

  public clients: Campaigns[] = [];
  fromDate: any = null; //new Date(new Date().setMonth(new Date().getMonth() - 1)); //new Date;"1-1-2022";
  toDate: any = null; //new Date(); //"12-31-2022";

  dropdownSettingsUsers = {
    idField: 'id',
    textField: 'name',
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: 'There is no item availabale to show',
    singleSelection: false,
    enableCheckAll: true,
    clearSearchFilter: true,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private toastr: ToastrService,
    private calendar: NgbCalendar,
    private messageService: MessageService
  ) {}
  hideFilter() {
    this.onHideRequest.emit(true);
  }
  applyFilter(): void {
    if (this.fromDate) {
      this.campaignsFilter.fromDate = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      );
    }
    if (this.toDate) {
      this.campaignsFilter.toDate = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day
      );
    }
    this.campaignsFilter.pageNumber = 1;
    this.onSearchRequest.emit(this.campaignsFilter);
  }
  resetFilters(): void {
    this.campaignsFilter = new CampaignsFilter();
    this.campaignsFilter.pageNumber = 1;
    this.onSearchRequest.emit(this.campaignsFilter);
  }
}

