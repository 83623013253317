import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Products } from 'src/app/Models/Products';
import { Result } from 'src/app/models/Result';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-setup',
  templateUrl: './product-setup.component.html',
  styleUrl: './product-setup.component.css'
})
export class ProductSetupComponent {
  product: Products = new Products();
  isDataSaving: boolean;
  productCategory: any[] = [{ id: 1, categoryName: 'Electronic' }, { id: 2, categoryName: 'Software' }];
  constructor(
    private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService
  ) {
    this.product = this.dialogConfig.data.client;
  }


  onProductCategoryChange(event: any) {
    this.product.productCategory = event.value;
    console.log('Selected Product Category:', this.product.productCategory);
  }
  public validateAndSetData() {
    console.log(this.product.productCategory);
    if (!this.product.name) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the product name',
        life: 3000,
      });
      return false;
    }

    if (!this.product.price) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the price',
        life: 3000,
      });
      return false;
    }
    if (!this.product.productCategory) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the product category',
        life: 3000,
      });
      return false;
    }
    if (!this.product.sku) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the sku',
        life: 3000,
      });
      return false;
    }
    if (!this.product.stockQuantity) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the stock quantity',
        life: 3000,
      });
      return false;
    }


    this.product.dateCreated = new Date();
    this.product.isActive = true;
    return true;
  }

  public onSubmit() {
    // Your form submission logic here
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.addUpdateProduct(this.product).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close();

          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          // Handle error
          this.isDataSaving = false;
          console.error('error:', error);
        },
      });
    }
  }
  public modalClose() {
    this.dialogRef.close();
  }
}
