<div class="row mt-3">
  <div class="col-md-4 mb-4">
    <button [routerLink]="['/users']" (click)="hide()" class="card shadow-sm"
      style="border-radius: 10px; border: none;">
      <div class="card-body text-center">
        <i class="fa-regular fa-users mr-1 mb-3" style="color: #3f51b5; font-size: 2.5rem;"></i>
        <h5 class="card-title">
          Manage Users
        </h5>
        <p class="card-text">Manage user profiles and settings</p>
      </div>
    </button>
  </div>
  <div class="col-md-4 mb-4">
    <button [routerLink]="['/teams']" (click)="hide()" class="card shadow-sm"
      style="border-radius: 10px; border: none;">
      <div class="card-body text-center">
        <i class="fa-regular fa-sitemap mr-1 mb-3" style="color: #b13fb5; font-size: 2.5rem;"></i>
        <h5 class="card-title">
          Manage Teams
        </h5>
        <p class="card-text">Build and customize teams for optimal collaboration</p>
      </div>
    </button>
  </div>
  <div class="col-md-4 mb-4">
    <button [routerLink]="['/roles']" (click)="hide()" class="card shadow-sm"
      style="border-radius: 10px; border: none;">
      <div class="card-body text-center">
        <i class="fa-regular fa-lock mr-1 mb-3" style="color: #009688; font-size: 2.5rem;"></i>
        <h5 class="card-title">
          Roles & Permissions
        </h5>
        <p class="card-text">Control user roles and access permissions</p>
      </div>
    </button>
  </div>
  <div class="col-md-4 mb-4">
    <button [routerLink]="['/projects']" (click)="hide()" class="card shadow-sm"
      style="border-radius: 10px; border: none;">
      <div class="card-body text-center">
        <i class="fa-regular fa-project-diagram mr-1 mb-3" style="color: #ff9800; font-size: 2.5rem;"></i>
        <h5 class="card-title">
          Manage Projects
        </h5>
        <p class="card-text">Configure project settings and details</p>
      </div>
    </button>
  </div>
  <div class="col-md-4 mb-4">
    <button [routerLink]="['/services']" (click)="hide()" class="card shadow-sm"
      style="border-radius: 10px; border: none;">
      <div class="card-body text-center">
        <i class="fa-regular fa-list-tree mr-1 mb-3" style="color: #e91e63; font-size: 2.5rem;"></i>
        <h5 class="card-title">
          Manage Services
        </h5>
        <p class="card-text">Organize services and categories</p>
      </div>
    </button>
  </div>
  <div class="col-md-4 mb-4">
    <button (click)="downloadInstaller()" (click)="hide()" class="card shadow-sm"
      style="border-radius: 10px; border: none;">
      <div class="card-body text-center">
        <i class="fa-regular fa-download mr-1 mb-3" style="color: #795548; font-size: 2.5rem;"></i>
        <h5 class="card-title">
          Client Track Agent
        </h5>
        <p class="card-text">Download and install the Client Track Agent setup on your system.</p>
      </div>
    </button>
  </div>
  <div class="col-md-4 mb-4">
    <button (click)="hrmsPluginSetup()" class="card shadow-sm" style="border-radius: 10px; border: none;" [hasPermission]="{ access: 'hrms-plugin-integration', action: 'disable' }">
      <div class="card-body text-center">
        <img src="../../../assets/logo/RWF.png" style="height: 40px;margin-bottom: 10px;" />
        <h5 class="card-title m-0">
          HRMS Plugin Integration
        </h5>
        <p class="card-text">Manage Radiant Workforce plugin settings</p>
      </div>
    </button>
  </div>
  <div class="col-md-4 mb-4">
    <button (click)="uploadDocsSetup()" class="card shadow-sm"
      style="border-radius: 10px; border: none;" [hasPermission]="{ access: 'bulk-data-import', action: 'disable' }">
      <div class="card-body text-center">
        <i class="fa-regular fa-file-import mr-1 mb-3" style="color: #6a4879; font-size: 2.5rem;"></i>
        <h5 class="card-title">
          Bulk Data Import
        </h5>
        <p class="card-text">Easily import large datasets in bulk for streamlined data management.</p>
      </div>
    </button>
  </div>
</div>
