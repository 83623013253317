<div class="modal-body">

  <form class="forms-sampme pt-3" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="mb-3">
        <label for="currentPassword" class="form-label">Current Password</label>
        <div class="input-group">
          <input [type]="showCurrentPassword ? 'text' : 'password'" class="form-control" name="currentPassword"
            placeholder="Enter your current password" [(ngModel)]="currentPassword" required />
          <button type="button" class="btn btn-outline-secondary" (click)="toggleCurrentPasswordVisibility()">
            <i [class]="showCurrentPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
          </button>
        </div>
      </div>

      <!-- New Password -->
      <div class="mb-3">
        <label for="password" class="form-label">New Password</label>
        <div class="input-group">
          <input [type]="showNewPassword ? 'text' : 'password'" class="form-control" name="password"
            placeholder="Enter your new password" [(ngModel)]="password" required />
          <button type="button" class="btn btn-outline-secondary" (click)="toggleNewPasswordVisibility()">
            <i [class]="showNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
          </button>
        </div>
      </div>

      <!-- Confirm Password -->
      <div class="mb-3">
        <label for="confirmPassword" class="form-label">Confirm Password</label>
        <div class="input-group">
          <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control" name="confirmPassword"
            placeholder="Confirm your new password" [(ngModel)]="confirmPassword" required />
          <button type="button" class="btn btn-outline-secondary" (click)="toggleConfirmPasswordVisibility()">
            <i [class]="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
          </button>
        </div>
      </div>

      <div *ngIf="password !== confirmPassword" class="alert alert-danger" role="alert">
        New and confirm passwords do not match!
      </div>
    </div>



    <hr class="mb-4">

    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">Change
        Password</button>
      <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
    </div>
  </form>

</div>