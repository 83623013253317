import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthServiceService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-trial-alert-dialog',
  templateUrl: './trial-alert-dialog.component.html',
  styleUrls: ['./trial-alert-dialog.component.css'],
})
export class TrialAlertDialogComponent {
  @Input() title: string | undefined;
  @Input() message: any | undefined;

  constructor(
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private authService: AuthServiceService,
    private router: Router
  ) {
    this.title = this.dialogConfig.data.title;
    this.message = this.dialogConfig.data.message;
  }

  closeModal(): void {
    this.dialogRef.close(true);

    this.authService
      .setAuthToken('')
      .then((result) => {
        localStorage.clear();
        this.router.navigate(['/login']);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
