import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HrmsEmployee } from 'src/app/Models/HrmsEmployee';
import { HrmsPluginSettings } from 'src/app/models/HrmsPluginSettings';
import { Result } from 'src/app/models/Result';
import { Project, User } from 'src/app/models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalVariables } from 'src/environments/environment';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { NumberLengthEquals2 } from 'aws-sdk/clients/paymentcryptographydata';
import { SharedService } from 'src/app/services/shared.service';
import { LeadGroup } from 'src/app/Models/LeadGroups';
import { LeadSources, leadTypes } from 'src/app/models/Leads';
import { BulkDocs } from 'src/app/Models/BulkDocs';

@Component({
  selector: 'app-bulk-documents',
  templateUrl: './bulk-documents.component.html',
  styleUrl: './bulk-documents.component.css',
})
export class BulkDocumentsComponent {
  isLoading: boolean = true;
  isProcessing: boolean = false;
  columns: string[] = [];
  fileContent: string = '';
  bulkDocsRequest: BulkDocs = new BulkDocs();
  selectedMappings: { [key: string]: string } = {};
  public leadGroupsList: LeadGroup[] = [];
  public leadTypesList: leadTypes[] = [];
  public leadSourcesList: LeadSources[] = [];
  selectedFile: File | null = null;
  csvHeaders: any = [];
  step: number;
  selectedType: string = null;
  campaignId:number;
  leadGroupId:number;
  leadTypeId:number;
  leadSourceId:number;
  campaignName: string;
  ptojectId: number;

  constructor(
    private dialogRef: DynamicDialogRef,
    private apiService: ApiService,
    private messageService: MessageService,
    private authService: AuthService,
    private ngxCsvParser: NgxCsvParser,
    public dialogConfig: DynamicDialogConfig,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService
  ) {
    this.isLoading = false;
    this.leadGroupId = this.dialogConfig.data.leadGroupId;
    this.campaignName = this.dialogConfig.data.campaignName;
    if(this.leadGroupId > 0){
      this.step = 2;
      this.onStepChange('leads');
    }
    else{
      this.step = 1;
    }
      this.sharedService.onProjectChange.subscribe((projectId) => {
        this.ptojectId = projectId;
      });
  }
  ngOnInit() {
    this.getLeadGroups();
    this.getLeadTypes();
    this.getLeadSources();
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
  }
  modalClose() {
    this.dialogRef.close();
  }

  saveSetting() {
    if (this.validateAndSetData()) {
      this.isProcessing = true;
      if (this.fileContent && Object.keys(this.selectedMappings).length > 0) {
        const formData = new FormData();

        // Append the CSV file
        formData.append('file', this.selectedFile, this.selectedFile.name);

        // Append the selected column mappings
        formData.append(
          'columnMappings',
          JSON.stringify(this.selectedMappings)
        );
        if(this.campaignId > 0){

        }
        else{
          this.campaignId = 0;
        }
        // Call the API to send the data
        this.apiService
          .bulkUploadLeads(
            this.selectedFile,
            JSON.stringify(this.selectedMappings),
            this.bulkDocsRequest
          )
          .subscribe({
            next: (resp: Result) => {
              if (resp.status === 'success') {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: resp.message,
                  life: 3000,
                });
                this.dialogRef.close();
              } else if (resp.status === 'warning') {
                this.messageService.add({
                  severity: 'warn',
                  summary: 'Warning',
                  detail: resp.message,
                  life: 3000,
                });
              }
                else{
                  this.messageService.add({
                    severity: 'error',
                    summary: 'error',
                    detail: resp.message,
                    life: 3000,
                  });
                }
              this.isProcessing = false;
              this.isLoading = false;
            },
            error: (error) => {
              // Handle error

              this.isLoading = false;
              console.error('error:', error);
            },
          });
      }
    }
  }
  validateAndSetData() {
    if ( !this.leadSourceId || !this.leadTypeId) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select all the required fields.',
        life: 3000,
      });
      return false;
    }
    if (
      this.selectedMappings &&
      Object.keys(this.selectedMappings).length === 0
    ) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please map the fields to proceed',
        life: 3000,
      });
      return false;
    }
    if (
      this.selectedMappings &&
      Object.keys(this.selectedMappings).length > 0
    ) {
      let hasNameMapped = false;
      let hasPhoneNumberMapped = false;

      for (const key in this.selectedMappings) {
        if (this.selectedMappings.hasOwnProperty(key)) {
          const value = key;

          // Check if Name and PhoneNumber are mapped
          if (value === 'Name') {
            hasNameMapped = true;
          }
          if (value === 'PhoneNumber') {
            hasPhoneNumberMapped = true;
          }
        }
      }

      if (hasNameMapped && hasPhoneNumberMapped) {
        this.bulkDocsRequest = new BulkDocs();
    this.bulkDocsRequest.projectId =  this.ptojectId;
    if(!this.leadGroupId){
      this.bulkDocsRequest.leadGroupId = 0;
    }
    else{
      this.bulkDocsRequest.leadGroupId = Number(this.leadGroupId);
    }
    this.bulkDocsRequest.leadSourceId = Number(this.leadSourceId);
    this.bulkDocsRequest.leadTypeId = Number(this.leadTypeId);
        return true; // Both Name and PhoneNumber are mapped
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail:
            'Please map at least the fields for Name and Phone Number to proceed.',
          life: 3000,
        });
        return false; // Name or PhoneNumber is not mapped
      }
    } else {
      // Handle case where selectedMappings is empty or undefined
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'No fields are mapped. Please map the fields to proceed.',
        life: 3000,
      });
      return false;
    }
    return true;
  }
  handleFileInput(event: any) {
    const file = event.target.files[0];
    this.selectedFile = file;
    if (file) {
      // Step 1: Read the file first
      this.readFile(file);
    }
  }

  readFile(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.fileContent = reader.result as string;

      // Step 2: Parse the file after reading it
      this.parseCsv(file);
    };
    reader.readAsText(file);
  }

  parseCsv(file: File) {
    this.ngxCsvParser.parse(file, { header: true, delimiter: ',' }).subscribe({
      next: (result: Array<any>) => {
        if (result.length > 0) {
          // Extract column names from the first row
          this.columns = Object.keys(result[0]);
        }
      },
      error: (error: NgxCSVParserError) => {
        console.error('Error parsing CSV:', error);
      },
    });
  }

  onStepChange(type) {
    this.selectedType = type;

    if (type === 'leads') {
      this.csvHeaders = [
        {
          dbField: 'Name',
          display: 'Name',
          desc: 'Full name or company name.',
        },
        {
          dbField: 'ConcernPerson',
          display: 'Concern Person',
          desc: 'Person responsible for the concern.',
        },
        {
          dbField: 'ContactPerson',
          display: 'Contact Person',
          desc: 'Name of the primary contact.',
        },
        {
          dbField: 'Designation',
          display: 'Designation',
          desc: 'Job title or role of the contact.',
        },
        {
          dbField: 'Email',
          display: 'Email Address',
          desc: 'Email address for communication.',
        },
        {
          dbField: 'PhoneNumber',
          display: 'Phone Number',
          desc: 'Main phone number.',
        },
        {
          dbField: 'MobileNumber',
          display: 'Mobile Number',
          desc: 'Mbile phone number.',
        }
      ];
    } else if (type === 'deals') {
    } else if (type === 'users') {
    }
  }

  next() {
    if (this.selectedType === 'deals' || this.selectedType === 'users') {
      this.messageService.add({
        severity: 'info',
        summary: 'Not Supported',
        detail: 'Only Leads import is supported at this time.',
        life: 3000,
      });

      return;
    }

    if (this.step === 2 && !this.selectedFile) {
      this.messageService.add({
        severity: 'warn',
        summary: 'No File Selected',
        detail: 'You must select a file to proceed',
        life: 3000,
      });

      return;
    }

    if (this.step < 3) this.step++;
  }

  previous() {
    if (this.step > 1) this.step--;
  }
  getLeadGroups() {
    this.apiService.getAllLeadGroups(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadGroupsList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }    
  getLeadTypes() {
    this.apiService.getLeadTypes().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadTypesList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getLeadSources() {
    this.apiService.getLeadSources().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadSourcesList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
}
