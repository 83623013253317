import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Result } from 'src/app/models/Result';
import { Project as Project } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-project-setup',
  templateUrl: './project-setup.component.html',
  styleUrls: ['./project-setup.component.css'],
})
export class ProjectSetupComponent {
  project: Project = new Project();

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  preview = '';

  imageInfos?: Observable<any>;

  constructor(
    private apiService: ApiServiceService,
    private authService: AuthServiceService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService,
  ) {
    this.project = this.dialogConfig.data.project;
  }

  ngOnInit(): void {
    this.preview = this.project.icon;
    
  }

  selectFile(event: any): void {
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (event.target.files.length > 0) {
      this.currentFile = event.target.files[0];

      if (this.currentFile) {
        this.preview = '';
        const reader = new FileReader();

        reader.onload = (e: any) => {
          //console.log(e.target.result);
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  uploadPhoto(): void {
    this.progress = 0;

    if (this.currentFile) {
      this.getAwsSignUrl(this.currentFile);
    }
  }

  public getAwsSignUrl(file: File): void {
    const awsPath = `crm/org_${this.authService.currentUserValue.organizationId}/images/projects`;
    const filePath = `${awsPath}/${file.name}`;

    let encodedPath = encodeURIComponent(filePath);
    let type = encodeURIComponent(file.type);

    this.apiService.getS3SignedUrl(encodedPath, type).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.apiService.uploadToS3(this.currentFile, resp.data).subscribe({
            next: (event) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);
              } else if (event.type === HttpEventType.Response) {
                if (event.status === 200) {
                  const url = `https://d1zoy1lzkhwt04.cloudfront.net/${filePath}`;
                  this.project.icon = url;
                } else {
                  console.log(event);
                }
              }
            },
            error: (error) => {
              // Handle error
              console.error('Upload error:', error);
            },
          });

          console.log(resp);
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public onSubmit() {
    if (this.validateAndSetData()) {
      this.apiService.addOrUpdateProject(this.project).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });

            this.sharedService.triggerProjectUpdates();
            this.dialogRef.close();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  public validateAndSetData() {
    if (!this.project.name) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter project name',
        life: 3000,
      });
      return false;
    }


    if (this.currentFile) {
      if (!this.project.icon) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'You may have forgotten to upload user project logo',
          life: 3000,
        });
        return false;
      }
    }

    return true;
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
