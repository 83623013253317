import { Component } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from '../../../services/auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/Users';
import { ActivatedRoute, Router } from '@angular/router';
import { DealsFilter, Deals, DealsData } from 'src/app/models/Deals';
import { Remarks } from 'src/app/models/Remarks';
import { DealStatus, LeadStatus } from 'src/app/models/Status';
import { Result } from 'src/app/models/Result';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SharedService } from 'src/app/services/shared.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalVariables } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { padZero } from 'src/app/utils/utils';
import { LeadGroup, LeadGroupsFilter } from 'src/app/Models/LeadGroups';
import { AddUpdateGroupsComponent } from '../add-update-groups/add-update-groups.component';
import { BulkDocumentsComponent } from '../../bulk-documents/bulk-documents.component';

@Component({
  selector: 'app-manage-groups',
  templateUrl: './manage-groups.component.html',
  styleUrl: './manage-groups.component.css',
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class ManageGroupsComponent {
  public leadGroups: LeadGroup[] = [];
  public leadGroup: LeadGroup = null;
  public leadGroupFilter: LeadGroupsFilter = new LeadGroupsFilter();

  public hideCol: Boolean = true;
  public statusTypeId: number = 5; // Incase of deal component
  public slideState = 'right';
  public dealsFilter: DealsFilter = new DealsFilter();
  public deals: Deals = new Deals();
  public logs: Remarks = new Remarks();
  public allDeals: Deals[] = [];
  public dealStatusList: DealStatus[] = [];
  public CampaignStatusList: LeadStatus[] = [];
  public usersList: User[] = [];
  public allLogs: Remarks[] = [];
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  public isLoading = false;
  public selectedUers: User[] = [];
  public selectedUersFilter: User[] = [];
  public totalCount: number = 0;
  public currentPage: number = 1;
  public totalPages: number = 0;
  public pageSize: number = 10;
  public displayedPages: number[] = [];
  public statusId: number;
  public pageHeading: string;
  addGroupComponentHeader: string;
  //#region refrence storing variables for model
  public addLogModal: any;

  constructor(
    public modalService: DialogService,
    private apiService: ApiService,
    private router: Router,
    public authService: AuthServiceService,
    public activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private calendar: NgbCalendar,
    private sharedService: SharedService,
    private messageService: MessageService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;

      this.loadData();
    });
  }

  ngOnInit() {
    this.loadData();
  }

  public loadData(): void {
    this.leadGroupFilter.pageNumber = this.currentPage;
    this.leadGroupFilter.pageSize = this.pageSize;
    this.getAllGroups();
    this.sharedService.triggerChildLoaded();
  }

  public toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  public onSearchRequest(filters: LeadGroupsFilter) {
    this.toggleFilter();
    this.leadGroupFilter = filters;
    this.currentPage = this.leadGroupFilter.pageNumber;
    this.getAllGroups();
  }

  public onHideFilterRequest() {
    this.toggleFilter();
  }

  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getAllGroups();
  }
  public calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  public generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 5; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  public onPageSizeChange(): void {
    this.currentPage = 1;
    this.getAllGroups();
  }

  addNewGroup(item: any) {
    this.addGroupComponentHeader = 'Add Group';
    this.leadGroup = new LeadGroup();

    //for current user selected
    const obj = this.usersList.filter(
      (user) => user.id === this.authService?.currentUserValue.id
    );
    this.selectedUers = obj;

    this.leadGroup.projectId = GlobalVariables.selectedProjectId; // in case its a RWF project then pick new lead else pick random first lead

    if (item) {
      this.addGroupComponentHeader = 'Edit Group';
      this.leadGroup = item;
    }
    const modalRef = this.dialogService.open(AddUpdateGroupsComponent, {
      modal: true,
      showHeader: true,
      header: this.addGroupComponentHeader,
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        projectId: GlobalVariables.selectedProjectId,
        leadGroup: this.leadGroup,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.leadGroup = null;
      this.getAllGroups();
    });
  }

  getAllGroups() {
    this.isLoading = true;
    this.leadGroupFilter.pageNumber = this.currentPage;
    this.leadGroupFilter.pageSize = this.pageSize;
    this.leadGroupFilter.projectId = GlobalVariables.selectedProjectId;
    this.apiService.getLeadGroup(this.leadGroupFilter).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.leadGroups = [];
        if (resp.status === 'success') {
          this.leadGroups = resp.data;
          if (this.leadGroups && this.leadGroups.length > 0) {
          }
          if (this.leadGroups?.length > 0)
            this.totalCount = this.leadGroups[0]?.totalRecords;
          this.generateDisplayedPages();
          this.sharedService.triggerChildLoaded();
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  deleteLeadGroupById(id: number) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message: 'Do you want to delete this group?',
      accept: () => {
        this.apiService.deleteLeadGroupById(id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: resp.message,
                life: 3000,
              });
              this.loadData();
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }
  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  bulkUploadLead(id){
    const tsModalRef = this.dialogService.open(BulkDocumentsComponent, {
      modal: true,
      showHeader: true,
      header: 'Bulk Data Import',
      width: '40%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        leadGroupId: id
      },
    });
    tsModalRef?.onClose.subscribe(() => {});
  }
}
