import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Task, TimeEntry,TaskCheckInState } from '../models/Task';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  timeEntry: TimeEntry = new TimeEntry();
  isProjectChangeInProc: boolean = false;


  private projectIdSubject = new BehaviorSubject<number | null>(
    this.getStoredProjectId() | 0
  );

  onProjectUpdates = new BehaviorSubject(null);

  triggerProjectUpdates() {
    this.onProjectUpdates.next(null);
  }

  onLangUpdates = new BehaviorSubject<string | null>(null);
  triggerLangUpdates(code: string | null) {
    this.onLangUpdates.next(code);
  }

  onProjectChange = this.projectIdSubject.asObservable();

  private getStoredProjectId(): number | null {
    const storedValue = localStorage.getItem('userProjectDefaultId');
    const parsedValue = parseInt(storedValue, 10); // Use radix 10 for decimal

    return isNaN(parsedValue) ? null : parsedValue;
  }

  triggerProjectChange(projectId: number): void {
    this.isProjectChangeInProc = true;
    localStorage.setItem('userProjectDefaultId', projectId.toString());
    this.projectIdSubject.next(Number(projectId));
  }

  triggerChildLoaded() {
    this.isProjectChangeInProc = false;
  }

  onTaskStart = new BehaviorSubject<Task | null>(null);
  triggerTaskStart(task: Task | null) {
    this.onTaskStart.next(task);
  }

  onTaskStop = new BehaviorSubject<Number | null>(null);
  triggerTaskStop(taskId: Number | null) {
    this.onTaskStop.next(taskId);
  }
  onTaskCheckIn = new BehaviorSubject<TaskCheckInState | null>(null);
  triggerTaskCheckIn(taskCheckIn:TaskCheckInState | null) {
    this.onTaskCheckIn.next(taskCheckIn);

  }

  onTaskCheckOut = new BehaviorSubject<TaskCheckInState | null>(null);
  triggerTaskCheckOut(taskCheckIn: TaskCheckInState | null) {
    this.onTaskCheckOut.next(taskCheckIn);

  }

  //#endregion
}
