<div class="modal-body">
  <input type="hidden" />
  <div *ngIf="!loading" class="mb-3 mt-3">
    <p>
      <i class="fas fa-info-circle" aria-hidden="true" title="Information" style="margin-right: 3px;"></i>
      Please select the date range and the columns you would like to include in the export. Once you click "Export," the
      file will be generated and downloaded automatically.
    </p>
  </div>
  <div style="height: 200px;">
    <ng-container *ngIf="!loading">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="startDate">From Date:</label>
          <p-calendar [(ngModel)]="startDate" name="startDate" [icon]="'fa-regular fa-calendar'" [showIcon]="true"
            appendTo="body" required></p-calendar>
        </div>

        <div class="form-group col-md-6">
          <label for="endDate">To Date:</label>
          <p-calendar [(ngModel)]="endDate" name="endDate" [icon]="'fa-regular fa-calendar'" [showIcon]="true"
            appendTo="body" required></p-calendar>
        </div>
      </div>

      <div class="form-group">
        <label for="usersselect">Choose Columns to Include</label>
        <p-multiSelect name="usersselect" [options]="availableCols" [(ngModel)]="selectedCols"
          placeholder="Select Columns" appendTo="body" optionLabel="displayName" [selectedItemsLabel]="getSelectedItemsLabel()">
        </p-multiSelect>
      </div>
    </ng-container>

    <ng-container *ngIf="loading">
      <app-project-loading-indicator *ngIf="loading && progress < 100"
        message="{{progress > 0 ? 'Downloading file: ' + progress + '%' : 'Processing... Please wait.'}}"
        [height]="'200px'">
      </app-project-loading-indicator>

      <ng-container *ngIf="loading && progress >= 100">
        <div class="success-container">
          <i class="far fa-check-circle" aria-hidden="true"></i>
          <div>The file <strong>{{filename}}</strong> has been downloaded. Check your Downloads folder or the location
            specified in your
            browser settings.</div>
        </div>
      </ng-container>
    </ng-container>

  </div>
</div>


<div class="modal-footer">
  <!-- Footer Buttons -->
  <div class="text-right">
    <button type="button" class="btn btn-sm action-button-cancel me-2" (click)="modalClose()">Close</button>
    <button *ngIf="!loading" type="button" (click)="exportLeads()"
      class="btn btn-primary btn-sm action-button">Export</button>&nbsp;&nbsp;
  </div>
</div>
