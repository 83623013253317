import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarView } from 'angular-calendar';
import { ToastrService } from 'ngx-toastr';
import { Event, EventFilter } from 'src/app/models/Event';
import { LeadsFilter } from 'src/app/models/Leads';
import { LeadsConversions } from 'src/app/models/LeadsConversions';
import { LeadsMetrics } from 'src/app/models/LeadsMetrics';
import { Result } from 'src/app/models/Result';

import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import 'src/app/extensions/DateExtensions';
import { TopPerformer } from 'src/app/models/TopPerformer';

@Component({
  selector: 'app-overall-analytics',
  templateUrl: './overall-analytics.component.html',
  styleUrls: ['./overall-analytics.component.css'],
})
export class OverallAnalyticsComponent {
  data_revenue: any;
  options_revenue: any;

  data_sales: any;
  options_sales: any;

  data_sales_trend: any;
  options_sales_trend: any;

  leadsFilter: LeadsFilter = new LeadsFilter();

  leadsMetrics: LeadsMetrics[] = [];
  leadsMetricsTotalLeads: LeadsMetrics = new LeadsMetrics();
  leadsMetricsStatus = 'loading';
  leadsMetricsSortingOrders = {
    3: 1,
    4: 2,
    7: 3,
    6: 4,
    2: 5,
    5: 6,
  };

  leadsConversions: LeadsConversions[] = [];
  leadsConversionsStatus = 'loading';

  eventFilters: EventFilter = null;
  events: Event[] = [];

  topPerformers: TopPerformer[] = [];

  constructor(
    public modalService: NgbModal,
    private apiService: ApiServiceService,
    public authService: AuthServiceService,
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    public sharedService: SharedService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
      this.leadsFilter.days = 30;

      this.loadData();
    });
    //this.loadData();
  }

  ngAfterViewInit() {
    //this.sharedService.triggerChildLoaded();
  }

  loadData() {
    this.leadsFilter.projectId = GlobalVariables.selectedProjectId;

    this.loadLeadsMetrics();
    this.loadCharts();
    this.loadEvents();
    this.loadTopPerformers();
    this.sharedService.triggerChildLoaded();
  }

  onDaysChange() {
    this.loadLeadsMetrics();
  }

  loadLeadsMetrics() {
    this.leadsMetricsStatus = 'loading';

    this.apiService.getLeadsMetrics(this.leadsFilter).subscribe({
      next: (resp: Result) => {
        this.leadsMetricsStatus = 'loaded';
        this.leadsMetrics = [];
        if (resp.status === 'success') {
          this.leadsMetrics = resp.data;

          this.leadsMetricsTotalLeads.currentPeriodTotal =
            this.leadsMetrics.reduce(
              (total, obj) => total + obj.currentPeriodTotal,
              0
            );
          this.leadsMetricsTotalLeads.previousPeriodTotal =
            this.leadsMetrics.reduce(
              (total, obj) => total + obj.previousPeriodTotal,
              0
            );
          this.leadsMetricsTotalLeads.comparison =
            this.leadsMetricsTotalLeads.currentPeriodTotal -
            this.leadsMetricsTotalLeads.previousPeriodTotal;
          this.leadsMetricsTotalLeads.comparisonIndicator =
            this.leadsMetricsTotalLeads.currentPeriodTotal >
            this.leadsMetricsTotalLeads.previousPeriodTotal
              ? '+'
              : this.leadsMetricsTotalLeads.currentPeriodTotal <
                this.leadsMetricsTotalLeads.previousPeriodTotal
              ? '-'
              : '';

          if (this.leadsMetrics && this.leadsMetrics.length > 0) {
            this.leadsMetrics.forEach((m) => {
              m.sortOrder = this.leadsMetricsSortingOrders[m.statusId];
            });

            this.leadsMetrics = this.leadsMetrics.filter(
              (m) => m.sortOrder !== undefined
            );

            this.leadsMetrics.sort((a, b) => a.sortOrder - b.sortOrder);
          }
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  loadCharts() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    let canvas = this.renderer.createElement('canvas');

    this.data_revenue = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: documentStyle.getPropertyValue('--blue-500'),
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: 'My Second dataset',
          backgroundColor: documentStyle.getPropertyValue('--pink-500'),
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };

    this.options_revenue = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    this.data_sales = {
      labels: ['A', 'B', 'C'],
      datasets: [
        {
          data: [540, 325, 702],
          backgroundColor: [
            documentStyle.getPropertyValue('--blue-500'),
            documentStyle.getPropertyValue('--yellow-500'),
            documentStyle.getPropertyValue('--green-500'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--blue-400'),
            documentStyle.getPropertyValue('--yellow-400'),
            documentStyle.getPropertyValue('--green-400'),
          ],
        },
      ],
    };

    this.options_sales = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: textColor,
          },
          position: 'right',
        },
      },
    };

    this.apiService.getLeadsConversions(this.leadsFilter).subscribe({
      next: (resp: Result) => {
        this.leadsConversionsStatus = 'loaded';
        this.leadsConversions = [];
        if (resp.status === 'success') {
          this.leadsConversions = resp.data;

          if (this.leadsConversions.length > 0) {
            const gradient2 = canvas
              .getContext('2d')
              .createLinearGradient(100, 0, 50, 150);
            gradient2.addColorStop(0, 'rgba(0, 205, 255, 0.2)');
            gradient2.addColorStop(1, 'rgba(0, 205, 255, 0)');

            const gradient = canvas
              .getContext('2d')
              .createLinearGradient(5, 0, 5, 100);
            gradient.addColorStop(0, 'rgba(42, 33, 186, 0.2)');
            gradient.addColorStop(1, 'rgba(42, 33, 186, 0)');

            const MonthNames = [
              ...new Set(
                this.leadsConversions.map((l: LeadsConversions) => l.monthName)
              ),
            ];

            this.data_sales_trend = {
              labels: MonthNames,
              datasets: [
                {
                  label: 'Total Leads',
                  fontFamily: 'Poppins',
                  data: this.leadsConversions
                    .filter((entry: any) => entry.label === 'New Lead')
                    .map((entry: any) => entry.data),
                  fill: true,
                  pointBorderWidth: 1,
                  pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                  pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                  tension: 0.4,
                  borderDash: [5, 5],
                  borderWidth: 1.5,
                  borderColor: 'rgba(0, 205, 255)',
                  backgroundColor: gradient2, //'rgba(0, 205, 255, 0.2)',
                },
                {
                  label: 'Demonstrated',
                  fontFamily: 'Poppins',
                  data: this.leadsConversions
                    .filter((entry: any) => entry.label === 'Demonstrated')
                    .map((entry: any) => entry.data),
                  fill: false,
                  pointBorderWidth: 1,
                  pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                  pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                  tension: 0.4,
                  borderWidth: 1.5,
                  borderColor: 'rgba(255, 22, 139)',
                  backgroundColor: 'rgba(255, 22, 139, 0.2)',
                },
                {
                  label: 'Converted',
                  fontFamily: 'Poppins',
                  data: this.leadsConversions
                    .filter((entry: any) => entry.label === 'Converted')
                    .map((entry: any) => entry.data),
                  fill: true,
                  pointBorderWidth: 1,
                  pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                  pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                  tension: 0.4,
                  borderWidth: 1.5,
                  borderColor: 'rgba(42, 33, 186)',
                  backgroundColor: gradient, //'rgba(42, 33, 186, 0.2)',
                },
              ],
            };

            this.options_sales_trend = {
              maintainAspectRatio: false,
              aspectRatio: 0.6,
              responsive: true,
              plugins: {
                legend: {
                  labels: {
                    color: textColor,
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    color: textColorSecondary,
                  },
                  grid: {
                    color: surfaceBorder,
                  },
                },
                y: {
                  ticks: {
                    color: textColorSecondary,
                  },
                  grid: {
                    color: surfaceBorder,
                  },
                },
              },
            };
          }
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  loadEvents() {
    this.eventFilters = new EventFilter();
    this.eventFilters.projectId = GlobalVariables.selectedProjectId;
    let currentDate = new Date();
    //currentDate.setDate(currentDate.getDate() + 1);

    this.eventFilters.fromDate = currentDate.toApiFormattedString();
    this.eventFilters.toDate = currentDate.toApiFormattedString();

    this.events = [];
    this.apiService.getEvents(this.eventFilters).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.events = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  loadTopPerformers() {
    this.topPerformers = [];

    this.apiService
      .getTopPerformers(GlobalVariables.selectedProjectId)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.topPerformers = resp.data;
            //console.log(this.topPerformers);
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
  }
}
