import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { ClientType } from 'src/app/Models/ClientType';
import { IndustryType } from 'src/app/Models/IndustryType';
import { Clients } from 'src/app/Models/Clients';
import { City, Country, States } from 'src/app/Models/Country';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

import ClientTypes from 'src/assets/data/clientTypes.js';
import IndustryTypes from 'src/assets/data/industryTypes.js';
import { Result } from 'src/app/models/Result';

@Component({
  selector: 'app-client-setup',
  templateUrl: './client-setup.component.html',
  styleUrls: ['./client-setup.component.css'],
})
export class ClientSetupComponent {
  client: Clients = new Clients();
  countriesList: Country[] = [];
  statesList: States[] = [];
  citiesList: City[] = [];

  clientTypes: ClientType[] = [];
  selectedClientType: ClientType = null;
  industryTypes: IndustryType[] = [];
  selectedIndustryType: IndustryType = null;

  isDataSaving: boolean;
  constructor(
    private apiService: ApiService,
    private router: Router,

    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService
  ) {
    this.clientTypes = ClientTypes;
    this.industryTypes = IndustryTypes;

    this.client = this.dialogConfig.data.client;
    if (this.client) {
      this.selectedClientType = this.clientTypes.find(
        (x) => x.type === this.client.clientType
      );
      this.selectedIndustryType = this.industryTypes.find(
        (x) => x.type === this.client.industryType
      );

      this.getCountries(this.client.countryId);
    } else {
      this.getCountries();
    }
  }

  public getCountries(countryId?: number | null | undefined) {
    if (countryId > 0) {
      this.apiService.getCountries().subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.countriesList = resp.data;
            this.getStates(countryId);
          } else {
            this.countriesList = [];
          }
        },
        error: (error) => {
          // Handle error
        },
      });
    } else {
      this.apiService.getCountries().subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.countriesList = resp.data;
            const countryId = this.countriesList.map((x) => x.id)[0];
            this.getStates(countryId);
          } else {
            this.countriesList = [];
          }
        },
        error: (error) => {
          // Handle error
        },
      });
    }
  }
  public getStates(countryId: number) {
    if (this.client.stateId > 0) {
      this.apiService.getStates(countryId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.statesList = resp.data;
            this.getCities(this.client.stateId);
          } else {
            this.statesList = [];
          }
        },
        error: (error) => {
          // Handle error
        },
      });
    } else {
      this.apiService.getStates(countryId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.statesList = resp.data;
            const stateId = this.statesList.map((x) => x.id)[0];
            this.getCities(stateId);
          } else {
            this.statesList = [];
          }
        },
        error: (error) => {
          // Handle error
        },
      });
    }
  }
  public getCities(stateId: number) {
    this.apiService.getCities(stateId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.citiesList = resp.data;
        } else {
          this.citiesList = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }

  onCountryChange(value: any) {
    const countryId = value;
    this.client.countryId = countryId;
    this.getStates(countryId);
  }
  onStateChange(value: any) {
    const stateId = value;
    this.client.stateId = stateId;
    this.getCities(stateId);
  }
  onCityChange(value: any) {
    const cityId = value;
    this.client.cityId = cityId;
  }

  public validateAndSetData() {
    if (!this.client.clientName) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the client name',
        life: 3000,
      });
      return false;
    }

    if (!this.client.contactName) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the contact name',
        life: 3000,
      });
      return false;
    }

    this.client.clientType = this.selectedClientType?.type;
    this.client.industryType = this.selectedIndustryType?.type;

    this.client.dateCreated = new Date();
    return true;
  }

  public onSubmit() {
    // Your form submission logic here
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.addUpdateClients(this.client).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close();
            // if(this.client.id === undefined || this.client.id === 0)
            //   {
            //      this.router.navigate(['/client'], {
            //       queryParams: { id: resp.data}, //Cleint Id
            //     });
            //     }

            //this.getAllclients();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          // Handle error
          this.isDataSaving = false;
          console.error('error:', error);
        },
      });
    }
  }
  public modalClose() {
    this.dialogRef.close();
  }
}
