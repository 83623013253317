export class Deals {
  id!: number;
  projectId: number;
  leadId!: number;
  productId!: number;
  name!: string | null;
  value!: number;
  statusId!: number;
  probability!: number;
  dealOwner!: string | null;
  dateUpdated!: Date;
  status!: string | null;
  color!: string | null;
  bgColor!: string | null;
  userIds: number[] = [];
  userIdsString!: string | null;
  assignedUsersNames!: string | null;
  userNames: string[] = [];
  updatedBy!: string | null;
  createdBy!: string | null;
  updatedByUserId!: number;
  createdByUserId!: number;
  organizationId!: number;
  dateCreated!: Date;
  dealStatus!: string | null;
  assignees!: string;
  assigneesArray: string[];
  firstAssignee: string;
}

export class DealsFilter {
  query!: string | null;
  statusId!: number;
  organizationId!: number;
  fromDate!: Date | null;
  toDate!: Date | null;
  userIds: number[] = [];
  projectId: number;
  pageNumber: number;
  pageSize: number;
}

export class DealsData {
  totalCount!: number;
  deals: Deals[] = [];
}
