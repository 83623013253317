import { Country, States, City } from 'src/app/Models/Country';
import { User } from './Users';

export class Leads {
  id!: number;
  name!: string | null;
  createdBy!: string | null;
  concernPerson!: string | null;
  contactPerson!: string | null;
  statusId!: number;
  groupId!: number;
  designation!: string | null;
  email!: string | null;
  dueDate!: Date;
  comments!: string | null;
  phoneNumber!: string | null;
  mobileNumber!: string | null;
  faxNumber!: string | null;
  createdByUserId!: number;
  updatedByUserId!: number;
  dateCreated!: Date;
  dateUpdated!: Date;
  leadStatus!: string | null;
  status!: string | null;
  bgColor!: string | null;
  color!: string | null;
  userIds: number[] = [];
  assignees: string;
  groups: string;
  assigneesArray: string[];
  firstAssignee: string;
  city: string;
  state: string;
  zip: string;
  assignedUsersNames: string = '';
  userIdsString: string;
  assigneeList: UserAssignees[] = [];
  updatedBy: string = '';
  userNames: string[] = [];
  organizationId!: number;
  projectId: number;
  rowNumber: number;
  totalRecords: number;
  selected: boolean = false;
  campaignId: number;
  campaignName: string;
  additionalFieldsJson: string;
  additionalFields?: LeadAdditionalField[];
  leadAddresses?: LeadAddresses[];
  leadAddressesJson: string;
  lastRemarks: string;
  leadGroup: string;
  leadGroupId: number;
  groupIds: number[] = [];

  leadTypeId!: number;
  leadType: string;

  leadSourceId!: number;
  leadSource: string;

  leadEntityTypeId!: number;
  leadEntityType: string;

  industryTypeId!: number;
  industryType: string | null;

  industrySpecializationId!: number;
  IndustrySpecialization: string | null;
}

export class ContactLead {
  name!: string | null;
  concernPerson!: string | null;
  contactPerson!: string | null;
  statusId!: number;
  email!: string | null;
  phoneNumber!: string | null;
  mobileNumber!: string | null;
  userIds: number[] = [];
  city: string;
  state: string;
  zip: string;
  country: string;
  contactId!: number;
  createdByUserId!: number;
  organizationId!: number;
  projectId: number;
}

export class LeadsFilter {
  id!: number | null;
  query!: string | null;
  statusId!: number;
  fromDate!: Date | null;
  toDate!: Date | null;
  userIds: number[] = [];
  projectId!: number;
  pageNumber!: number;
  pageSize!: number;
  days!: number;
  groupIds: number[] = [];
  leadTypeId!: number;
  leadSourceId!: number;
  leadEntityTypeId!: number;
  industryTypeId!: number;
  industrySpecializationId!: number;
}

export class LeadsData {
  totalCount!: number;
  leads: Leads[] = [];
}
export class UserAssignees {
  id!: number;
  name:string;
  profileImageUrl:string;
}
export class BulkLeadsAssignee {
  id!: number | null;
  userIds: number[] = [];
  createdByUserId!: number;
}

export class LeadAdditionalField {
  id: number;
  leadId: number;
  fieldName: string;
  fieldValue: string;
  isDeleted: boolean;
}

export class LeadAddresses {
  id: number;
  leadId: number;
  name: string;
  address: string;
  cityId: number;
  stateId: number;
  zip: string;
  countryId: number;
  countriesList: Country[];
  statesList: States[];
  citiesList: City[];
}

export class leadTypes {
  id: number;
  type: string;
}

export class LeadSources {
  id: number;
  source: string;
}

export class LeadEntityTypes {
  id: number;
  type: string;
}

export class IndustryTypes {
  id: number;
  type: string;
}

export class IndustrySpecializations {
  id: number;
  specialization: string;
  parentId: number;
}
