import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ManageLeadsComponent } from './components/leads/manageleads/manage-leads.component';
import {
  NgbActiveModal,
  NgbModule,
  NgbAlertModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { KanbanModule } from '@syncfusion/ej2-angular-kanban';
import { NgxEditorModule } from 'ngx-editor';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxTranslateModule } from './translate/translate.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { ListboxModule } from 'primeng/listbox';
import { CardModule } from 'primeng/card';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { ProgressBarModule } from 'primeng/progressbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MasterComponent } from './components/layout/master/master.component';
import { LoginComponent } from './components/login/login.component';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ToastModule } from 'primeng/toast';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmDialogComponent } from './components/modals/confirm-dialog/confirm-dialog.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { LeadviewComponent } from './components/leads/leadview/leadview.component';
import { AlertDialogComponent } from './components/modals/alert-dialog/alert-dialog.component';
import { LeadsFiltersComponent } from './components/leads/manageleads/leads-filters/leads-filters.component';
import { AuthGaurd } from './gaurds/auth-guard';
import { DealsFiltersComponent } from './components/deals/managedeals/deals-filters/deals-filters.component';
import { ManageDealsComponent } from './components/deals/managedeals/manage-deals.component';
import { DealviewComponent } from './components/deals/dealview/dealview.component';
import { ManageEventsComponent } from './components/events/manageevents/manageevents.component';
import { EventViewComponent } from './components/events/eventview/eventview.component';
import {
  CalendarModule as AngularCalendarModule,
  DateAdapter,
} from 'angular-calendar';
import { CalendarModule as PrimeNGCalendarModule } from 'primeng/calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AddEventComponent } from './components/events/addevent/addevent.component';
import { AdddealsComponent } from './components/deals/adddeals/adddeals.component';
import { OverallAnalyticsComponent } from './components/analytics/overall-analytics/overall-analytics.component';
import { LeadsAnalyticsComponent } from './components/analytics/leads-analytics/leads-analytics.component';
import { AddupdateleadComponent } from './components/leads/addupdatelead/addupdatelead.component';
import { NodatafoundComponent } from './components/layout/Error/nodatafound/nodatafound.component';
import { NoactivityComponent } from './components/layout/Error/noactivity/noactivity.component';
import { ProjectLoadingIndicatorComponent } from './components/common/project-loading-indicator/project-loading-indicator.component';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DatePipe } from '@angular/common';
import { ManageUsersComponent } from './components/users/manage-users/manage-users.component';
import { UserSetupComponent } from './components/users/user-setup/user-setup.component';
import { SettingsComponent } from './components/settings/settings.component';
import { InvitationComponent } from './components/invitation/invitation.component';
import { ProjectsComponent } from './components/projects/manage-projects/projects.component';
import { ProjectSetupComponent } from './components/projects/project-setup/project-setup.component';
import { ServicesComponent } from './components/services/manage-services/services.component';
import { ServicesSetupComponent } from './components/services/services-setup/services-setup.component';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { TreeModule } from 'primeng/tree';
import { ApiService } from './services/api.service';
import { TimeDifferencePipe } from './pipes/time-difference.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ElapsedTimePipe } from './pipes/elapsed-time-pipe.pipe';
import { OnlyNumbersDirectiveDirective } from './directives/only-numbers-directive.directive';
import { SpecialCharsBlockerDirective } from './directives/special-chars-blocker.directive';
import { EmailValidatorDirective } from './directives/email-validator-directive.directive';
import { PhoneValidatorDirective } from './directives/phone-validator.directive';
import { InputFieldValidatorDirective } from './directives/name-validator.directive';
import { CircleProgressComponent } from './components/common/circle-progress/circle-progress.component';
import { HrmsPluginComponent } from './plugins/hrms/hrms-plugin/hrms-plugin.component';
import { AttendanceConfirmationComponent } from './components/common/attendance-confirmation/attendance-confirmation.component';
import { HasPermissionDirective } from './directives/permission.directive';
import { ManageRolesComponent } from './components/roles-and-permissions/manage-roles/manage-roles.component';
import { RoleSetupComponent } from './components/roles-and-permissions/role-setup/role-setup.component';
import { PermissionListComponent } from './components/roles-and-permissions/permission-list/permission-list.component';
import { RolePermissionsListComponent } from './components/roles-and-permissions/role-permissions-list/role-permissions-list.component';
import { ToastComponent } from './components/common/toast/toast.component';
import { ManageTeamsComponent } from './components/users/manage-teams/manage-teams.component';
import { NotAuthorizedComponent } from './components/layout/Error/not-authorized/not-authorized.component';
import { TeamSetupComponent } from './components/users/team-setup/team-setup.component';
import { ContactsListComponent } from './components/contacts/contacts-list/contacts-list.component';
import { ChangePasswordComponent } from './components/password/change-password/change-password.component';
import { ConvertToLeadComponent } from './components/contacts/convert-to-lead/convert-to-lead.component';
import { BulkDocumentsComponent } from './components/bulk-documents/bulk-documents.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { AssignLeadsComponent } from './components/leads/manageleads/assign-leads/assign-leads.component';
import { KanbanBoardComponent } from './components/tasks/kanban/kanban.component';
import { KanbanBoardViewComponent } from './components/leads/kanban-board-view/kanban-board-view.component';
import { ManageCampaignComponent } from './components/campaign/manage-campaign/manage-campaign.component';
import { AddUpdateCampaignComponent } from './components/campaign/add-update-campaign/add-update-campaign.component';
import { ClientListComponent } from './components/clients/client-list/client-list.component';
import { ClientSetupComponent } from './components/clients/client-setup/client-setup.component';
import { ClientFiltersComponent } from './components/clients/client-list/client-filters/client-filters.component';
import { CampaignFiltersComponent } from './components/campaign/manage-campaign/campaign-filters/campaign-filters.component';
import { ProductFiltersComponent } from './components/products/product-list/product-filters/product-filters.component';
import { ProductListComponent } from './components/products/product-list/product-list.component';
import { CampaignOverviewComponent } from './components/campaign/campaign-overview/campaign-overview.component';
import { ManageGroupsComponent } from './components/groups/manage-groups/manage-groups.component';
import { GroupsFiltersComponent } from './components/groups/manage-groups/groups-filters/groups-filters.component';
import { AddUpdateGroupsComponent } from './components/groups/add-update-groups/add-update-groups.component';
import { ExportOptionsDialogComponent } from './components/modals/export-options-dialog/export-options-dialog.component';
import { ProductSetupComponent } from './components/products/product-setup/product-setup.component';

@NgModule({
  declarations: [
    AppComponent,
    ManageLeadsComponent,
    ManageDealsComponent,
    MasterComponent,
    LoginComponent,
    ConfirmDialogComponent,
    SidebarComponent,
    LeadviewComponent,
    DealviewComponent,
    AlertDialogComponent,
    LeadsFiltersComponent,
    DealsFiltersComponent,
    ManageEventsComponent,
    EventViewComponent,
    AddEventComponent,
    AdddealsComponent,
    OverallAnalyticsComponent,
    LeadsAnalyticsComponent,
    AddupdateleadComponent,
    NodatafoundComponent,
    NoactivityComponent,
    ProjectLoadingIndicatorComponent,
    ManageUsersComponent,
    UserSetupComponent,
    SettingsComponent,
    InvitationComponent,
    ProjectsComponent,
    ProjectSetupComponent,
    ServicesComponent,
    ServicesSetupComponent,
    TimeDifferencePipe,
    SafeHtmlPipe,
    ElapsedTimePipe,
    OnlyNumbersDirectiveDirective,
    SpecialCharsBlockerDirective,
    EmailValidatorDirective,
    PhoneValidatorDirective,
    InputFieldValidatorDirective,
    HasPermissionDirective,
    CircleProgressComponent,
    HrmsPluginComponent,
    AttendanceConfirmationComponent,
    ManageRolesComponent,
    RoleSetupComponent,
    PermissionListComponent,
    RolePermissionsListComponent,
    ToastComponent,
    ManageTeamsComponent,
    NotAuthorizedComponent,
    TeamSetupComponent,
    ContactsListComponent,
    ChangePasswordComponent,
    ConvertToLeadComponent,
    BulkDocumentsComponent,
    AssignLeadsComponent,
    KanbanBoardComponent,
    KanbanBoardViewComponent,
    ManageCampaignComponent,
    AddUpdateCampaignComponent,
    ClientListComponent,
    ClientSetupComponent,
    ClientFiltersComponent,
    CampaignFiltersComponent,
    ProductFiltersComponent,
    ProductListComponent,
    ProductSetupComponent,
    CampaignOverviewComponent,
    ManageGroupsComponent,
    GroupsFiltersComponent,
    AddUpdateGroupsComponent,
    ExportOptionsDialogComponent,
  ],
  exports: [
    HasPermissionDirective, // Export the directive if it's used across multiple modules
  ],
  bootstrap: [AppComponent],
  imports: [
    TreeModule,
    CascadeSelectModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCsvParserModule,
    KanbanModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        underline: 'Underline',
        strike: 'Strike',
        blockquote: 'Blockquote',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',
        horizontal_rule: 'Horizontal rule',
        format_clear: 'Clear Formatting',
        insertLink: 'Insert Link',
        removeLink: 'Remove Link',
        insertImage: 'Insert Image',
        indent: 'Increase Indent',
        outdent: 'Decrease Indent',
        superscript: 'Superscript',
        subscript: 'Subscript',
        undo: 'Undo',
        redo: 'Redo',
        // pupups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
        enterValidUrl: 'Please enter a valid URL',
      },
    }),
    InputTextareaModule,
    ListboxModule,
    CardModule,
    SelectButtonModule,
    FileUploadModule,
    TabViewModule,
    BadgeModule,
    AvatarModule,
    TableModule,
    DialogModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    MultiSelectModule,
    ProgressBarModule,
    TooltipModule,
    ChartModule,
    ToastModule,
    NgbModule,
    NgbDatepickerModule,
    NgbAlertModule,
    BrowserAnimationsModule,
    NgxTranslateModule,
    ToastrModule.forRoot(), // ToastrModule added
    FlatpickrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    DropdownModule,
    CheckboxModule,
    SliderModule,
    SidebarModule,
    AutoCompleteModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    PrimeNGCalendarModule,
  ],
  providers: [
    NgbActiveModal,
    AuthGaurd,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    ConfirmationService,
    MessageService,
    DatePipe,
    ApiService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule { }
