export class LeadGroup {
  id: number;
  groupName: string;
  groupType: string;
  groupTypeId: number;
  description: string;
  totalLeads: number;
  createdBy: number;
  updatedBy: number;
  orgId: number;
  projectId: number;
  dateCreated: string;
  dateUpdated: string;
  priorityLevelId: number;
  totalRecords: number;
  weight!: number;
  priorityName!: string;
  priorityLevelDescription!: string;
  foreColor!: string;
  bgColor!: string;
}

export class LeadGroupsFilter {
  query: string;
  type: string;
  fromDate!: Date | null;
  toDate!: Date | null;
  orgId: number;
  projectId: number;
  pageNumber: number;
  pageSize: number;
}
