import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from './auth.service';
import { User } from '../models/Users';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  message: any = null;

  firebaseConfig = {
    apiKey: 'AIzaSyDsavR_l1BWASk0oQzI71ZXIqvBY9IVQPM',
    authDomain: 'tms-radiant-workforce.firebaseapp.com',
    projectId: 'tms-radiant-workforce',
    storageBucket: 'tms-radiant-workforce.appspot.com',
    messagingSenderId: '253954452645',
    appId: '1:253954452645:web:74539902a9151e8da9714c',
    measurementId: 'G-29ZT9H35MH',
    vapidKey: 'BJ5TzwPAiyD8ao8zNk-xz7H5EP7A319ZcAXYvRSZ1iYh_UWOQ5UJHQprs95tCWzyikbAienqzXHuSQtbrp1uZDY'
  }
  deviceInfo = null;
  public userInfo: User;
  devieType = null;

  constructor(private deviceService: DeviceDetectorService,
    public authService: AuthService,
    public apiService: ApiService,
  ) {
    this.epicFunction();
    this.userInfo = authService.currentUserValue;

  }
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();
    // const isDesktopDevice = this.deviceService.isDesktop();

    this.devieType = this.deviceInfo?.browser + '(' + this.deviceInfo?.browser_version + ') ' + this.deviceInfo?.deviceType + ' ' + this.deviceInfo?.os + ' ' + this.deviceInfo?.os_version


    //console.log(this.deviceInfo);
    //this.deviceInfo?.browser
    //browser_version
    //deviceType
    //os
    //os_version
    //console.log(devieType);
    //console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    //console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    //console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  init() {
    const app = initializeApp(this.firebaseConfig);
    if (app) {
      this.requestPermission(app);
    }
  }

  requestPermission(app) {
    // if (window.Notification || Notification.permission === 'denied') {
    //   if (
    //     confirm(
    //       this.isChromeBrowser()
    //         ? "To enable notifications in Chrome, click on the padlock icon in the address bar, go to Site settings, and change the Notifications setting to 'Allow.'"
    //         : this.isEdgeBrowser()
    //         ? "To enable notifications in Microsoft Edge, click on the padlock icon in the address bar, go to Site permissions, and change Notifications to 'Allow.'"
    //         : this.isFirefoxBrowser()
    //         ? "To enable notifications in Firefox, click on the padlock icon in the address bar, go to Permissions, and set Notifications to 'Allow.'"
    //         : this.isSafariBrowser()
    //         ? "To enable notifications in Safari, open Safari Preferences, go to Websites, select Notifications, and choose 'Allow' for our website."
    //         : 'To enable notifications, please check your browser settings and allow notifications for our website.'
    //     )
    //   ) {
    //     window
    //       .open('chrome://settings/content/notifications', '_blank')
    //       .focus();
    //   }
    // }

    const messaging = getMessaging(app);
    // console.log('getMessaging', messaging);
    getToken(messaging, { vapidKey: this.firebaseConfig.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.listen(app);
          //console.log('FCM token Messaging Service:', currentToken);
          localStorage.setItem('FCMToken', currentToken);

          // save token to db
          this.userInfo = this.authService.currentUserValue;
          if (this.userInfo?.id > 0) {
            this.registerUserDevice(currentToken);
          }

        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  registerUserDevice(token: string): void {
    const data = {
      UserId: this.userInfo?.id,
      FCMToken: token,
      WebId: this.devieType,
    };

    this.apiService.addFCMToken(data).subscribe(
      (data) => {
        //console.log('credentials saved to db ', data);
      },
      (error) => {
        return error;
      }
    );

  }

  listen(app) {
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
    });
  }
}
