import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
  catchError,
  of,
} from 'rxjs';
import { Campaigns } from 'src/app/Models/Campaigns';
import { IndustrySpecializations, IndustryTypes, LeadAdditionalField, LeadAddresses, LeadEntityTypes, Leads, LeadsFilter, LeadSources, leadTypes } from 'src/app/models/Leads';
import { Result } from 'src/app/models/Result';
import { LeadStatus } from 'src/app/models/Status';
import { User } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { Data } from './data';
import { City, Country, States } from 'src/app/Models/Country';
import { LeadGroup } from 'src/app/Models/LeadGroups';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { int } from 'aws-sdk/clients/datapipeline';

@Component({
  selector: 'app-addupdatelead',
  templateUrl: './addupdatelead.component.html',
  styleUrls: ['./addupdatelead.component.css'],
})
export class AddupdateleadComponent {
  leadTypes = Data.leadTypes;
  leadSources = Data.leadSources;
  industryTypes = Data.industryTypes;
  industrySpecializations = [];
  entityTypes = Data.entityTypes;
  leadGroups = [];

  selectedIndustry: any = null;

  countriesList: Country[] = [];

  addresses: LeadAddresses[] = [
    {
      id:0,
      leadId:0,
      name: '',
      address: '',
      cityId: 0,
      stateId: 0,
      zip: '',
      countryId: 0,
      countriesList: [],
      statesList: [],
      citiesList: [],
    },
  ];
  additionalFields: LeadAdditionalField[] = [
    {
      id: 0,
      leadId: 0,
      fieldName: null,
      fieldValue: null,
      isDeleted: false,
    },
  ];
  leads: Leads = new Leads();
  public usersList: User[] = [];
  public selectedUsers: User[] = [];
  public selectedLeadGroups: LeadGroup[] = [];
  public selectedUersFilter: User[] = [];
  public leadStatusList: LeadStatus[] = [];
  public leadGroupsList: LeadGroup[] = [];
  public leadTypesList: leadTypes[] = [];
  public leadSourcesList: LeadSources[] = [];
  public industryTypesList: IndustryTypes[] = [];
  public leadEntityTypes: LeadEntityTypes[] = [];
  public industrySpecializationsList: IndustrySpecializations[] = [];
  public isCountryLoaded : boolean;
  public country_id:int;

  public projectId = 0;
  campaignId: number;
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  isDataSaving: boolean;
  leadFormatter_search = (name: any) => name;

  constructor(
    private apiService: ApiServiceService,
    private authService: AuthServiceService,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.usersList = this.dialogConfig.data.users;
    this.projectId = this.dialogConfig.data.projectId;
    this.leads = this.dialogConfig.data.lead;
    this.selectedUsers = this.dialogConfig.data.selectedUers;
    this.leadStatusList = this.dialogConfig.data.leadStatusList;
    this.selectedLeadGroups = this.dialogConfig.data.selectedLeadGroup;
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
  }

  ngOnInit() {
    this.getLeadGroups();
    this.getLeadTypes();
    this.getLeadSources();
    this.getLeadEntityTypes();
    this.getIndustryTypes();
    this.getIndustrySpecializations();

    if (this.leads.additionalFieldsJson) {
      this.additionalFields =
        this.leads.additionalFieldsJson ||
        this.leads.additionalFieldsJson !== ''
          ? parseJSONToLowerCaseFirstChar(this.leads.additionalFieldsJson)
          : [];

      this.additionalFields?.forEach((x) => (x.isDeleted = false));
    }
    this.getCountries(0);
    if (this.leads.leadAddressesJson) {
      this.addresses =
        this.leads.leadAddressesJson ||
        this.leads.leadAddressesJson !== ''
          ? parseJSONToLowerCaseFirstChar(this.leads.leadAddressesJson)
          : [];
          this.addresses.forEach((address, index) => {
            // Load states for each address's countryId
            if (address.countryId) {

              this.getStates(address.countryId, index);
              if (address.stateId) {
                this.getCities(address.stateId, index); // load cities based on stateId
              }
            }
          });
      //this.additionalFields?.forEach((x) => (x.isDeleted = false));
    }
    else{
      this.getCountries(0);
    }
  }

  getLeadGroups() {
    this.apiService.getAllLeadGroups(this.projectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadGroupsList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getLeadTypes() {
    this.apiService.getLeadTypes().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadTypesList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getLeadSources() {
    this.apiService.getLeadSources().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadSourcesList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getLeadEntityTypes() {
    this.apiService.getLeadEntityTypes().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadEntityTypes = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getIndustryTypes() {
    this.apiService.getIndustryTypes().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.industryTypesList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getIndustrySpecializations() {
    this.apiService.getIndustrySpecializations().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.industrySpecializationsList = resp.data;
          if(this.leads.industrySpecializationId){
            this.onIndustryChange(this.leads.industryTypeId);
          }
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  addField() {
    this.additionalFields.push({
      id: 0,
      leadId: 0,
      fieldName: null,
      fieldValue: null,
      isDeleted: false,
    });
  }

  deleteField(index) {
    this.additionalFields[index].isDeleted = true;
  }

  onIndustryChange(id: any) {
    this.industrySpecializations = this.industrySpecializationsList.filter(
      (x) => Number(x.parentId) === Number(id)
    );
  }

  public getCountries(index: number) {
    if (this.countriesList.length === 0) {
      this.apiService.getCountries().subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.countriesList = resp.data;
            const countryId = this.countriesList.map((x) => x.id)[0];
            this.isCountryLoaded = true;
           // this.getStates(countryId, index);
          } else {
            this.countriesList = [];
          }
        },
        error: (error) => {
          // Handle error
        },
      });
    }
  }
  public getStates(countryId: number, index: number) {
    this.apiService.getStates(countryId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.addresses[index].statesList = resp.data;

          // Set stateId if it exists in the list
          const stateId = this.addresses[index].stateId;
          if (stateId && this.addresses[index].statesList.some((state) => state.id === stateId)) {
            this.addresses[index].stateId = stateId;
           // this.getCities(stateId, index); // load cities for the selected state
          }
        } else {
          this.addresses[index].statesList = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }

  public getCities(stateId: number, index: number) {
    this.apiService.getCities(stateId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.addresses[index].citiesList = resp.data;

          // Set cityId if it exists in the list
          const cityId = this.addresses[index].cityId;
          if (cityId && this.addresses[index].citiesList.some((city) => city.id === cityId)) {
            this.addresses[index].cityId = cityId;
          }
        } else {
          this.addresses[index].citiesList = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }

  addAddress() {
    this.addresses.push({
      id:0,
      leadId:0,
      name: '',
      address: '',
      cityId: 0,
      stateId: 0,
      zip: '',
      countryId: 0,
      countriesList: [],
      statesList: [],
      citiesList: [],

    });
  }

  removeAddress(index: number) {
    this.addresses.splice(index, 1);
  }

  onCountryChange(value: any, index: number) {
    const countryId = Number(value);
    this.addresses[index].countryId = countryId;
    this.getStates(countryId, index);
  }

  onStateChange(value: any, index: number) {
    const stateId = Number(value);
    this.addresses[index].stateId = stateId;
    this.getCities(stateId, index);
  }

  onCityChange(value: any, index: number) {
    const cityId = Number(value);
    this.addresses[index].cityId = cityId;
  }

  public validateAndSetData() {
    if (!this.selectedUsers || this.selectedUsers.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select at least one user',
        life: 3000,
      });
      return false;
    }

    if (!this.leads.statusId || this.leads.statusId === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select status',
        life: 3000,
      });
      return false;
    }

    if (!this.leads.name) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the lead name',
        life: 3000,
      });
      return false;
    }

    this.leads.additionalFields = this.additionalFields.filter(
      (x) => !x.isDeleted && x.fieldName !== null && x.fieldValue !== null
    );
    this.leads.userIds = this.selectedUsers.map((x) => x.id);
    this.leads.groupIds = this.selectedLeadGroups.map((x) => x.id);
    this.leads.createdByUserId = this.authService.currentUserValue.id;
    this.leads.updatedByUserId = this.authService.currentUserValue.id;
    this.leads.dateCreated = new Date();
    this.leads.dueDate = new Date();
    this.leads.leadAddresses = this.addresses;
    this.leads.additionalFieldsJson = JSON.stringify(this.leads.additionalFields);
    this.leads.leadAddressesJson = JSON.stringify(this.leads.leadAddresses);
    return true;
  }

  searchLead = (text$: Observable<string>) =>
    text$.pipe(
      // Wait for 200ms after each keystroke
      debounceTime(200),
      // Ignore if the term is the same as before
      distinctUntilChanged(),
      // Switch to a new Observable of API results
      switchMap((term) => this.getLeads(term))
    );

  public getLeads(term): Observable<any[]> {
    const filters = new LeadsFilter();
    filters.query = term;
    filters.projectId = this.projectId;
    if (!term) {
      return new Observable<any[]>();
    }

    return this.apiService.getMatchingLeadNames(filters).pipe(
      map((resp) => (resp.status === 'success' ? resp.data : [])),
      catchError((error) => {
        return of([]);
      })
    );
  }

  public onSubmit() {
    // Your form submission logic here
    this.leads.projectId = this.projectId;
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.addUpdateLeads(this.leads).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close();
            //this.getAllLeads();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          // Handle error
          this.isDataSaving = false;
          console.error('error:', error);
        },
      });
    }
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
