import { Component } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from '../../../services/auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/Users';
import { ActivatedRoute, Router } from '@angular/router';
import { DealsFilter, Deals, DealsData } from 'src/app/models/Deals';
import { Remarks } from 'src/app/models/Remarks';
import { DealStatus, LeadStatus } from 'src/app/models/Status';
import { Result } from 'src/app/models/Result';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SharedService } from 'src/app/services/shared.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalVariables } from 'src/environments/environment';
import { Campaigns, CampaignsFilter } from 'src/app/Models/Campaigns';
import { ApiService } from 'src/app/services/api.service';
import { AddUpdateCampaignComponent } from '../add-update-campaign/add-update-campaign.component';
import { padZero } from 'src/app/utils/utils';
import { LeadGroup } from 'src/app/Models/LeadGroups';

@Component({
  selector: 'app-manage-campaign',
  templateUrl: './manage-campaign.component.html',
  styleUrl: './manage-campaign.component.css',
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class ManageCampaignComponent {
  public campaignsFilter: CampaignsFilter = new CampaignsFilter();
  public hideCol: Boolean = true;
  public statusTypeId: number = 5; // Incase of deal component
  public slideState = 'right';
  public dealsFilter: DealsFilter = new DealsFilter();
  public campaigns: Campaigns[] = [];
  public deals: Deals = new Deals();
  public logs: Remarks = new Remarks();
  public allDeals: Deals[] = [];
  public dealStatusList: DealStatus[] = [];
  public CampaignStatusList: LeadStatus[] = [];
  public usersList: User[] = [];
  public allLogs: Remarks[] = [];
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  public isLoading = false;
  public selectedUers: User[] = [];
  public selectedUersFilter: User[] = [];
  public totalCount: number = 0;
  public currentPage: number = 1;
  public totalPages: number = 0;
  public pageSize: number = 10;
  public displayedPages: number[] = [];
  public statusId: number;
  public pageHeading: string;
  public campaign: Campaigns = new Campaigns();
  addCampaignComponentHeader: string;
  public leadGroupList: LeadGroup[] = [];
  public selectedLeadGroup: LeadGroup[] = [];
  //#region refrence storing variables for model
  public addLogModal: any;

  constructor(
    public modalService: DialogService,
    private apiService: ApiService,
    private router: Router,
    public authService: AuthServiceService,
    public activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private calendar: NgbCalendar,
    private sharedService: SharedService,
    private messageService: MessageService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;

      this.loadData();
    });
  }

  ngOnInit() {
    this.getCampaignStatus();
    this.loadData();
    this.getLeadGroups();
  }

  public loadData(): void {
    this.campaignsFilter.pageNumber = this.currentPage;
    this.campaignsFilter.pageSize = this.pageSize;
    this.getAllCampaigns();
    this.sharedService.triggerChildLoaded();
  }

  public toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  public onSearchRequest(filters: CampaignsFilter) {
    this.toggleFilter();
    this.campaignsFilter = filters;
    this.currentPage = this.campaignsFilter.pageNumber;
    this.getAllCampaigns();
  }

  public onHideFilterRequest() {
    this.toggleFilter();
  }

  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getAllCampaigns();
  }
  public calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  getLeadGroups() {
    this.apiService.getAllLeadGroups(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadGroupList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  public generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 5; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  public onPageSizeChange(): void {
    this.currentPage = 1;
    this.getAllCampaigns();
  }

  addNewCampaign(item: any) {
    this.addCampaignComponentHeader = 'Add Campaign';
    this.campaign = new Campaigns();

    //for current user selected
    const obj = this.usersList.filter(
      (user) => user.id === this.authService?.currentUserValue.id
    );
    this.selectedUers = obj;

    this.campaign.statusId =
      GlobalVariables.selectedProjectId === 1
        ? 5
        : this.CampaignStatusList.map((x) => x.id)[0]; // in case its a RWF project then pick new lead else pick random first lead

    if (item) {
      this.addCampaignComponentHeader = 'Edit Campaign';
      this.campaign = item;
      if (this.campaign.groupIds && this.campaign.groupIds.length > 0) {
        const array = this.leadGroupList.filter((item) =>
          this.campaign.groupIds.includes(item.id)
        );
        this.selectedLeadGroup = array;
      } else {
        this.selectedLeadGroup = [];
      }
    
    }
    const modalRef = this.dialogService.open(AddUpdateCampaignComponent, {
      modal: true,
      showHeader: true,
      header: this.addCampaignComponentHeader,
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        users: this.usersList,
        projectId: GlobalVariables.selectedProjectId,
        campaign: this.campaign,
        CampaignStatusList: this.CampaignStatusList,
        selectedLeadGroup: this.selectedLeadGroup,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.campaign = new Campaigns();
      this.getAllCampaigns();
    });
  }
  public getCampaignStatus() {
    this.apiService.getCampaignStatuses(this.statusTypeId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.CampaignStatusList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getAllCampaigns() {
    this.isLoading = true;
    this.campaignsFilter.pageNumber = this.currentPage;
    this.campaignsFilter.pageSize = this.pageSize;
    this.campaignsFilter.projectId = GlobalVariables.selectedProjectId;
    this.apiService.getCampaigns(this.campaignsFilter).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.campaigns = [];
        if (resp.status === 'success') {
          this.campaigns = resp.data;
          if (this.campaigns && this.campaigns.length > 0) {
            const updatedCases = this.campaigns.map((caseObj) => {
              caseObj.campaignAssets = parseJSONToLowerCaseFirstChar(
                caseObj.assetTypesJson
              );
              return caseObj;
            });
          }
          this.campaigns?.forEach((l) => {
            if (l.groups) {
              try {
                // l.firstAssignee = l.assignees.split(',')[0];
                const UserObjects = l.groups.split(',');
                l.groupIds = UserObjects.map((user) =>
                  parseInt(user.split(';')[0])
                );
              } catch (e) {
                console.error(e);
              }
            }
          });
          if (this.campaigns?.length > 0)
            this.totalCount = this.campaigns[0]?.totalRecords;
          this.generateDisplayedPages();
          this.sharedService.triggerChildLoaded();
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  deleteCampaignById(id: number) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message: 'Do you want to delete this campaign?',
      accept: () => {
        this.apiService.deleteCampaignById(id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: resp.message,
                life: 3000,
              });
              this.loadData();
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }
  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }

  public viewCampaignOverview(item: Campaigns) {
    this.router.navigate(['/campaigns'], {
      queryParams: { id: item.id }, //Cleint Id
    });
  }
}
