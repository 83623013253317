<div class="modal-body pb-5 me-3">
  <form class="mt-3">
    <div id="productInfoSection" class="section-title mt-3">
      <h5><i class="far fa-box-open me-1"></i>Product Information</h5>
      <br/>
    </div>
    <div class="form-row m-0">
      <div class="form-group col-md-6">
        <label for="productName">Product Name</label>
        <input type="text" class="form-control mt-1" name="productName" id="productName"
          placeholder="Enter product name" [(ngModel)]="product.name" appSpecialCharsBlocker [min]=3 [max]=255 required>
      </div>
      <div class="form-group col-md-6">
        <label for="contactName">Product Price</label>
        <input type="number" class="form-control mt-1" appSpecialCharsBlocker inputValidator name="productPrice"
          id="productPrice" placeholder="Enter product price" [(ngModel)]="product.price">
      </div>
    </div>
    <div class="form-row m-0">
      <div class="form-group col-md-6">
        <label for="stockQuantity">Stock Quantity</label>
        <input type="number" class="form-control mt-1" name="stockQuantity" id="stockQuantity"
          placeholder="Enter Stock Quantity" [(ngModel)]="product.stockQuantity" required>
      </div>
      <div class="form-group col-md-6">
        <label for="sku">SKU</label>
        <input type="text" class="form-control mt-1" name="sku" id="sku" placeholder="Enter SKU"
          [(ngModel)]="product.sku" required>
      </div>
    </div>

    <div class="form-row m-0">
      <div class="form-group col-md-6">
        <label for="description">Description</label>
        <input type="text" class="form-control mt-1" name="description" id="description" placeholder="Enter Description"
          [(ngModel)]="product.description" >
      </div>
      <div class="form-group col-md-6">
        <label for="productCategory" >Product Category</label>
        <p-dropdown appendTo="body" [options]="productCategory" name="productCategory" optionLabel="categoryName"
          [filter]="true" [ngModel]="product.productCategory" filterBy="categoryName" [showClear]="false" (onChange)="onProductCategoryChange($event)"
          dataKey="categoryName" optionValue="categoryName" placeholder="Select a Product Category" required>
        </p-dropdown>
      </div>
    </div>


  </form>
</div>

<div class="modal-footer mt-3">
  <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
    (click)="modalClose()">Cancel</button>
  <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()"
    [disabled]="this.isDataSaving">{{product.id > 0 ? 'Update product' : 'Create product'}}</button>
</div>
