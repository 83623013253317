import { Component, ElementRef, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AutoCompleteCompleteEvent } from 'src/app/interfaces/AutoCompleteCompleteEvent ';
import { JobTitle } from 'src/app/models/JobTitle';
import { Result } from 'src/app/models/Result';
import { Team } from 'src/app/models/Teams';
import { User } from 'src/app/models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { padZero, parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-team-setup',
  templateUrl: './team-setup.component.html',
  styleUrls: ['./team-setup.component.css'],
})
export class TeamSetupComponent {
  @ViewChild('membersDropdownButton', { static: false })
  membersDropdownButton!: ElementRef;

  team: Team;
  selectedMember: any;
  filteredMembers: User[] = undefined;
  users: User[] = [];
  jobTitles: JobTitle[] = [];

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {
    this.team = this.dialogConfig.data.team;
    this.jobTitles = this.dialogConfig.data.jobTitles;
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
    });

    if (this.team.membersJson) {
      this.team.members = parseJSONToLowerCaseFirstChar(this.team.membersJson);
    } else {
      this.team.members = [];
    }
  }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers() {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getAvailableUsersForTeam().subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.users = resp.data;
          } else {
            this.users = [];
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
          console.error('error:', error);
        },
      });
    });
  }

  filterMembers(event: AutoCompleteCompleteEvent) {
    let query = event.query.toLowerCase();
    this.filteredMembers = (this.users as any[]).filter(
      (user) =>
        user.name?.toLowerCase().includes(query) &&
        !this.team.members.some((selected) => selected.id === user.id)
    );
  }

  toggleMemberOverlay(members: any, event: any) {
    members.toggle(event);
  }

  onMemberSelect(event: any) {
    event.originalEvent.stopPropagation();

    if (!this.team.members) this.team.members = [];

    // Clone the selected user to avoid reference issues
    let user: User = { ...event.value };
    user.selected = true;
    user.jobTitleId = 4;

    // Add the cloned user to the selected members array
    this.team.members.push(user);

    // Reset the selectedMember (if used in the template)
    this.selectedMember = null;
  }

  duplicateMessage: string | null = null;

  onJobTitleChange(event: any, member: User) {
    this.team.members.map((x) => (x.hasError = false));
    this.duplicateMessage = null;
    member.hasError = false;
    member.jobTitleId = Number(event.target.value.split(':')[1].trim());

    this.jobTitles.some((jt) => {
      const _members = this.team.members.filter((x) => x.jobTitleId === jt.id);
      if (!jt.allowDuplicate && _members.length > 1) {
        this.duplicateMessage = `The highlighted job title cannot be assigned to more than one team member.`;
        jt.hasError = true;

        _members.map((x) => (x.hasError = true));
      }
    });

    if (this.duplicateMessage && this.duplicateMessage.length > 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: this.duplicateMessage,
        life: 3000,
      });
    }
  }

  removeMemeber(member: User) {
    const index = this.team.members.findIndex((m: User) => m.id === member.id);

    if (index !== -1) {
      this.team.members.splice(index, 1);
    }

    member.selected = false;
  }

  resetMembers() {
    this.team.members = [];
    this.duplicateMessage = null;
  }

  closeDropdown() {
    if (this.duplicateMessage && this.duplicateMessage.length > 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please correct job title errors',
        life: 3000,
      });
    } else {
      this.membersDropdownButton.nativeElement.click();
    }
  }

  public onSubmit() {
    if (this.validateAndSetData()) {
      this.apiService.addUpdateTeam(this.team).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  public modalClose() {
    this.dialogRef.close();
  }

  public validateAndSetData() {
    // Check if team name is provided
    if (!this.team.name) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter team name',
        life: 3000,
      });
      return false;
    }

    // Check if at least one team member is selected
    if (this.team.members.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You must select at least one team member',
        life: 3000,
      });
      return false;
    }

    return true;
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
}
