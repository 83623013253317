import { Component,Renderer2 } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from '../../../services/auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/Users';
import { ActivatedRoute , Router } from '@angular/router';
import { DealsFilter, Deals, DealsData } from 'src/app/models/Deals';
import { Remarks } from 'src/app/models/Remarks';
import { DealStatus, LeadStatus } from 'src/app/models/Status';
import { Result } from 'src/app/models/Result';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SharedService } from 'src/app/services/shared.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService,MessageService } from 'primeng/api';
import { GlobalVariables } from 'src/environments/environment';
import { Campaigns, CampaignsFilter } from 'src/app/Models/Campaigns';
import { ApiService } from 'src/app/services/api.service';
import { AddUpdateCampaignComponent } from '../add-update-campaign/add-update-campaign.component';
import { padZero } from 'src/app/utils/utils';
import { CampaignOverview } from 'src/app/Models/CampaignOverview';
import { Leads } from 'src/app/models/Leads';
import { AddupdateleadComponent } from '../../leads/addupdatelead/addupdatelead.component';
import { BulkDocumentsComponent } from '../../bulk-documents/bulk-documents.component';


@Component({
  selector: 'app-campaign-overview',
  templateUrl: './campaign-overview.component.html',
  styleUrl: './campaign-overview.component.css'
})
export class CampaignOverviewComponent {
  public statusTypeId: number = 2;
  campaignId:number;
  overview: any;
  isLoading:boolean;
  addLeadComponentHeader: string;
  public lead: Leads = new Leads();
  public usersList: User[] = [];
  public selectedUers: User[] = [];
  public leadStatusList: LeadStatus[] = [];

  constructor(
    private renderer: Renderer2,
    public modalService: DialogService,
    private apiService: ApiService,
    private router: Router,
    public authService: AuthServiceService,
    public activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private calendar: NgbCalendar,
    private sharedService: SharedService,
    private messageService: MessageService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const campaignId = +params['id'];
      this.campaignId = campaignId;
    });
  }

  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.loadData().then((t) => {
      this.createCharts();
      this.getAllUsers();
      this.getLeadStatus();
    });
  }
  public getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.usersList = resp.data;
        } else {
          this.usersList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  public getLeadStatus() {
    this.apiService.getLeadStatus(0, this.statusTypeId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadStatusList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  createCharts() {
    if(this.overview.campaignleadStats[0].dayNumber > 0){
      this.loadDailyTimeTrackingChart(this.overview.campaignleadStats);
    }
    else if(this.overview.campaignleadStats[0].monthNumber > 0){
      this.loadMonthlyLeadTrackingChart(this.overview.campaignleadStats);
    }

  }

  loadData(): Promise<CampaignOverview> {
    return new Promise<CampaignOverview>((resolve, reject) => {
      this.isLoading = true;
      this.apiService.getCampaignOverview(
          this.campaignId
        )
        .subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.overview = resp.data;
              this.isLoading = false;
              if (this.overview) {
                // You can add any additional logic here if needed
              }

              resolve(this.overview); // Resolve the promise when the operation is successful
            } else {
              // Handle cases where the response status is not 'success'
              reject(new Error('Failed to get totals: ' + resp.status));
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
            reject(error); // Reject the promise on error
          },
        });
    });
  }

  //#region Daily Time Tracking

  dailyTimeTrackingData: any = null;

  loadDailyTimeTrackingChart(t) {
    let ctx = this.renderer.createElement('canvas');

    const gradient = ctx.getContext('2d').createLinearGradient(100, 0, 50, 150);
    gradient.addColorStop(0, 'rgba(0, 101, 255, 0.2)');
    gradient.addColorStop(1, 'rgba(0, 101, 255, 0)');



    this.dailyTimeTrackingData = {
      labels: t.map((x) => x.leadPeriod),
      datasets: [
        {
          label: 'Total Leads',
          fontFamily: 'Poppins',
          data: t.map((x) => x.totalLeads),
          fill: true,
          pointBorderWidth: 1,
          pointRadius: new Array(t.map((x) => x.totalLeads)).fill(2),
          pointHoverRadius: new Array(t.map((x) => x.totalLeads)).fill(4),
          tension: 0.4,
          borderDash: [5, 5],
          borderWidth: 1.5,
          borderColor: 'rgba(0, 101, 255)',
          backgroundColor: gradient,
        },
        {
          label: 'Qualified Leads',
          fontFamily: 'Poppins',
          data: t.map((x) => x.qualifiedLeads),
          fill: false,
          pointBorderWidth: 1,
          pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
          tension: 0.4,
          borderWidth: 1.5,
          borderColor: 'rgba(255, 22, 139)',
          backgroundColor: 'rgba(255, 22, 139, 0.2)',
        },
        {
          label: 'Converted Leads',
          fontFamily: 'Poppins',
          data: t.map((x) => x.convertedLeads),
          fill: true,
          pointBorderWidth: 1,
          pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
          tension: 0.4,
          borderWidth: 1.5,
          borderColor: 'rgba(42, 33, 186)',
          backgroundColor:'rgba(42, 33, 186, 0.2)',
        },
      ],
    };
  }

  //#endregion

   //#region Monthly Actual vs Estimated Cost

   monthlyActualvsEstimatedCostData: any = null;

   loadMonthlyLeadTrackingChart(trend) {
     let ctx = this.renderer.createElement('canvas');

     const gradient = ctx.getContext('2d').createLinearGradient(100, 0, 50, 150);
     gradient.addColorStop(0, 'rgba(0, 255, 17, 0.2)');
     gradient.addColorStop(1, 'rgba(0, 255, 17, 0)');
     const months =  trend.map((x) => x.leadPeriod);
     const totalLeads = trend.map((t) => t.totalLeads);
     const qualifiedLeads = trend.map((t) => t.qualifiedLeads);
     const convertedLeads = trend.map((t) => t.convertedLeads);


     this.monthlyActualvsEstimatedCostData = {
       labels: months,
       datasets: [
         {
           label: 'Total Leads',
           fontFamily: 'Poppins',
           data: totalLeads,
           fill: true,
           pointBorderWidth: 1,
           pointRadius: new Array(totalLeads.length).fill(2),
           pointHoverRadius: new Array(totalLeads.length).fill(4),
           tension: 0.4,
           borderDash: [5, 5],
           borderWidth: 1.5,
           borderColor: 'rgba(0, 255, 17)',
           backgroundColor: gradient,
         },
         {
          label: 'Qualified Leads',
          fontFamily: 'Poppins',
          data: qualifiedLeads,
          fill: false,
          pointBorderWidth: 1,
          pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
          tension: 0.4,
          borderWidth: 1.5,
          borderColor: 'rgba(255, 22, 139)',
          backgroundColor: 'rgba(255, 22, 139, 0.2)',
        },
        {
          label: 'Converted Leads',
          fontFamily: 'Poppins',
          data: convertedLeads,
          fill: true,
          pointBorderWidth: 1,
          pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
          tension: 0.4,
          borderWidth: 1.5,
          borderColor: 'rgba(42, 33, 186)',
          backgroundColor:'rgba(42, 33, 186, 0.2)',
        },
       ],
     };
   }

   //#endregion

   //#region Chart Optiions

   chartOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
    },
  };
  chartOptionsCost: any = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const value = tooltipItem.raw;
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          }
        }
      },
      legend: {
        display: false,
      },
    },
    aspectRatio: 3.5, // Adjust this ratio to control the height of the chart area
  };
  //#endregion

  addNewLead(){
    this.addLeadComponentHeader = 'Add Lead';
    this.lead = new Leads();

    //for current user selected
    const obj = this.usersList.filter(
      (user) => user.id === this.authService?.currentUserValue.id
    );
    this.selectedUers = obj;

    this.lead.statusId = 3

    const modalRef = this.dialogService.open(AddupdateleadComponent, {
      modal: true,
      showHeader: true,
      header: this.addLeadComponentHeader,
      width: '60%',
      height: '100%',
      contentStyle: { overflow: 'hidden', paddingRight: 0 },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        users: this.usersList,
        projectId: GlobalVariables.selectedProjectId,
        lead: this.lead,
        leadStatusList: this.leadStatusList,
        selectedUers: this.selectedUers,
        campaignId: this.campaignId
      },
    });
    modalRef.onClose.subscribe(() => {
      this.lead = new Leads();
    });
  }
}
