import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Remarks } from 'src/app/models/Remarks';
import { LeadStatus } from 'src/app/models/Status';
import { Result } from 'src/app/models/Result';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Leads } from 'src/app/models/Leads';
import { ConfirmationService, MessageService } from 'primeng/api';
import { User } from 'src/app/models/Users';
import { AddEventComponent } from '../../events/addevent/addevent.component';
import { GlobalVariables } from 'src/environments/environment';
@Component({
  selector: 'app-leadview',
  templateUrl: './leadview.component.html',
  styleUrls: ['./leadview.component.css'],
})
export class LeadviewComponent {
  @Output() onModalClosed: EventEmitter<any> = new EventEmitter<any>();

  public statusTypeId: number = 2; // Incase of lead component
  public projectId: number | undefined;
  public lead: Leads = new Leads();
  public logs: Remarks[] = [];
  public statusList: LeadStatus[] = [];
  public usersList: User[] = [];
  public remarks: Remarks = new Remarks();
  public isLoading: boolean = false;

  constructor(
    public apiService: ApiServiceService,
    public authService: AuthServiceService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dialogService: DialogService,
  ) {
    this.lead = this.dialogConfig.data.item;
    this.projectId = this.dialogConfig.data.projectId;
    this.usersList = this.dialogConfig.data.users;
    this.remarks.statusId = this.lead.statusId;
    if(this.lead.assignees){
      this.lead.assigneesArray = this.lead.assignees.split(',');
    }
  }
  ngOnInit(): void {
    this.GetRemarksByLeadId(this.lead?.id);
    this.getLeadStatus();
  }
  public getLeadStatus(): void {
    this.apiService.getLeadStatus(this.projectId, this.statusTypeId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.statusList = resp.data;
        } else {
          this.statusList = resp.data;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  public validateAndSetData() {
    if (!this.remarks.details || this.remarks.details === '') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please Add Some Remarks',
        life: 3000,
      });
      return false;
    } else {
      return true;
    }
  }
  public addRemarks(): void {
    if (this.validateAndSetData()) {
      this.remarks.dateCreated = new Date();
      this.remarks.userId = this.authService.currentUserValue.id;
      this.remarks.isActive = true;
      this.remarks.leadId = this.lead?.id;
      this.apiService.addUpdateRemarks(this.remarks).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.GetRemarksByLeadId(this.lead?.id);
            this.messageService.add({
              severity: 'success',
              summary: resp.status,
              detail: resp.message,
              life: 3000,
            });
            this.remarks = new Remarks();
            this.remarks.statusId = this.lead.statusId;
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
        complete: () => {},
      });
    }
  }
  public GetRemarksByLeadId(id: number): void {
    this.isLoading = true;
    this.apiService.getRemarksByLeadId(id).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        if (resp.status === 'success') {
          this.logs = resp.data;
        } else {
          this.logs = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  public deleteLogById(id: number): void {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message: 'Do you want to delete this lead?',
      accept: () => {
        this.apiService.deleteLogById(id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.GetRemarksByLeadId(this.lead?.id);
              this.messageService.add({
                severity: 'success',
                summary: resp.status,
                detail: resp.message,
                life: 3000,
              });
            } else {
              this.GetRemarksByLeadId(this.lead?.id);
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  openEventAddEditView(): void {
    const modalRef = this.dialogService.open(AddEventComponent, {
      modal: true,
      header: 'Follow-Up Reminder',
      width: '36%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        projectId: GlobalVariables.selectedProjectId,
        mode: 'New',
        leadId: this.lead.id,
        users: this.usersList,
        isFollowUpOnly: true,
      },
    });
  }

  public editLogById(item: any) {
    this.remarks = item;
    this.remarks.isEdit = true;
  }

  public resetLogForm(): void {
    this.remarks = new Remarks();
    this.remarks.statusId = this.lead.statusId;
  }

  close(): void {
    this.dialogRef.close();
    this.onModalClosed.emit();
  }
}
