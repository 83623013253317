<!-- BEGIN #header -->
<div id="header" class="app-header">
  <!-- BEGIN menu -->
  <div class="menu">
    <div class="menu-search client-search-main-layout" name="header_search_form">
      <!-- <div class="search-container">
        <select class="form-select" [(ngModel)]="selectedProjectId" (change)="onProjectChange()">
          <option *ngFor="let item of userProjects" [selected]="item.isDefault" value="{{item.id}}">
            {{item.name}}
          </option>
        </select>
      </div> -->

      <div class="d-flex align-items-center ms-3 text-nowrap">
        <span style="
        font-size: large;
        margin-right: 10px;">{{ "Current Project" | translate }} <i class="fa-duotone fa-angles-right"></i></span>
        <div
          style="font-size: 1rem;border-radius: 50px;margin-right: 4px;color: #747474;display: flex;align-items: center;justify-content: center;">
          <img [src]="selectedProject?.icon" style="height: 20px; width: 20px;" />
        </div>

        <h5 class="m-0 ellipsis" style="margin-top: 2px !important;">{{selectedProject?.name}}</h5>

        <div class="btn-group ml-2">
          <button class="btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
            style="
            color: #00a7ff;
            font-weight: 500;
        ">
            {{ "Change" | translate }}
          </button>
          <ul class="dropdown-menu dropdown-cust">
            <li *ngFor="let p of userProjects" style="padding: 0.3rem 0.3rem 0.6rem 0.3rem;">
              <a class="dropdown-item d-flex align-items-center" (click)="onProjectChange(p)">
                <img [src]="p?.icon" style="height: 18px; width: 18px; margin-right: 4px;border: 1px solid #2d4173;
                padding: 2px;
                border-radius: 50%;" />
                <span>{{p.name}}</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex blinking-text">
      <a class="right-items-dev " *ngIf="isDevelopment()" pTooltip="DEV API MODE" tooltipPosition="top">
        <i class="fa-solid fa-triangle-person-digging"></i>
      </a>
    </div>
    
    <div *ngIf="trial" class="trial-header-strip">
      <div class="trial-header-content">
        <p class="m-0">{{trial.getExpiryMessage()}} <a href="#" (click)="upgradeNow()">Upgrade now</a></p>
      </div>
    </div>
    <div class="menu-item dropdown mr-2">
      <a data-toggle="dropdown" data-display="static" class="menu-link">
        <div class="menu-img online username-wrap">
          <img [src]="userInfo.profilePictureUrl" alt="" class="rounded-circle"
            style="object-fit: cover;height: 42px;width: 42px;border: 2px solid #14202b; padding: 2px" />
        </div>
        <div class="menu-text">
          <p class="m-0">{{ userInfo.name }}</p><span style="
          font-size: 11px;">{{userInfo.userType}}</span>
        </div>


      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-cust mr-lg-3">

        <!-- <a class="dropdown-item d-flex align-items-center">Timeline <i
            class="fa fa-envelope fa-fw ml-auto text-gray-400 f-s-16"></i></a>
        <a class="dropdown-item d-flex align-items-center">Calendar <i
            class="fa fa-calendar-alt fa-fw ml-auto text-gray-400 f-s-16"></i></a> -->
        <a class="dropdown-item d-flex align-items-center" (click)="editProfile(userInfo)">Profile <i
            class="fa fa-wrench fa-fw ml-auto text-gray-400 f-s-16"></i></a>
        <a class="dropdown-item d-flex align-items-center" (click)="changePassword(userInfo)">Change Password <i
              class="fa fa-key  fa-fw ml-auto text-gray-400 f-s-16"></i></a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item d-flex align-items-center" style="cursor: pointer;" (click)="logout()">Log Out <i
            class="fa fa-toggle-off fa-fw ml-auto text-gray-400 f-s-16"></i></a>
      </div>
    </div>
  </div>
  <!-- END menu -->

</div>
<!-- END #header -->

<div class="sidebar" style="background:none">
  <app-sidebar />
</div>

<div class="app-content" id="content">
  <!-- BEGIN container -->
  <div>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<ng-container *ngIf="isConfirm">

  <app-attendance-confirmation (onConfirmed)="confirmMarkAttendance($event)" />
</ng-container>
<ng-container *ngIf="isLocation">
  <app-toast (retry)="requestLocation()" #toast></app-toast>
</ng-container>
<footer class="footer fixed">
  <div class="d-sm-flex justify-content-center justify-content-sm-between">
    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Client Track powered by <a
        href="https://www.softnology.com/" target=_blank>Softnology Systems</a></span>
    <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Copyright © 2024. All
      rights reserved.</span>
  </div>
</footer>
