import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ProductFilter, Products } from 'src/app/Models/Products';

@Component({
  selector: 'app-product-filters',
  templateUrl: './product-filters.component.html',
  styleUrl: './product-filters.component.css',
  animations: [
    trigger('childSlide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left <=> right', animate('300ms')),
    ]),
  ],
})
export class ProductFiltersComponent {
  @Input() data: any | undefined;
  @Input() slideState: string | undefined;
  @Output() onHideRequest = new EventEmitter<boolean>();
  @Output() onSearchRequest = new EventEmitter<any>();

  public filter: any = new ProductFilter();
  fromDate: any = null; //new Date(new Date().setMonth(new Date().getMonth() - 1)); //new Date;"1-1-2022";
  toDate: any = null; //new Date(); //"12-31-2022";
  /**
   *
   */
  constructor(
    public activeModal: NgbActiveModal,

  ) {
this.filter= new ProductFilter();

  }
  hideFilter() {
    this.onHideRequest.emit(true);
  }
  applyFilter(): void {
    if (this.fromDate) {
      this.filter.fromDate = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      );
    }
    if (this.toDate) {
      this.filter.toDate = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day
      );
    }
    this.filter.pageNumber = 1;
    this.onSearchRequest.emit(this.filter);
  }
  resetFilters(): void {
    this.filter = new ProductFilter();
    this.filter.pageNumber = 1;
    this.onSearchRequest.emit(this.filter);
  }
}
