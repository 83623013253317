export class Data {
  static leadTypes = [
    { id: 1, type: 'New Lead' },
    { id: 2, type: 'Existing Customer' },
    { id: 3, type: 'Referral' },
    { id: 4, type: 'Sales Lead' },
    { id: 5, type: 'Marketing Lead' },
    { id: 6, type: 'Product/Service Inquiry' },
    { id: 7, type: 'Event Lead' },
    { id: 8, type: 'Social Media Lead' },
    { id: 9, type: 'Website Lead' },
    { id: 10, type: 'Email Campaign Lead' },
    { id: 11, type: 'Partner Lead' },
    { id: 12, type: 'Reseller' },
    { id: 13, type: 'Competitor' },
    { id: 14, type: 'Others' },
  ];
  static leadSources = [
    { id: 1, source: 'Website' },
    { id: 2, source: 'Social Media' },
    { id: 3, source: 'Email Campaign' },
    { id: 4, source: 'Referral' },
    { id: 5, source: 'Event' },
    { id: 6, source: 'Webinar' },
    { id: 7, source: 'Paid Advertising' },
    { id: 8, source: 'Cold Call' },
    { id: 9, source: 'Networking' },
    { id: 10, source: 'Direct Mail' },
    { id: 11, source: 'Partnership' },
    { id: 12, source: 'Trade Show' },
    { id: 13, source: 'Competitor' },
    { id: 14, source: 'Other' },
  ];
  static entityTypes = [
    { id: 1, type: 'Business' },
    { id: 2, type: 'Individual' },
  ];
  static industryTypes = [
    { id: 1, type: 'Retail' },
    { id: 2, type: 'Wholesale' },
    { id: 3, type: 'Manufacturing' },
    { id: 4, type: 'Information Technology' },
    { id: 5, type: 'Healthcare' },
    { id: 6, type: 'Finance' },
    { id: 7, type: 'Education' },
    { id: 8, type: 'Construction' },
    { id: 9, type: 'Real Estate' },
    { id: 10, type: 'Transportation and Logistics' },
    { id: 11, type: 'Hospitality and Tourism' },
    { id: 12, type: 'Telecommunications' },
    { id: 13, type: 'Energy and Utilities' },
    { id: 14, type: 'Non-Profit' },
    { id: 15, type: 'Agriculture' },
  ];
  static industrySpecializations = [
    // Retail Specializations (parentId: 1)
    { id: 1, specialization: 'E-commerce', parentId: 1 },
    { id: 2, specialization: 'Brick-and-Mortar', parentId: 1 },
    { id: 3, specialization: 'Fashion', parentId: 1 },
    { id: 4, specialization: 'Electronics', parentId: 1 },
    { id: 5, specialization: 'Grocery', parentId: 1 },

    // Wholesale Specializations (parentId: 2)
    { id: 6, specialization: 'Food Distribution', parentId: 2 },
    { id: 7, specialization: 'B2B Sales', parentId: 2 },
    { id: 8, specialization: 'Industrial Products', parentId: 2 },
    { id: 9, specialization: 'Consumer Goods', parentId: 2 },

    // Manufacturing Specializations (parentId: 3)
    { id: 10, specialization: 'Corrugation', parentId: 3 },
    { id: 11, specialization: 'Offset Printing', parentId: 3 },
    { id: 12, specialization: 'Paper Core', parentId: 3 },
    { id: 13, specialization: 'Tobacco', parentId: 3 },
    { id: 14, specialization: 'Paper Bag', parentId: 3 },
    { id: 15, specialization: 'Match Industry', parentId: 3 },
    { id: 16, specialization: 'Pencil', parentId: 3 },
    { id: 17, specialization: 'Tissue Paper', parentId: 3 },
    { id: 18, specialization: 'Wood Working', parentId: 3 },
    { id: 19, specialization: 'Home Packaging', parentId: 3 },
    { id: 20, specialization: 'Foil Lamination', parentId: 3 },
    { id: 21, specialization: 'Automotive Filter', parentId: 3 },

    // Information Technology Specializations (parentId: 4)
    { id: 22, specialization: 'Software Development', parentId: 4 },
    { id: 23, specialization: 'Cloud Computing', parentId: 4 },
    { id: 24, specialization: 'Cybersecurity', parentId: 4 },
    { id: 25, specialization: 'IT Services', parentId: 4 },
    { id: 26, specialization: 'Data Centers', parentId: 4 },

    // Healthcare Specializations (parentId: 5)
    { id: 27, specialization: 'Hospitals', parentId: 5 },
    { id: 28, specialization: 'Pharmaceuticals', parentId: 5 },
    { id: 29, specialization: 'Medical Devices', parentId: 5 },
    { id: 30, specialization: 'Telemedicine', parentId: 5 },
    { id: 31, specialization: 'Diagnostics', parentId: 5 },

    // Finance Specializations (parentId: 6)
    { id: 32, specialization: 'Banking', parentId: 6 },
    { id: 33, specialization: 'Insurance', parentId: 6 },
    { id: 34, specialization: 'Investment Management', parentId: 6 },
    { id: 35, specialization: 'Fintech', parentId: 6 },
    { id: 36, specialization: 'Accounting', parentId: 6 },

    // Education Specializations (parentId: 7)
    { id: 37, specialization: 'K-12 Schools', parentId: 7 },
    { id: 38, specialization: 'Higher Education', parentId: 7 },
    { id: 39, specialization: 'Vocational Training', parentId: 7 },
    { id: 40, specialization: 'E-Learning', parentId: 7 },
    { id: 41, specialization: 'Test Preparation', parentId: 7 },

    // Construction Specializations (parentId: 8)
    { id: 42, specialization: 'Residential Construction', parentId: 8 },
    { id: 43, specialization: 'Commercial Construction', parentId: 8 },
    { id: 44, specialization: 'Infrastructure', parentId: 8 },
    { id: 45, specialization: 'Civil Engineering', parentId: 8 },
    { id: 46, specialization: 'Interior Design', parentId: 8 },

    // Real Estate Specializations (parentId: 9)
    { id: 47, specialization: 'Property Management', parentId: 9 },
    { id: 48, specialization: 'Real Estate Development', parentId: 9 },
    { id: 49, specialization: 'Commercial Real Estate', parentId: 9 },
    { id: 50, specialization: 'Real Estate Investment', parentId: 9 },

    // Transportation and Logistics Specializations (parentId: 10)
    { id: 51, specialization: 'Freight Forwarding', parentId: 10 },
    { id: 52, specialization: 'Shipping and Delivery', parentId: 10 },
    { id: 53, specialization: 'Warehousing', parentId: 10 },
    { id: 54, specialization: 'Fleet Management', parentId: 10 },
    { id: 55, specialization: 'Supply Chain Management', parentId: 10 },

    // Hospitality and Tourism Specializations (parentId: 11)
    { id: 56, specialization: 'Hotels', parentId: 11 },
    { id: 57, specialization: 'Restaurants', parentId: 11 },
    { id: 58, specialization: 'Travel Agencies', parentId: 11 },
    { id: 59, specialization: 'Event Planning', parentId: 11 },
    { id: 60, specialization: 'Cruises', parentId: 11 },

    // Telecommunications Specializations (parentId: 12)
    { id: 61, specialization: 'Mobile Network Providers', parentId: 12 },
    { id: 62, specialization: 'Broadband Services', parentId: 12 },
    { id: 63, specialization: 'VoIP', parentId: 12 },
    { id: 64, specialization: 'Satellite Communications', parentId: 12 },
    { id: 65, specialization: 'Telecom Equipment', parentId: 12 },

    // Energy and Utilities Specializations (parentId: 13)
    { id: 66, specialization: 'Renewable Energy', parentId: 13 },
    { id: 67, specialization: 'Oil and Gas', parentId: 13 },
    { id: 68, specialization: 'Electricity Distribution', parentId: 13 },
    { id: 69, specialization: 'Water Management', parentId: 13 },
    { id: 70, specialization: 'Waste Management', parentId: 13 },

    // Non-Profit Specializations (parentId: 14)
    { id: 71, specialization: 'Charitable Organizations', parentId: 14 },
    { id: 72, specialization: 'Educational Foundations', parentId: 14 },
    { id: 73, specialization: 'Healthcare NGOs', parentId: 14 },
    { id: 74, specialization: 'Environmental Groups', parentId: 14 },

    // Agriculture Specializations (parentId: 15)
    { id: 75, specialization: 'Crop Farming', parentId: 15 },
    { id: 76, specialization: 'Dairy Farming', parentId: 15 },
    { id: 77, specialization: 'Agrochemicals', parentId: 15 },
    { id: 78, specialization: 'Organic Farming', parentId: 15 },
    { id: 79, specialization: 'Livestock Farming', parentId: 15 },
  ];
}
