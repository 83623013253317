<div class="container">
  <div class="d-flex justify-content-between align-items-center">
    <h5>
      Manage Projects
    </h5>
  </div>

  <div class="search-container">
    <div>
      <!-- Search input -->
      <input type="text" class="form-control mb-3" placeholder="Search projects.." [(ngModel)]="searchKeyword"
        (input)="searchProjects()">
    </div>

    <div class="d-flex align-items-center justify-content-end">
      <button class="btn btn-primary action-button me-2" (click)="addNewProject()"><i class="fa-light fa-plus"></i>
        New Project</button>
      <!-- <button class="btn btn-sm btn-outline-dark action-button-outline" (click)="toggleFilter()"><i
          class="fa-regular fa-magnifying-glass"></i>
        Search</button> -->
    </div>
  </div>

  <div class="row" *ngFor="let project of filteredProjects">
    <div class="col-md-12" >

      <div class="card mb-3">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <img *ngIf="project.icon; else noPhoto" src="{{ project.icon }}" class="mr-3" style="height: 50px;width: 50px;object-fit: cover;" alt="Project Icon">
            <h5 class="card-title mb-0">{{ project.name }}</h5>
          </div>
          <div class="btn-group" role="group" aria-label="Project Actions">
            <button type="button" class="btn btn-outline-primary" (click)="editProject(project)">
              <i class="fas fa-edit"></i>
            </button>
            <button type="button" class="btn btn-outline-danger" (click)="deleteProject(project)">
              <i class="fas fa-trash-alt"></i>
            </button>
            <button type="button" class="btn btn-outline-secondary" [disabled]="project.isDefault" (click)="makeDefault(project)">
              <i class="fas fa-star"></i> Set As Default
            </button>
          </div>
        </div>
        <div class="card-body">
          <p class="card-text">{{ project.description }}</p>
        </div>
      </div>

    </div>
  </div>

  <ng-template #noPhoto>
    <svg class="preview"
      style="vertical-align: middle;fill: #b3b3b3;overflow: hidden;height: 50px;width: 50px;object-fit: cover;margin-right: 1.2rem;"
      version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 350"
      xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 350 350">
      <path
        d="M5,350h340V0H5V350z M25,330v-62.212h300V330H25z M179.509,247.494H60.491L120,171.253L179.509,247.494z   M176.443,211.061l33.683-32.323l74.654,69.05h-79.67L176.443,211.061z M325,96.574c-6.384,2.269-13.085,3.426-20,3.426  c-33.084,0-60-26.916-60-60c0-6.911,1.156-13.612,3.422-20H325V96.574z M25,20h202.516C225.845,26.479,225,33.166,225,40  c0,44.112,35.888,80,80,80c6.837,0,13.523-0.846,20-2.518v130.306h-10.767l-104.359-96.526l-45.801,43.951L120,138.748  l-85.109,109.04H25V20z" />
    </svg>
  </ng-template>
</div>
