export class Products {
  id: number;
  dateCreated: Date = new Date();
  name: string;
  price: number;
  productCategory: string;
  stockQuantity: number;
  sku: string;
  description: string;
  dateUpdated: string;
  isActive: boolean | null;
  orgId: number;
  rowNumber: any;
  totalRecords: number;
}
export class ProductFilter {
  fromDate: Date;
  toDate: Date;
  query: string;
  pageNumber: number;
  pageSize: number;
  projectId: number;
}
