import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DealsFilter, Deals } from 'src/app/models/Deals';
import { Remarks } from 'src/app/models/Remarks';
import { DealStatus } from 'src/app/models/Status';
import { Result } from 'src/app/models/Result';
import { User } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-deals-filters',
  templateUrl: './deals-filters.component.html',
  styleUrls: ['./deals-filters.component.css'],
  animations: [
    trigger('childSlide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left <=> right', animate('300ms')),
    ]),
  ],
})
export class DealsFiltersComponent {
  @Input() data: any | undefined;
  @Input() slideState: string | undefined;
  @Output() onHideRequest = new EventEmitter<boolean>();
  @Output() onSearchRequest = new EventEmitter<DealsFilter>();

  public dealsFilter: DealsFilter = new DealsFilter();
  public deals: Deals = new Deals();
  public logs: Remarks = new Remarks();
  public alldeals: Deals[] = [];
  public allStatus: DealStatus[] = [];
  public allUsers: User[] = [];
  public allLogs: Remarks[] = [];
  fromDate: any = null; //new Date(new Date().setMonth(new Date().getMonth() - 1)); //new Date;"1-1-2022";
  toDate: any = null; //new Date(); //"12-31-2022";
 public selectedUers: User[] = [];
  public selectedUersFilter: User[] = [];

  dropdownSettingsUsers = {
    idField: 'id',
    textField: 'name',
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: 'There is no item availabale to show',
    singleSelection: false,
    enableCheckAll: true,
    clearSearchFilter: true,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiServiceService,
    private toastr: ToastrService,
    private calendar: NgbCalendar,
    private messageService: MessageService,
  ) {
    // this.loadStatuses();
    this.loadUsers();
  }

  hideFilter() {
    this.onHideRequest.emit(true);
  }

  loadUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe(
      { next:
        (resp: Result) => {
          if (resp.status === 'success') {
            this.allUsers = resp.data;
          } else {
            this.allUsers = resp.data;
            this.messageService.add({severity: 'warn', summary: 'Warning', detail: resp.message, life: 3000,});
          }
        },
        error:
        (error) => {
          console.error('error:', error);
        },
        complete:()=>
        {

        }
      }

    );
  }


  applyFilter(): void {
    if (this.fromDate) {
      this.dealsFilter.fromDate = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      );
    }
    if (this.toDate) {
      this.dealsFilter.toDate = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day
      );
    }
    this.dealsFilter.pageNumber=1;
    this.dealsFilter.userIds = this.selectedUersFilter ? this.selectedUersFilter?.map((x) => x.id):[];
    this.onSearchRequest.emit(this.dealsFilter);
  }
  resetFilters(): void {
    this.dealsFilter= new DealsFilter();
    this.onDeSelectAllIUsersFilter('');
    this.dealsFilter.pageNumber=1;
    this.onSearchRequest.emit(this.dealsFilter);
  }
  selectUsers(e: any) {
    const data = e;
    this.selectedUers.push(data);
  }

  onItemDeSelectUsers(item: any) {
    this.selectedUers?.forEach((value: any, index: any) => {
      if (value == item.id) this.selectedUers?.splice(index, 1);
    });
  }

  onSelectAllUsers(items: any) {
    this.selectedUers = items;
  }

  onDeSelectAllIUsers(items: any) {
    this.selectedUers = items;
  }

  selectUsersFilter(e: any) {
    const data = e;
    this.selectedUersFilter.push(data);
  }

  onItemDeSelectUsersFilter(item: any) {
    this.selectedUersFilter?.forEach((value: any, index: any) => {
      if (value == item.id) this.selectedUersFilter?.splice(index, 1);
    });
  }

  onSelectAllUsersFilter(items: any) {
    this.selectedUersFilter = items;
  }

  onDeSelectAllIUsersFilter(items: any) {
    this.selectedUersFilter = items;
  }
}
