<div class="modal-body">

  <div class="section-title mb-3 mt-3">
    <h5><i class="far fa-info-circle me-1"></i> Campaign Details</h5>
    <div class="desc">Provide basic information and schedule for the campaign</div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="name">Name: <span class="text-danger">* </span></label>
        <input type="text" class="form-control" ngbInput [(ngModel)]="campaigns.name" name="name"
          placeholder="Give your campaign a name">
      </div>
    </div>
    <div class="form-group col-md-3">
      <label for="startDate">Start Date </label>
      <p-calendar [(ngModel)]="campaigns.startDate" name="startDate" placeholder="Select start date"
        [icon]="'fa-regular fa-calendar'" [showIcon]="true" [showTime]="true" appendTo="body" [showButtonBar]="true"
        required></p-calendar>
    </div>
    <div class="form-group col-md-3">
      <label for="dueDate">Due Date </label>
      <p-calendar [(ngModel)]="campaigns.endDate" name="dueDate" placeholder="Select due date"
        [icon]="'fa-regular fa-calendar'" [showIcon]="true" [showTime]="true" appendTo="body"
        [showButtonBar]="true"></p-calendar>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="name">Campaign Description:</label>
        <textarea class="form-control" name="description" [(ngModel)]="campaigns.description" rows="3"></textarea>
      </div>
    </div>
  </div>

  <div class="section-title mb-3 mt-4">
    <h5><i class="far fa-cogs me-1"></i> Campaign Settings</h5>
    <div class="desc">Adjust the settings and preferences for the campaign</div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label for="statusselect">Type: <span class="text-danger">* </span></label>
        <p-dropdown appendTo="body" name="statusselect" [options]="campaignTypeList"
          [(ngModel)]="campaigns.campaignTypeId" optionLabel="typeName" optionValue="id"
          placeholder="Select campaign type" required />
      </div>
    </div>

    <div class="col-md-3">
      <div class="form-group">
        <label for="statusselect">Status: <span class="text-danger">* </span></label>
        <p-dropdown appendTo="body" name="statusselect" [options]="campaignStatusList" [(ngModel)]="campaigns.statusId"
          optionLabel="name" optionValue="id" placeholder="Select campaign status">
        </p-dropdown>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="leadType">Lead Group</label>
        <p-multiSelect name="usersselect" [options]="leadGroupsList" [(ngModel)]="selectedLeadGroups"
        placeholder="Select Lead Group" optionLabel="groupName" display="chip"></p-multiSelect>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="email">Budget: (amount)</label>
        <input type="number" class="form-control" name="allocatedBudget" [(ngModel)]="campaigns.allocatedBudget"
          required>
      </div>
    </div>
  </div>

  <div class="section-title mb-3 mt-4">
    <h5><i class="far fa-bullseye me-1"></i> Target Entities</h5>
    <div class="desc">Specify the client and product associated with this campaign</div>
  </div>

  <div class="row">

    <div class="col-md-6">
      <div class="form-group">
        <label for="usersselect">Client:</label>
        <div>
          <p-dropdown [options]="clientList" optionLabel="clientName" [filter]="true" [(ngModel)]="campaigns.clientId"
            filterBy="clientName" [showClear]="false" dataKey="id" optionValue="id" placeholder="Select Client">
          </p-dropdown>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label for="usersselect">Product:</label>
        <div>
          <p-dropdown [options]="productList" optionLabel="name" [filter]="true" [(ngModel)]="campaigns.productId"
            filterBy="name" [showClear]="false" dataKey="id" optionValue="id" placeholder="Select Product">
          </p-dropdown>
        </div>
      </div>
    </div>

  </div>

  <div class="section-title mb-3 mt-4">
    <h5><i class="far fa-folder-open me-1"></i> Assets</h5>
    <div class="desc">Manage assets such as media and resources linked to the campaign</div>
  </div>

  <div class="container mt-3 mb-5">
    <div *ngFor="let camp of campaigns.campaignAssets; let i = index" class="row mt-3">
      <div class="col-md-3 me-3">
        <div class="form-group">
          <label for="category" class="d-block"> Asset Type</label>
          <p-dropdown [options]="assetTypeDbList" name="drp-category{{i}}" optionLabel="assetTypeName" [filter]="true"
            appendTo="body" [(ngModel)]="camp.assetTypeId" filterBy="assetTypeName" [showClear]="false" dataKey="id"
            optionValue="id" placeholder="Select an asset type">
          </p-dropdown>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="estimatedHours">Asset Name</label>
          <input type="text" class="form-control" ngbInput [(ngModel)]="camp.assetName" name="name">
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="estimatedCost">Asset URL</label>
          <input type="text" class="form-control" ngbInput [(ngModel)]="camp.assetURL" name="name">
        </div>
      </div>

      <div class="col-md-1"><button type="button" style="height: 25px;margin-top: 22px;"
          class="btn btn-sm btn-secondary" (click)="removeAssetType(i)">
          <i class="fa fa-light fa-times"></i>
        </button></div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-sm btn-outline-dark action-button-outline bg-white"
        (click)="addAssetTypes()"><i class="fa-regular fa-plus mr-1"></i>Add New Asset</button>
    </div>

  </div>

</div>


<div class="modal-footer">
  <!-- Footer Buttons -->
  <div class="text-right">
    <button type="button" class="btn btn-sm action-button-cancel me-2" (click)="modalClose()">Cancel</button>
    <button type="button" (click)="onSubmit()" [disabled]="this.isDataSaving"
      class="btn btn-primary btn-sm action-button">Create Campaign</button>&nbsp;&nbsp;
  </div>
</div>
