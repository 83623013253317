import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { Kanban } from '@syncfusion/ej2-kanban';
const _c0 = ["template"];
const _c1 = ["tooltipTemplate"];
const _c2 = ["columnsTemplate"];
const _c3 = ["swimlaneSettingsTemplate"];
const _c4 = ["cardSettingsTemplate"];
const _c5 = ["dialogSettingsTemplate"];
export * from '@syncfusion/ej2-kanban';
import { CommonModule } from '@angular/common';
let input$1 = ['allowDrag', 'allowDrop', 'allowToggle', 'headerText', 'isExpanded', 'keyField', 'maxCount', 'minCount', 'showAddButton', 'showItemCount', 'template', 'transitionColumns'];
let outputs$2 = [];
/**
 * `e-columns` directive represent a columns of the Kanban board.
 * It must be contained in a Kanban component(`ejs-kanban`).
 * ```html
 * <ejs-kanban>
 *   <e-columns>
 *    <e-column keyField='Open' textField='To Do'></e-column>
 *    <e-column keyField='Close' textField='Completed'></e-column>
 *   </e-columns>
 * </ejs-kanban>
 * ```
 */
class ColumnDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input$1;
  }
}
ColumnDirective.ɵfac = function ColumnDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnDirective,
  selectors: [["e-column"]],
  contentQueries: function ColumnDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
    }
  },
  inputs: {
    allowDrag: "allowDrag",
    allowDrop: "allowDrop",
    allowToggle: "allowToggle",
    headerText: "headerText",
    isExpanded: "isExpanded",
    keyField: "keyField",
    maxCount: "maxCount",
    minCount: "minCount",
    showAddButton: "showAddButton",
    showItemCount: "showItemCount",
    template: "template",
    transitionColumns: "transitionColumns"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ColumnDirective.prototype, "template", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnDirective, [{
    type: Directive,
    args: [{
      selector: 'e-columns>e-column',
      inputs: input$1,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();
/**
 * Column Array Directive
 * @private
 */
class ColumnsDirective extends ArrayBase {
  constructor() {
    super('columns');
  }
}
ColumnsDirective.ɵfac = function ColumnsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnsDirective)();
};
ColumnsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnsDirective,
  selectors: [["e-columns"]],
  contentQueries: function ColumnsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ColumnDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-kanban>e-columns',
      queries: {
        children: new ContentChildren(ColumnDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input = ['keyFields', 'text'];
let outputs$1 = [];
/**
 * `e-stackedHeaders` directive represent a stacked header of the Kanban board.
 * It must be contained in a Kanban component(`ejs-kanban`).
 * ```html
 * <ejs-kanban>
 *   <e-stackedHeaders>
 *    <e-stackedHeader keyField='Open' text='To Do'></e-stackedHeader>
 *    <e-stackedHeader keyField='Close' text='Completed'></e-stackedHeader>
 *   </e-stackedHeaders>
 * </ejs-kanban>
 * ```
 */
class StackedHeaderDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
StackedHeaderDirective.ɵfac = function StackedHeaderDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StackedHeaderDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
StackedHeaderDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: StackedHeaderDirective,
  selectors: [["e-stackedHeader"]],
  inputs: {
    keyFields: "keyFields",
    text: "text"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StackedHeaderDirective, [{
    type: Directive,
    args: [{
      selector: 'e-stackedHeaders>e-stackedHeader',
      inputs: input,
      outputs: outputs$1,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * StackedHeader Array Directive
 * @private
 */
class StackedHeadersDirective extends ArrayBase {
  constructor() {
    super('stackedheaders');
  }
}
StackedHeadersDirective.ɵfac = function StackedHeadersDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StackedHeadersDirective)();
};
StackedHeadersDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: StackedHeadersDirective,
  selectors: [["e-stackedHeaders"]],
  contentQueries: function StackedHeadersDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, StackedHeaderDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StackedHeadersDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-kanban>e-stackedHeaders',
      queries: {
        children: new ContentChildren(StackedHeaderDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['allowDragAndDrop', 'allowKeyboard', 'cardHeight', 'cardSettings', 'columns', 'constraintType', 'cssClass', 'dataSource', 'dialogSettings', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'enableTooltip', 'enableVirtualization', 'externalDropId', 'height', 'keyField', 'locale', 'query', 'showEmptyColumn', 'sortSettings', 'stackedHeaders', 'swimlaneSettings', 'tooltipTemplate', 'width'];
const outputs = ['actionBegin', 'actionComplete', 'actionFailure', 'cardClick', 'cardDoubleClick', 'cardRendered', 'created', 'dataBinding', 'dataBound', 'dataSourceChanged', 'dataStateChange', 'dialogClose', 'dialogOpen', 'drag', 'dragStart', 'dragStop', 'queryCellInfo'];
const twoWays = [''];
/**
 * `ej-kanban` represents the Angular Kanban Component.
 * ```html
 * <ejs-kanban></ejs-kanban>
 * ```
 */
let KanbanComponent = class KanbanComponent extends Kanban {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['columns', 'stackedHeaders'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childColumns;
    if (this.childStackedHeaders) {
      this.tagObjects[1].instance = this.childStackedHeaders;
    }
    this.context.ngAfterContentChecked(this);
  }
};
KanbanComponent.ɵfac = function KanbanComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || KanbanComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
KanbanComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: KanbanComponent,
  selectors: [["ejs-kanban"]],
  contentQueries: function KanbanComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, ColumnsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, StackedHeadersDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltipTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.columns_template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.swimlaneSettings_template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.cardSettings_template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dialogSettings_template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childColumns = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childStackedHeaders = _t.first);
    }
  },
  inputs: {
    allowDragAndDrop: "allowDragAndDrop",
    allowKeyboard: "allowKeyboard",
    cardHeight: "cardHeight",
    cardSettings: "cardSettings",
    columns: "columns",
    constraintType: "constraintType",
    cssClass: "cssClass",
    dataSource: "dataSource",
    dialogSettings: "dialogSettings",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableTooltip: "enableTooltip",
    enableVirtualization: "enableVirtualization",
    externalDropId: "externalDropId",
    height: "height",
    keyField: "keyField",
    locale: "locale",
    query: "query",
    showEmptyColumn: "showEmptyColumn",
    sortSettings: "sortSettings",
    stackedHeaders: "stackedHeaders",
    swimlaneSettings: "swimlaneSettings",
    tooltipTemplate: "tooltipTemplate",
    width: "width"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    cardClick: "cardClick",
    cardDoubleClick: "cardDoubleClick",
    cardRendered: "cardRendered",
    created: "created",
    dataBinding: "dataBinding",
    dataBound: "dataBound",
    dataSourceChanged: "dataSourceChanged",
    dataStateChange: "dataStateChange",
    dialogClose: "dialogClose",
    dialogOpen: "dialogOpen",
    drag: "drag",
    dragStart: "dragStart",
    dragStop: "dragStop",
    queryCellInfo: "queryCellInfo"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function KanbanComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], KanbanComponent.prototype, "tooltipTemplate", void 0);
__decorate([Template()], KanbanComponent.prototype, "columns_template", void 0);
__decorate([Template()], KanbanComponent.prototype, "swimlaneSettings_template", void 0);
__decorate([Template()], KanbanComponent.prototype, "cardSettings_template", void 0);
__decorate([Template()], KanbanComponent.prototype, "dialogSettings_template", void 0);
KanbanComponent = __decorate([ComponentMixins([ComponentBase])], KanbanComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KanbanComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-kanban',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childColumns: new ContentChild(ColumnsDirective),
        childStackedHeaders: new ContentChild(StackedHeadersDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    tooltipTemplate: [{
      type: ContentChild,
      args: ['tooltipTemplate']
    }],
    columns_template: [{
      type: ContentChild,
      args: ['columnsTemplate']
    }],
    swimlaneSettings_template: [{
      type: ContentChild,
      args: ['swimlaneSettingsTemplate']
    }],
    cardSettings_template: [{
      type: ContentChild,
      args: ['cardSettingsTemplate']
    }],
    dialogSettings_template: [{
      type: ContentChild,
      args: ['dialogSettingsTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Kanban component.
 */
class KanbanModule {}
KanbanModule.ɵfac = function KanbanModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || KanbanModule)();
};
KanbanModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: KanbanModule
});
KanbanModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KanbanModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [KanbanComponent, ColumnDirective, ColumnsDirective, StackedHeaderDirective, StackedHeadersDirective],
      exports: [KanbanComponent, ColumnDirective, ColumnsDirective, StackedHeaderDirective, StackedHeadersDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Kanban component with providers.
 */
class KanbanAllModule {}
KanbanAllModule.ɵfac = function KanbanAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || KanbanAllModule)();
};
KanbanAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: KanbanAllModule
});
KanbanAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, KanbanModule], KanbanModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KanbanAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, KanbanModule],
      exports: [KanbanModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ColumnDirective, ColumnsDirective, KanbanAllModule, KanbanComponent, KanbanModule, StackedHeaderDirective, StackedHeadersDirective };
