<div class="filter-view-container" [@childSlide]="slideState">
  <div class="filter-view-content">
    <div class="filter-view-header">
      <h4 class="modal-title">Search</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="hideFilter()"></button>
    </div>
    <div class="filter-view-body">
      <div class="form-group "> <!-- Add text-right class here -->
        <label for="statusId">Keyword</label>
        <input type="text" [(ngModel)]="leadsFilter.query" class="form-control" placeholder="Name, Email or Contact">
      </div>
      <div class="form-row m-0">
        <div class="form-group col-md-6">
          <label for="statusId">From Date</label>
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="fromDate" ngbDatepicker
              #d1="ngbDatepicker" style="width: 105px;" />
            <button class="btn btn-outline-secondary bi bi-calendar3 cal-btn" (click)="d1.toggle()" type="button"><i
                class="fa-solid fa-calendar"></i></button>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="statusId">To Date</label>

          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="toDate" ngbDatepicker
              #d2="ngbDatepicker" style="width: 105px;" />
            <button class="btn btn-outline-secondary bi bi-calendar3 cal-btn" (click)="d2.toggle()" type="button"><i
                class="fa-solid fa-calendar"></i></button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="statusId">User</label>
        <ng-multiselect-dropdown [settings]="dropdownSettingsUsers" [data]="allUsers"
          (onSelect)="selectUsersFilter($event)" (onDeSelect)="onItemDeSelectUsersFilter($event)"
          (onSelectAll)="onSelectAllUsersFilter($event)" (onDeSelectAll)="onDeSelectAllIUsersFilter($event)"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedUersFilter">
        </ng-multiselect-dropdown>
      </div>
      <div class="form-group ">
        <label for="leadType">Lead Group</label>
        <p-multiSelect name="usersselect" [options]="leadGroupsList" [(ngModel)]="selectedLeadGroups"
          placeholder="Select Lead Group" optionLabel="groupName" display="chip"></p-multiSelect>
      </div>

      <div class="form-group">
        <label for="leadType">Lead Type</label>
        <select name="leadType" [(ngModel)]="leadsFilter.leadTypeId" class="form-control custom-select">
          <option *ngFor="let item of leadTypesList" [value]="item.id">{{ item.type }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="leadType">Lead Source</label>
        <select name="leadType" [(ngModel)]="leadsFilter.leadSourceId" class="form-control custom-select">
          <option *ngFor="let item of leadSourcesList" [value]="item.id">{{ item.source }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="entityType">Entity Type</label>
        <select name="entityType" [(ngModel)]="leadsFilter.leadEntityTypeId" class="form-control custom-select"
          placeholder="Select Entity Type">
          <option *ngFor="let item of leadEntityTypes" [value]="item.id">{{ item.type }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="industry">Industry</label>
        <select name="industry" [(ngModel)]="leadsFilter.industryTypeId" (ngModelChange)="onIndustryChange($event)"
          class="form-control custom-select" placeholder="Select Industry">
          <option *ngFor="let item of industryTypesList" [value]="item.id">{{ item.type }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="campaign">Specializations</label>
        <select name="industry" [(ngModel)]="leadsFilter.industrySpecializationId" class="form-control custom-select"
          placeholder="Select Industry">
          <option *ngFor="let item of industrySpecializations" [value]="item.id">{{ item.specialization }}
          </option>
        </select>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary action-button-outline me-2" (click)="resetFilters()"><i
            class="fas fa-times me-1"></i>Clear All
        </button>
        <button type="button" class="btn btn-outline-secondary action-button-outline me-2"
          (click)="hideFilter()">Close</button>
        <button class="btn btn-primary action-button" (click)="applyFilter()">
          <i class="fas fa-search me-1"></i>Search
        </button>
      </div>
    </div>

  </div>
</div>
