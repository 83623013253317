import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/Users';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService
{
  private authToken: string | null = null;
  private currentUserSubject: BehaviorSubject<User>;
  isLogged: any;

  constructor(public router: Router,)
  {
    const userJson = localStorage.getItem('UserInfo');
    this.currentUserSubject = new BehaviorSubject<User>(userJson !== null ? JSON.parse(userJson) : new User());
    this.authToken = localStorage.getItem('authToken');
    this.isLogged = true;
  }
  public get currentUserValue(): User
  {
    return this.currentUserSubject.value;
  }
  setCurrentSubject(user: any) {
    localStorage.setItem('UserInfo', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }
  setAuthToken(token: string): Promise<boolean>
  {
    return new Promise((resolve, reject) =>
    {
      this.authToken = token;
      localStorage.setItem('authToken', token);
      resolve(true);
    });
  }

  getAuthToken(): string | null
  {
    return this.authToken;
  }


}
