<!-- Plugin Activation Section -->

<!-- Loading Animation Section -->
<div *ngIf="isLoading">
  <div class="modal-body p-3 text-center"
    style="height: 265px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="mt-3">Getting Plugin Configuration, please wait...</div>
  </div>
</div>

<!-- Processing Animation Section -->
<div *ngIf="isProcessing">
  <div class="modal-body p-3 text-center"
    style="height: 265px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Processing...</span>
    </div>
    <div class="mt-3">File Uploading, please wait...</div>
  </div>
</div>

<!-- Plugin Configuration Section -->
<div *ngIf="!isProcessing && !isLoading">
  <div class="modal-body p-3">

    <ng-container *ngIf="step === 1">
      <div class="alert alert-secondary p-1 mb-3 fs-12px" role="alert">
        <i class="fa fa-regular fa-circle-info text-dark mr-1 ml-1"></i>
        <span>Select the type of data you wish to upload in bulk
        </span>
      </div>

      <div class="d-flex align-items-center mt-2 ml-3">
        <div class="search-container">

          <div class="d-flex align-items-center">

            <button class="btn btn-sm btn-type"
              [class]="selectedType === 'leads' ? 'btn-secondary' : 'btn-outline-secondary'"
              (click)="onStepChange('leads')">
              <i class="fa-regular fa-rectangle-history-circle-user mb-2 fs-32px"></i>
              <p>Import Leads</p>
              <span>Upload potential customer data</span>
              <i *ngIf="selectedType === 'leads'" class="fa-sharp fa-light fa-circle-check"></i>
            </button>

            <button class="btn btn-sm btn-type"
              [class]="selectedType === 'deals' ? 'btn-secondary' : 'btn-outline-secondary'"
              (click)="onStepChange('deals')">
              <i class="fa-regular fa-handshake mb-2 fs-32px"></i>
              <p>Import Deals</p>
              <span>Upload new business opportunities</span>
              <i *ngIf="selectedType === 'deals'" class="fa-sharp fa-light fa-circle-check"></i>
            </button>

            <button class="btn btn-sm btn-type"
              [class]="selectedType === 'users' ? 'btn-secondary' : 'btn-outline-secondary'"
              (click)="onStepChange('users')">
              <i class="fa-regular fa-users mb-2 fs-32px"></i>
              <p>Import Users</p>
              <span>Upload user accounts and details</span>
              <i *ngIf="selectedType === 'users'" class="fa-sharp fa-light fa-circle-check"></i>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
      <div class="file-input-section">
        <h2 class="mapping-title">Upload Your CSV File</h2>
        <p class="file-input-description">Please select a CSV file containing your data for import. Ensure it is
          properly formatted with the necessary headers.</p>

        <div class="file-input-container">
          <input type="file" id="csvFileInput" (change)="handleFileInput($event)" accept=".csv" />
          <label for="csvFileInput" class="file-input-label">
            <i class="fas fa-file-csv"></i> Choose CSV File
          </label>
          <span *ngIf="!selectedFile" class="file-name">No file selected</span>
          <p *ngIf="selectedFile" class="file-name">{{ selectedFile.name }}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 3">
      <div class="alert alert-secondary p-1 mb-3 fs-12px" role="alert" style="
    background: #fffff3;
    padding: 5px 10px !important;
    font-size: 0.78rem !important;">
        <span>Use this interface to map your CSV file fields to the corresponding CRM fields for efficient bulk data
          import. Ensure that each field is accurately matched to facilitate seamless integration of your data into the
          system. After mapping, review the details and click "Save Settings" to complete the import process.
        </span>
      </div>
      <hr>
      <div class="row">
      <div class="col-md-3">
        <label for="leadType"><span class="text-danger">* </span>Lead Type</label>
        <select name="leadType" [(ngModel)]="leadTypeId" class="form-control custom-select">
          <option *ngFor="let item of leadTypesList" [value]="item.id">{{ item.type }}</option>
        </select>
      </div>
        <div class="col-md-3">
        <label for="leadType"><span class="text-danger">* </span>Lead Source</label>
        <select name="leadType" [(ngModel)]="leadSourceId" class="form-control custom-select">
          <option *ngFor="let item of leadSourcesList" [value]="item.id">{{ item.source }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="leadType">Lead Group</label>
        <select name="leadType" [(ngModel)]="leadGroupId" class="form-control custom-select">
          <option *ngFor="let item of leadGroupsList" [value]="item.id">{{ item.groupName }}</option>
        </select>
      </div>
      </div>
      <div class="mt-2">
        <div *ngIf="columns.length > 0" class="mapping-container">
          <h2 class="mapping-title">CSV Field Mapping</h2>
          <h6 *ngIf="campaignName" style="text-transform: capitalize;"> Selected Campaign: {{campaignName}}</h6>
          <div class="table-wrapper">
            <table class="mapping-table">
              <thead>
                <tr>
                  <th>CRM Field</th>
                  <th>CSV Header Mapping</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let h of csvHeaders; let i = index">
                  <td>
                    <label class="header-label">{{ h.display }}</label>
                    <p class="field-desc">{{ h.desc }}</p>
                  </td>
                  <td>
                    <select [(ngModel)]="selectedMappings[h.dbField]" name="mapping{{i}}" class="mapping-select">
                      <option value="" disabled>Select a column</option>
                      <option *ngFor="let column of columns" [value]="column">{{ column }}</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer p-3" *ngIf="!isProcessing && !isLoading">
  <div class="d-flex align-items-center justify-content-between w-100">
    <div>
      <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="previous()"
        *ngIf="step > 1">Previous</button>
    </div>
    <div>
      <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
        (click)="modalClose()">Cancel</button>

      <button type="button" class="btn btn-sm action-button mr-2" style="width: 80px;" (click)="next()"
        *ngIf="step < 3">Next</button>

      <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="saveSetting()"
        *ngIf="step === 3">Save
        Settings</button>
    </div>
  </div>
</div>
