<div class="container">
  <div class="page-head-container" style="margin: -5px -5px 0.5rem -5px !important;">
    <h4 class="page-title"><i class="fa-regular fa-bullseye"></i> {{ "Manage Campaigns" | translate }}
    </h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <button class="btn btn-primary action-button text-nowrap" (click)="addNewCampaign(null)"><i
          class="fa-light fa-plus"></i> {{ "New" | translate }}</button>
      <button class="btn btn-sm btn-outline-dark action-button-outline ml-1 " (click)="toggleFilter()"><i
          class="fa-light fa-filter-list"></i>
        {{ "Apply Filters" | translate }}</button>
    </div>
  </div>
  <div [@slide]="slideState" class="filter-container">
    <app-campaign-filters (onHideRequest)="onHideFilterRequest()" (onSearchRequest)="onSearchRequest($event)" />
  </div>

  <div>
    <div class="card-container"
      style="display: flex; flex-wrap: wrap; gap: 16px; overflow-y: auto; height: calc(100vh - 225px);"
      *ngIf="campaigns != null && campaigns.length > 0; else !isLoading ? noData : loader">
      <div class="card" *ngFor="let item of campaigns">
        <div class="card-header">
          <h5 class="card-title mt-2">{{ item.name }}</h5>
          <span>{{ item.campaignTypeName }}</span>
        </div>
        <div class="card-body">
          <div class="row mb-1">
            <div class="col-4">
              <strong>Status:</strong>
            </div>
            <div class="col-8">
              <div class="status bg border badge rounded-pill"
                [ngStyle]="{ 'background-color': item.bgColor, 'border-color': item.color + '!important' }">
                <span [style.color]="item.color">{{ item.statusName }}</span>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <strong>Created At:</strong>
            </div>
            <div class="col-8">
              <span>{{ item.dateCreated | date }}</span>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <strong>Start At:</strong>
            </div>
            <div class="col-3">
              <span *ngIf="item.startDate">{{ item.startDate | date }}</span>
            </div>
            <div class="col-5">
              <strong class="me-2">End At:</strong>
              <span>{{ item.endDate | date }}</span>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <strong>Members:</strong>
            </div>
            <div class="col-8">
              <span>7</span>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <strong>Leads:</strong>
            </div>
            <div class="col-8">
              <span>1854000</span>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <strong>Response Rate:</strong>
            </div>
            <div class="col-8">
              <span>67%</span>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <strong>Opportunities:</strong>
            </div>
            <div class="col-8">
              <span>1456</span>
            </div>
          </div>

          <!-- <div class="form-group mt-3">
            <label for="name" class="font-weight-500">Description:</label>
            <p class="card-text description"
              style="overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; line-clamp: 2;">
              {{ item.description }}
            </p>
          </div> -->

        </div>
        <div class="card-footer text-right">
          <div class="d-flex align-items-center justify-content-end">
            <button class="btn primary action-button" (click)="viewCampaignOverview(item)"
              title="View Campaign Overview">Campaign Overview
            </button>
            <button class="dropdown-toggle btn btn-sm btn-outline-dark action-button-outline ml-2"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ "Options" | translate }}
            </button>
            <div class="dropdown-menu">
              <button class="dropdown-item" (click)="addNewCampaign(item)">
                <i class="fa-regular fa-pencil mr-1"></i> {{ "Edit Campaign Information" | translate }}
              </button>
              <button class="dropdown-item" (click)="deleteCampaignById(item.id)">
                <i class="fa-regular fa-trash mr-1"></i> {{ "Delete Campaign" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Pagination DIV -->
    <div class="pagination-container">
      <div class="d-flex align-items-center">
        <span class="mr-3">Page {{ currentPage }} of {{ calculateTotalPages(totalCount, pageSize) }}</span>
        <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()"
          style="width: 68px; text-align: center; font-size: 12px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      <div style="max-width: 500px;">
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
          <i class="fa-sharp fa-solid fa-angles-left"></i>
        </button>
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
          [disabled]="currentPage === 1">
          <i class="fa-sharp fa-solid fa-angle-left"></i>
        </button>
        <ng-container *ngFor="let page of displayedPages">
          <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
            [class.active]="page === currentPage">
            {{ page }}
          </button>
        </ng-container>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)">
          <i class="fa-sharp fa-solid fa-angle-right"></i>
        </button>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)">
          <i class="fa-sharp fa-solid fa-angles-right"></i>
        </button>
      </div>
    </div>
  </div>


  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="No campaign has been created yet." height="calc(100vh - 315px)"></app-nodatafound>
  </ng-template>
</div>
