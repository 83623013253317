import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Result } from 'src/app/models/Result';
import { UserType } from 'src/app/models/UserType';
import { User, Project, ChangePassword } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  password: string = '';
  confirmPassword: string = '';
  currentPassword: string = '';
  user: User = new User();
  changePassword: ChangePassword = new ChangePassword();
    // Properties to track password visibility states
    showCurrentPassword: boolean = false;
    showNewPassword: boolean = false;
    showConfirmPassword: boolean = false;


  constructor(
    private apiService: ApiServiceService,
    private authService: AuthServiceService,
    private activatedRoute: ActivatedRoute,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {
    this.user = this.dialogConfig.data.user;
  }
  onSubmit(){
    if (this.password !== this.confirmPassword) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Password does not match',
        life: 3000,
      });

      return; // Passwords do not match, form will show an error
    }
   this.changePassword = new ChangePassword();
    this.changePassword.currentPassword = this.currentPassword;
    this.changePassword.newPassword = this.password;
    this.changePassword.userId = this.user.id;
    this.apiService.changePassword(this.changePassword).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: resp.message,
            life: 3000,
          });
          this.dialogRef.close();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  

  }
  public modalClose() {
    this.dialogRef.close();
  }
  // Toggle visibility for each password field
  toggleCurrentPasswordVisibility() {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
