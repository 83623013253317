<div class="filter-view-container" [@childSlide]="slideState">
  <div class="filter-view-content">
    <div class="filter-view-header">
      <h4 class="modal-title">Search</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="hideFilter()"></button>
    </div>
    <div class="filter-view-body">
      <div class="form-group "> <!-- Add text-right class here -->
        <label for="statusId">Keyword</label>
        <input type="text" [(ngModel)]="dealsFilter.query" class="form-control" placeholder="Name, Email or Contact">
      </div>


      <div class="form-group">
        <label for="statusId">User</label>
        <ng-multiselect-dropdown [settings]="dropdownSettingsUsers"
          [data]="allUsers" (onSelect)="selectUsersFilter($event)" (onDeSelect)="onItemDeSelectUsersFilter($event)"
          (onSelectAll)="onSelectAllUsersFilter($event)" (onDeSelectAll)="onDeSelectAllIUsersFilter($event)"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedUersFilter">
        </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
        <label for="statusId">From Date</label>
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="fromDate" ngbDatepicker
            #d1="ngbDatepicker" style="width: 105px;" />
          <button class="btn btn-outline-secondary bi bi-calendar3 cal-btn" (click)="d1.toggle()" type="button"><i
              class="fa-solid fa-calendar"></i></button>
        </div>
      </div>

      <div class="form-group">
        <label for="statusId">To Date</label>

        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="toDate" ngbDatepicker
            #d2="ngbDatepicker" style="width: 105px;" />
          <button class="btn btn-outline-secondary bi bi-calendar3 cal-btn" (click)="d2.toggle()" type="button"><i
              class="fa-solid fa-calendar"></i></button>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary action-button-outline me-2"
          (click)="resetFilters()"><i class="fas fa-times me-1"></i>Clear All
        </button>
        <button type="button" class="btn btn-outline-secondary action-button-outline me-2"
          (click)="hideFilter()">Close</button>
        <button class="btn btn-primary action-button" (click)="applyFilter()">
          <i class="fas fa-search me-1"></i>Search
        </button>
      </div>
    </div>
  </div>
</div>
