import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { HrmsEmployee } from 'src/app/Models/HrmsEmployee';
import { HrmsPluginSettings } from 'src/app/models/HrmsPluginSettings';
import { Result } from 'src/app/models/Result';
import { Project, User } from 'src/app/models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-hrms-plugin',
  templateUrl: './hrms-plugin.component.html',
  styleUrls: ['./hrms-plugin.component.css'],
})
export class HrmsPluginComponent {
  settings: HrmsPluginSettings = new HrmsPluginSettings();

  clientId: string = '';
  authKey: string = '';
  pairingKey: string = '';
  pluginConfigId: number = 0;
  isPluginActivated: boolean = false;
  isProcessing: boolean = false;
  isLoading: boolean = true;
  selectAll: boolean = false;

  isLoadingHrmsEmp: boolean = true;
  isSyncing: boolean = false;
  totalImportedEmployees: number = 0;
  hrmsEmployees: HrmsEmployee[] = [];
  filteredEmployees: HrmsEmployee[] = [];
  hrmsLocations: any = [];
  selectedLocation: any = null;
  searchQuery: string = '';

  constructor(
    private dialogRef: DynamicDialogRef,
    private apiService: ApiService,
    private messageService: MessageService,
    private authService: AuthService,
    private confirmationService: ConfirmationService
  ) {
    this.setSettingAttributes(null);
  }

  ngOnInit() {
    this.loadPluginConfig();
  }

  async loadPluginConfig() {
    this.apiService.getHrmsPluginConfig().subscribe({
      next: (data: any) => {
        if (data) {
          if (data.isActive) {
            this.pluginConfigId = data.id;
            this.isPluginActivated = true;
            this.clientId = data.clientId;
            this.pairingKey = data.pairingKey;
            if (data.settings) {
              this.setSettingAttributes(JSON.parse(data.settings));
            }
            this.getImportedHrmsEmployeesCount();
          }
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isProcessing = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An unknown error occurred',
          life: 3000,
        });
      },
    });
  }

  setSettingAttributes(obj: HrmsPluginSettings) {
    if (!obj) {
      this.settings.allowMarkAttendance = false;
      this.settings.allowUserChoice = false;
      this.settings.autoMarkAttendance = false;
    } else {
      this.settings.allowMarkAttendance = obj.allowMarkAttendance;
      this.settings.allowUserChoice = obj.allowUserChoice;
      this.settings.autoMarkAttendance = obj.autoMarkAttendance;
    }

    GlobalVariables.hrmsPluginConfig = this.settings;
  }

  async deletePluginConfig() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'fas fa-warning',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message:
        'Are you sure you want to deactivate the plugin? Users will no longer be able to mark attendance?',
      accept: () => {
        this.isProcessing = true;
        this.apiService.deletePluginConfig(this.pluginConfigId).subscribe({
          next: (resp: any) => {
            if (resp.status === 'success') {
              this.isPluginActivated = false;
              this.pluginConfigId = null;
              this.clientId = '';
              this.pairingKey = '';
              this.authKey = '';

              this.messageService.add({
                severity: 'warn',
                summary: 'Plugin deactivated',
                detail: resp.message,
                life: 3000,
              });
            }

            this.isProcessing = false;
          },
          error: (error) => {
            this.isProcessing = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An unknown error occurred',
              life: 3000,
            });
          },
        });
      },
      reject: () => {},
    });
  }

  async activatePlugin() {
    this.isProcessing = true;
    const obj = {
      ClientID: this.clientId,
      AuthKey: this.authKey,
      ModuleId: 4,
      ExternalRefId: this.authService.currentUserValue.organizationId,
    };

    this.apiService.activateHrmsPlugin(obj).subscribe({
      next: (resp: any) => {
        this.isProcessing = false;
        if (resp.status === 'success') {
          this.loadPluginConfig().then((x) => {
            this.isPluginActivated = true;
            this.saveSetting(true);
            this.messageService.add({
              severity: 'success',
              summary: 'Plugin Activation',
              detail: resp.message,
              life: 3000,
            });
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Plugin Activation',
            detail:
              'Plugin activation failed. Please verify Client Id and Auth Key',
            life: 3000,
          });
        }
      },
      error: (error) => {
        this.isProcessing = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An unknown error occurred',
          life: 3000,
        });
      },
    });
  }

  async loadHrmsBranches(hrmsUsers: any, event: any) {
    this.isLoadingHrmsEmp = true;
    hrmsUsers.toggle(event);
    this.apiService.getHrmsLocations().subscribe({
      next: (resp: any) => {
        if (resp.status === 'success') {
          this.hrmsLocations = resp.data;
          this.selectedLocation = this.hrmsLocations[0];
          this.loadHrmsEmployees(this.selectedLocation.ID);
        }
      },
      error: (error) => {
        this.isLoadingHrmsEmp = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An unknown error occurred',
          life: 3000,
        });
      },
    });
  }

  loadHrmsEmployees(locationId: number) {
    this.isLoadingHrmsEmp = true;
    this.apiService.getHrmsEmployees(locationId).subscribe({
      next: (resp: any) => {
        if (resp.status === 'success') {
          this.hrmsEmployees = resp.data;
          this.filteredEmployees = this.hrmsEmployees;
          //
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Oops!',
            detail: resp.message,
            life: 3000,
          });
        }

        this.isLoadingHrmsEmp = false;
      },
      error: (error) => {
        this.isProcessing = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An unknown error occurred',
          life: 3000,
        });
      },
    });
  }

  async getImportedHrmsEmployeesCount() {
    this.apiService.getImportedHrmsEmployeesCount().subscribe({
      next: (resp: any) => {
        if (resp.status === 'success') {
          this.totalImportedEmployees = resp.data;
        }
      },
      error: (error) => {},
    });
  }

  importUsers() {
    this.pushToServer()
      .then((obj) => {
        if (obj) {
          const selectedEmps = this.mapSelectedEmployeesToUsers(
            this.filteredEmployees.filter((x) => x.selected)
          );

          if (
            obj.numOfSuccessfulImportedEmployees >= 1 &&
            obj.numOfSuccessfulImportedEmployees === selectedEmps.length
          ) {
            this.messageService.add({
              severity: 'success',
              summary: 'Employees Imported',
              detail: 'HRMS Employees imported successfully',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'info',
              summary: 'Partial Import',
              detail: 'Some HRMS employees were not imported due to errors.',
              life: 5000,
            });
          }

          this.getImportedHrmsEmployeesCount();
        }
      })
      .catch((error) => {
        this.messageService.add(error);
      });
  }

  pushToServer(): Promise<any> {
    return new Promise((resolve, reject) => {
      const emps = this.mapSelectedEmployeesToUsers(
        this.filteredEmployees.filter((x) => x.selected)
      );

      if (emps.length === 0) {
        reject({
          severity: 'warn',
          summary: 'No Employee Selected',
          detail:
            'Please select at least one employee to proceed with the import.',
          life: 3000,
        });
        return;
      }

      let numOfSuccessfulImportedEmployees = 0;
      const failedImports: HrmsEmployee[] = [];

      const promises = emps.map((emp: User) => {
        return this.apiService
          .addOrUpdateUser(emp)
          .toPromise()
          .then((resp: Result) => {
            let employee = this.filteredEmployees.find(
              (x) => x.id === emp.hrmsEmpId
            );

            if (resp.status === 'success') {
              numOfSuccessfulImportedEmployees++;
              if (employee) {
                employee.successMessage = 'Employee imported successfully';
                employee.errorMessage = null;
                employee.isImported = true;
              }
            } else {
              if (employee) {
                employee.errorMessage = resp.message;
                employee.successMessage = null;
                failedImports.push(employee);
              }
            }
          })
          .catch((error) => {
            console.error('error:', error);
            reject({
              severity: 'error',
              summary: 'Oops!',
              detail: 'An unknown error occurred',
              life: 5000,
            });
          });
      });

      Promise.all(promises)
        .then(() => {
          resolve({
            numOfSuccessfulImportedEmployees: numOfSuccessfulImportedEmployees,
            failedImports: failedImports,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  mapSelectedEmployeesToUsers(
    employees: HrmsEmployee[],
    sync: boolean = false
  ): User[] {
    return employees.map((item: HrmsEmployee) => {
      const user = new User();
      user.id = !sync ? 0 : !item.taskTrackUserID ? 0 : item.taskTrackUserID;
      user.hrmsEmpId = item.id;
      user.firstName = item.firstName;
      user.lastName = item.lastName;
      user.name = item.name;
      user.userName = item.userName;
      user.password = item.password;
      user.profilePictureUrl = item.imageUrl;
      user.emailAddress = item.email;
      user.projectIds = this.authService.currentUserValue.projectIds;
      user.projects = [GlobalVariables.selectedProject];
      user.defaultProjectId =
        this.authService.currentUserValue.defaultProjectId;
      user.isActive = true;
      user.userTypeId = 3; //Standard User
      user.roleId = 2;
      user.organizationId = this.authService.currentUserValue.organizationId;

      return user;
    });
  }

  syncUsers() {
    this.confirmationService.confirm({
      header: 'Confirm Sync',
      icon: 'fas fa-warning',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message:
        '<b>Please note:</b> During the sync process, the username and password of each user will be updated from the HRMS system. Any changes made directly in Client Track will be overwritten.<br />Are you sure you want to continue?',
      accept: () => {
        this.isSyncing = true;
        this.apiService.syncHrmsEmployees().subscribe({
          next: (resp: any) => {
            if (resp.status === 'success') {
              this.messageService.add({
                severity: 'info',
                summary: 'HRMS Employee Sync',
                detail:
                  'HRMS employees have been successfully synced with Client Track users.',
                life: 3000,
              });
            }

            this.isSyncing = false;
          },
          error: (error) => {
            this.isSyncing = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An unknown error occurred',
              life: 3000,
            });
          },
        });
      },
      reject: () => {},
    });
  }

  toggleAttendanceOptions() {
    if (!this.settings.allowMarkAttendance) {
      this.settings.autoMarkAttendance = false;
      this.settings.allowUserChoice = false;
    }
    // console.log(
    //   'Allow Mark Attendance from Client Track:',
    //   this.settings.allowMarkAttendance
    // );
  }

  toggleAutoMarkAttendance() {
    if (this.settings.autoMarkAttendance) {
      this.settings.allowUserChoice = false;
    }
    // console.log(
    //   'Automatically Mark Attendance:',
    //   this.settings.autoMarkAttendance
    // );
  }

  toggleAllCheckboxes() {
    this.hrmsEmployees.forEach((user) => (user.selected = this.selectAll));
  }

  updateSelectAllCheckbox() {
    this.selectAll = this.hrmsEmployees.every((user) => user.selected);
  }

  filterByLocation() {
    if (this.selectedLocation) {
      this.loadHrmsEmployees(this.selectedLocation.ID);
    } else {
      this.filteredEmployees = this.hrmsEmployees;
    }
    this.updateSelectAllCheckbox();
  }

  filterByKeyword() {
    this.filteredEmployees = this.hrmsEmployees.filter((user) => {
      const matchesSearch =
        !this.searchQuery ||
        user.name?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.firstName
          ?.toLowerCase()
          .includes(this.searchQuery.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.id?.toString().includes(this.searchQuery.toLowerCase());
      return matchesSearch;
    });

    this.updateSelectAllCheckbox();
  }

  saveSetting(isActivating: boolean = false) {
    if (isActivating) {
      this.setSettingAttributes(null);
    }

    const obj = {
      Settings: JSON.stringify(this.settings),
    };

    this.apiService.saveHrmsPluginSettings(obj).subscribe({
      next: (resp: any) => {
        if (!isActivating) {
          if (resp.status === 'success') {
            this.setSettingAttributes(this.settings);
            this.messageService.add({
              severity: 'success',
              summary: 'Plugin Settings',
              detail: resp.message,
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Plugin Settings',
              detail: resp.message,
              life: 3000,
            });
          }
        }
      },
      error: (error) => {
        if (!isActivating) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An unknown error occurred',
            life: 3000,
          });
        }
      },
    });
  }

  modalClose() {
    this.dialogRef.close();
  }
}
