import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/Users';
import { Permission } from '../models/Permission';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLogged: any;
  private authToken: string | null = null;
  private currentUserSubject: BehaviorSubject<User>;


  constructor(public router: Router) {
    const userJson = localStorage.getItem('UserInfo');
    this.currentUserSubject = new BehaviorSubject<User>(
      userJson !== null ? JSON.parse(userJson) : new User()
    );

    this.authToken = localStorage.getItem('authToken');
    this.isLogged = true;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get permissions(): Permission[] {
    return this.currentUserValue.roleInfo.permissions;
  }

  setCurrentSubject(user: any) {
    localStorage.setItem('UserInfo', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  setCurrentPermissions(permission: Permission[]) {
    const user =this.currentUserSubject.value;
    user.roleInfo.permissions = permission;
    localStorage.setItem('UserInfo', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  setAuthToken(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authToken = token;
      localStorage.setItem('authToken', token);
      resolve(true);
    });
  }

  getAuthToken(): string | null {
    return this.authToken;
  }

  hasPagePermission(permission: string): boolean {
    return this.permissions?.some(p => {
      if (p.isPage) {
        return p.permissionName === permission;
      }
      return false;
    });
  }

  hasAllPagePermissions(requiredPermissions: string[]): boolean {
    return requiredPermissions.every((permission) =>
      this.permissions?.some(p => { if (p.isPage) p.permissionName === permission; })
    );
  }

  hasPermission(permission: string): boolean {
    return this.permissions?.some(p => p.permissionName === permission);
  }

  hasAllPermissions(requiredPermissions: string[]): boolean {
    return requiredPermissions.every((permission) =>
      this.permissions?.some(p => p.permissionName === permission)
    );
  }
}
