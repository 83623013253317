import { Component } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { ProductSetupComponent } from '../product-setup/product-setup.component';
import { TranslateService } from '@ngx-translate/core';
import { padZero } from 'src/app/utils/utils';
import { Result } from 'src/app/models/Result';
import { ProductFilter, Products } from 'src/app/Models/Products';
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.css',
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class ProductListComponent {
  public statusTypeId: number = 2; // Incase of lead component
  slideState = 'right';
  public filter: ProductFilter = new ProductFilter();
  public product: any = {};
  public products: Products[] = [];
  public totalCount: number = 0;
  public currentPage = 1;
  public pageSize = 50;
  public displayedPages: number[] = [];
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  public isLoading = false;
  public totalPages: number = 0;
  public pageHeading: string;

  //#region refrence storing variables for model
  public addLogModal: any;
  clientSetupHeader: string;
  clientType: string;
  setupHeader: string;
  //#endregion
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public sharedService: SharedService,
    private translate: TranslateService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      // Fetch data based on the new projectId
      GlobalVariables.selectedProjectId = projectId;
      this.loadData();
    });

    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);

  }
  public loadData(): void {
    this.filter.projectId = GlobalVariables.selectedProjectId;
    this.filter.pageNumber = this.currentPage;
    this.filter.pageSize = this.pageSize;

     this.getProducts();

    this.sharedService.triggerChildLoaded();
  }
  public onSearchRequest(filters: any) {
    this.toggleFilter();
    this.filter = filters;
    this.currentPage = this.filter.pageNumber;
     this.getProducts();
  }
  public addNewProduct(item: any) {
    this.setupHeader = 'Product Setup';
    this.product = new Products();

    if (item) {
      this.setupHeader = 'Product Setup';
      this.product = item;
    }

    const modalRef = this.dialogService.open(ProductSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.setupHeader,
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        client: this.product,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.product = new Products();
      this.getProducts();
    });
  }
  public getProducts() {
    this.isLoading = true;
    this.filter.pageNumber = this.currentPage;
    this.filter.pageSize = this.pageSize;

    this.apiService.getProducts(this.filter).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.products = [];
        if (resp.status === 'success') {
          this.products = resp.data;

          if (this.products?.length > 0) {
            this.totalCount = this.products[0]?.totalRecords;
          }
          this.generateDisplayedPages();
          this.sharedService.triggerChildLoaded();
        } else {
          // Handle unsuccessful status
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public deleteProductById(id: number) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message: 'Do you want to delete this product?',
      accept: () => {
        this.apiService.deleteProductById(id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: resp.message,
                life: 3000,
              });
              this.loadData();
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  public viewClientProfile(item: Products) {
    this.router.navigate(['/client'], {
      queryParams: { id: item.id }, //Cleint Id
    });
  }
  public toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  public onHideFilterRequest() {
    this.toggleFilter();
  }
  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    //this.getProducts();
  }
  public calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }

  public generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  public onPageSizeChange(): void {
    this.currentPage = 1;
    this.getProducts();
  }



  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }


}
