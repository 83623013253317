import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Result } from 'src/app/models/Result';
import { User } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css'],
})
export class InvitationComponent {
  password: string = '';
  confirmPassword: string = '';
  user: User = new User();
  token: string = '';
  isLoading = true;
  processCompleted = false;

  constructor(
    private apiService: ApiServiceService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.getUserInvitationStatus(this.token);
  }

  getUserInvitationStatus(token: string) {
    this.apiService.getUserInvitationStatus(token).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.user = resp.data;
          this.user.token = this.token;
        } else {
          this.user = null;
        }

        this.isLoading = false;
      },
      error: (error) => {
        // Handle error
        this.user = null;
        this.isLoading = false;
        console.error('error:', error);
      },
    });
  }

  setPassword(): void {
    if (this.password !== this.confirmPassword) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Password does not match',
        life: 3000,
      });

      return; // Passwords do not match, form will show an error
    }

    this.user.password = this.password;

    this.apiService.acceptInvitation(this.user).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: resp.message,
            life: 3000,
          });

          this.processCompleted = true;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
}
