import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Services } from 'src/app/models/Services';
import { ServicesSetupComponent } from '../services-setup/services-setup.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Result } from 'src/app/models/Result';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { padZero } from 'src/app/utils/utils';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent {
  services: Services[] = [];
  public filteredServices: Services[] = [];
  searchKeyword: string = '';

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    public sharedService: SharedService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
      this.getServices();
    });
  }

  ngOnInit(): void {
    this.getServices();
  }

  getServices(): void {
    this.apiService.getServices().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.services = resp.data;
          this.filteredServices = this.services;
        } else {

        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }

  searchServices(): void {
    // Filter users based on the searchKeyword
    this.filteredServices = this.services.filter((x) => {
      // Assuming you want to search in user's name and username
      return x.serviceName
        .toLowerCase()
        .includes(this.searchKeyword.toLowerCase());
    });
  }

  addNewService() {
    if (!this.checkPermission()) {
      return;
    }

    const modalRef = this.dialogService.open(ServicesSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'New Service',
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        service: new Services(),
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getServices();
    });
  }

  editService(service: Services) {
    if (service.isSystemService) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warnig',
        detail: 'You cannot edit system services',
        life: 3000,
      });

      return;
    }

    if (!this.checkPermission()) {
      return;
    }

    const modalRef = this.dialogService.open(ServicesSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'Edit Project',
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        service: service,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getServices();
    });
  }

  deleteService(service: Services) {
    if (service.isSystemService) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warnig',
        detail: 'You cannot delete system services',
        life: 3000,
      });

      return;
    }

    if (!this.checkPermission()) {
      return;
    }

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + service.serviceName + '?',
      header: 'Confirmation',
      icon: 'fa-regular fa-circle-info',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.apiService.deleteService(service.id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.getServices();
              this.sharedService.triggerProjectUpdates();
              this.messageService.add({
                severity: 'success',
                summary: 'Default Project',
                detail: resp.message,
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warnig',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            this.messageService.add({
              severity: 'warn',
              summary: 'Warnig',
              detail: 'Something went wrong',
              life: 3000,
            });
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
      key: 'positionDialog',
    });
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }

  checkPermission() {
    if (this.authService.currentUserValue.userTypeId > 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warnig',
        detail: 'You are not allowed to perform this action',
        life: 3000,
      });

      return false;
    }
    return true;
  }
}
