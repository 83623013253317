<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-users"></i> {{ "Clients" | translate }}</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <button class="btn btn-primary action-button text-nowrap" (click)="addNewClient(null)" [hasPermission]="{ access: 'addUpdateClient', action: 'hide' }"><i
          class="fa-light fa-plus"></i> {{ "New Client" | translate }}</button>
      <button class="btn btn-sm btn-outline-dark action-button-outline ml-1 " (click)="toggleFilter()"><i
          class="fa-light fa-filter-list"></i>
        {{ "Apply Filters" | translate }}</button>
    </div>
  </div>

  <ng-container *ngIf="sharedService.isProjectChangeInProc">
    <app-project-loading-indicator />
  </ng-container>

  <ng-container *ngIf="!sharedService.isProjectChangeInProc">
    <div [@slide]="slideState" class="search-container-sidebar">
      <app-client-filters (onHideRequest)="onHideFilterRequest()" (onSearchRequest)="onSearchRequest($event)" />
    </div>

    <div>
      <div class="table-responsive" style="overflow-y: auto; height: calc(100vh - 235px);" *ngIf="clients != null && clients.length > 0;
                        else !isLoading ? noData : loader">
        <table class="table table-hover">
          <thead>
            <tr class="sticky-header">
              <th style="width: 20px; padding: 1rem;">#</th>
              <th style="width: 230px;">{{ "Client Name" | translate }}</th>
              <th>{{ "Contact Name" | translate }}</th>
              <th>{{ "Contact Email" | translate }}</th>
              <th>{{ "Contact Phone" | translate }}</th>
              <th>{{ "Date Registered" | translate }}</th>
              <th>{{ "Status" | translate }}</th>
              <th class="text-right"><span class="me-3">{{ "Actions" | translate }}</span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of clients;let rowIndex = index">
              <td class="text-center">
                <div class="">{{ item.rowNumber }}</div>
              </td>
              <td class="clientname-td">
                <div class="d-flex align-items-center clientname-container">
                  <span class="clientname">
                    {{ item.clientName
                    + ' - ' + padZero(item.id, 3) }}</span>
                </div>
              </td>
              <td>
                {{item.contactName}}
              </td>
              <td>
                <div class="ellipsis" tooltipPosition="top" [pTooltip]="item?.contactEmail">{{item.contactEmail}}</div>
              </td>
              <td>
                {{item.contactPhone}}
              </td>
              <td>
                {{ item.dateCreated | date }}
              </td>
              <td>
                <span *ngIf="item.active" class="status bg border badge rounded-pill active">Active</span>
                <span *ngIf="!item.active" class="status bg border badge rounded-pill inactive">Inactive</span>
              </td>
              <td class="text-right pr-4">
                <div class="btn-group">
                  <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    {{ "Options" | translate }}
                  </button>
                  <div class="dropdown-menu">


                    <button class="dropdown-item table-action-btn mb-2"  [hasPermission]="{ access: 'addUpdateClient', action: 'hide' }" (click)="addNewClient(item)">
                      <i class="fa-regular fa-pencil mr-1"></i>
                      {{"Edit Client Information" | translate}}</button>
                    <button class="dropdown-item table-action-btn" [hasPermission]="{ access: 'addUpdateClient', action: 'hide' }" (click)="deleteClientById(item.id)"><i
                        class="fa-regular fa-trash mr-1"></i>
                      {{"Delete Client" | translate}}</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination DIV -->
      <div class="pagination-container">
        <div class="d-flex align-items-center">
          <span class="mr-3">{{"Page" | translate}} {{ currentPage }} {{"of" | translate}} {{
            calculateTotalPages(totalCount, pageSize) }} {{"of" | translate}}
            {{totalCount}}</span>
          <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="
          width: 68px;
          text-align: center;
          font-size: 12px;">
            <option class="dropdown-item d-flex align-items-center" value="10">10</option>
            <option class="dropdown-item d-flex align-items-center" value="20">20</option>
            <option class="dropdown-item d-flex align-items-center" value="50">50</option>
            <option class="dropdown-item d-flex align-items-center" value="100">100</option>
          </select>
        </div>



        <div style="max-width: 500px;">
          <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
            <i class="fa-sharp fa-solid fa-angles-left"></i></button>
          <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
            [disabled]="currentPage === 1"><i class="fa-sharp fa-solid fa-angle-left"></i></button>
          <ng-container *ngFor="let page of displayedPages">
            <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
              [class.active]="page === currentPage">
              {{ page }}
            </button>
          </ng-container>
          <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
            [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
              class="fa-sharp fa-solid fa-angle-right"></i></button>
          <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
            [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
              class="fa-sharp fa-solid fa-angles-right"></i></button>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<ng-template #loader>
  <app-project-loading-indicator />
</ng-template>

<ng-template #noData>
  <app-nodatafound message="{{'message.No client has been created yet.' | translate }}"
    height="calc(100vh - 285px)"></app-nodatafound>
</ng-template>
