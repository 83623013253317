import { Permission } from 'src/app/models/Permission';

export class Role {
  id: number;
  roleName: string;
  description: string;
  dateCreated: Date;
  dateUpdated: Date;
  isSystemRole: boolean;
  active: boolean;
  totalPermissions: number;
  permissionsJson: string;
  permissions: Permission[];
}

export class UserRole {
  id: number;
  userId: number;
  roleId: number;
  dateCreated: Date;
}
