import { CommonModule } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { LeadGroup } from 'src/app/Models/LeadGroups';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Result } from 'src/app/models/Result';
import { GlobalVariables } from 'src/environments/environment.development';
import { SharedService } from 'src/app/services/shared.service';
import { PriorityLevels } from 'src/app/models/Task';

@Component({
  selector: 'app-add-update-groups',
  templateUrl: './add-update-groups.component.html',
  styleUrl: './add-update-groups.component.css',
})
export class AddUpdateGroupsComponent {
  leadGroup: LeadGroup = new LeadGroup();
  priorityLevels: PriorityLevels[] = [];
  progress = 0;
  projectId: number;

  groupTypes: any = [];

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
    });
  }

  ngOnInit() {
    this.groupTypes = [
      { id: 1, type: 'Leads' },
      { id: 2, type: 'Contacts' },
    ];

    this.projectId = this.dialogConfig.data.projectId;
    this.leadGroup = this.dialogConfig.data.leadGroup;

    this.getPriorityLevels();
  }

  ngAfterViewInit() {}

  getPriorityLevels() {
    this.apiService.getPriorityLevels().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.priorityLevels = resp.data;
          if (this.leadGroup?.id > 0) {
            this.leadGroup.priorityLevelId = this.leadGroup.priorityLevelId;
          } else {
            this.leadGroup.priorityLevelId = 2;
          }
        } else {
          this.priorityLevels = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }
  public onSubmit() {
    if (this.validateAndSetData()) {
      this.apiService.addUpdateLeadGroup(this.leadGroup).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close();
            //this.getAllLeads();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  public validateAndSetData() {
    if (!this.leadGroup.groupName) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter group name',
        life: 3000,
      });
      return false;
    }
    this.leadGroup.createdBy = this.authService.currentUserValue.id;
    this.leadGroup.updatedBy = this.authService.currentUserValue.id;
    return true;
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
