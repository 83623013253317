import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Result } from 'src/app/models/Result';
import { Project } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { ProjectSetupComponent } from '../project-setup/project-setup.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthServiceService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
})
export class ProjectsComponent {
  projects: Project[] = [];
  public filteredProjects: Project[] = [];
  searchKeyword: string = '';

  constructor(
    private apiService: ApiServiceService,
    private dialogService: DialogService,
    public sharedService: SharedService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthServiceService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
      this.getUserProjects();
    });
  }

  ngOnInit(): void {
    this.getUserProjects();
  }

  getUserProjects(): void {
    this.apiService.getUserProjects().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.projects = resp.data;
          this.filteredProjects = this.projects;
        } else {
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }

  makeDefault(project: Project) {
    this.apiService.setDefaultProject(project.id).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.getUserProjects();

          this.messageService.add({
            severity: 'info',
            summary: 'Default Project',
            detail: project.name + ' is now your default project',
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        this.messageService.add({
          severity: 'warn',
          summary: 'Warnig',
          detail: 'Something went wrong',
          life: 3000,
        });
        console.error('error:', error);
      },
    });
  }

  searchProjects(): void {
    // Filter users based on the searchKeyword
    this.filteredProjects = this.projects.filter((user) => {
      // Assuming you want to search in user's name and username
      return user.name.toLowerCase().includes(this.searchKeyword.toLowerCase());
    });
  }

  addNewProject() {
    if (!this.checkPermission()) {
      return;
    }

    const modalRef = this.dialogService.open(ProjectSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'New Project',
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        project: new Project(),
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getUserProjects();
    });
  }

  editProject(project: Project) {
    if (!this.checkPermission()) {
      return;
    }

    const modalRef = this.dialogService.open(ProjectSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'Edit Project',
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        project: project,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getUserProjects();
    });
  }

  deleteProject(project: Project) {
    if (!this.checkPermission()) {
      return;
    }

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + project.name + '?',
      header: 'Confirmation',
      icon: 'fa-regular fa-circle-info',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.apiService.deleteProject(project.id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.getUserProjects();
              this.sharedService.triggerProjectUpdates();
              this.messageService.add({
                severity: 'success',
                summary: 'Default Project',
                detail: resp.message,
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warnig',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            this.messageService.add({
              severity: 'warn',
              summary: 'Warnig',
              detail: 'Something went wrong',
              life: 3000,
            });
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  checkPermission() {
    if (this.authService.currentUserValue.userTypeId > 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warnig',
        detail: 'You are not allowed to perform this action',
        life: 3000,
      });

      return false;
    }
    return true;
  }
}
