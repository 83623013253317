import { Component } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from '../../../services/auth-service.service';
import { User } from 'src/app/models/Users';
import { ActivatedRoute } from '@angular/router';
import { LeadviewComponent } from '../leadview/leadview.component';
import { LeadsFilter, Leads, LeadsData } from 'src/app/models/Leads';
import { Remarks } from 'src/app/models/Remarks';
import { DealStatus, LeadStatus, StatusTypeId } from 'src/app/models/Status';
import { Result } from 'src/app/models/Result';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Deals } from 'src/app/models/Deals';
import { AdddealsComponent } from '../../deals/adddeals/adddeals.component';
import { AddupdateleadComponent } from '../addupdatelead/addupdatelead.component';
import { SharedService } from 'src/app/services/shared.service';
import { AddEventComponent } from '../../events/addevent/addevent.component';
import { GlobalVariables } from 'src/environments/environment';
import { ConvertToLeadComponent } from '../../contacts/convert-to-lead/convert-to-lead.component';
import { AssignLeadsComponent } from './assign-leads/assign-leads.component';
import { LeadGroup } from 'src/app/Models/LeadGroups';
import { ExportOptionsDialogComponent } from '../../modals/export-options-dialog/export-options-dialog.component';

declare var swal: any;

@Component({
  selector: 'app-manage-leads',
  templateUrl: './manage-leads.component.html',
  styleUrls: ['./manage-leads.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class ManageLeadsComponent {
  public statusTypeId: number = 2; // Incase of lead component
  slideState = 'right';
  public leadsFilter: LeadsFilter = new LeadsFilter();
  public lead: Leads = new Leads();
  public logs: Remarks = new Remarks();
  public allleads: Leads[] = [];
  public selectedLeads: Leads[] = [];
  public totalCount: number = 0;
  public currentPage = 1;
  public pageSize = 50;

  public displayedPages: number[] = [];
  public leadStatusList: LeadStatus[] = [];
  public dealStatusList: DealStatus[] = [];
  public usersList: User[] = [];
  public leadGroupList: LeadGroup[] = [];
  public allLogs: Remarks[] = [];
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  public isLoading = false;
  public totalPages: number = 0;
  public selectedUers: User[] = [];
  public selectedLeadGroup: LeadGroup[] = [];
  public selectedUersFilter: User[] = [];
  public statusId: number = 0;
  public pageHeading: string;
  public deals: Deals;
  //#region refrence storing variables for model
  public addLogModal: any;
  addLeadComponentHeader: string;
  //#endregion

  constructor(
    private apiService: ApiServiceService,
    private authService: AuthServiceService,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      // Fetch data based on the new projectId
      GlobalVariables.selectedProjectId = projectId;
      this.getAllUsers();
      this.getLeadGroups();
      this.loadData();
    });

    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getAllUsers();
    this.getLeadGroups();
    this.loadData();
  }

  public loadData(): void {
    this.leadsFilter.statusId = this.statusId;
    this.leadsFilter.projectId = GlobalVariables.selectedProjectId;
    this.leadsFilter.pageNumber = this.currentPage;
    this.leadsFilter.pageSize = this.pageSize;

    this.getDealstatus();
    this.getLeadStatus();
    this.getLeads();

    this.sharedService.triggerChildLoaded();
  }

  public toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  public onHideFilterRequest() {
    this.toggleFilter();
  }

  public onStatusFilterChange(id: number) {
    this.leadsFilter.statusId = id;
    this.statusId = id;
    this.leadsFilter.projectId = GlobalVariables.selectedProjectId;
    this.getLeads();
  }

  public onSearchRequest(filters: LeadsFilter) {
    this.toggleFilter();
    this.leadsFilter = filters;
    this.leadsFilter.statusId = this.statusId;
    this.leadsFilter.projectId = GlobalVariables.selectedProjectId;
    this.currentPage = this.leadsFilter.pageNumber;
    this.getLeads();
  }

  exportLeads() {
    const modalRef = this.dialogService.open(ExportOptionsDialogComponent, {
      modal: true,
      header: 'Export Leads',
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        projectId: GlobalVariables.selectedProjectId,
        mode: 'New',
        users: this.usersList,
      },
    });
  }

  openEventAddEditView(leadId: number): void {
    const modalRef = this.dialogService.open(AddEventComponent, {
      modal: true,
      header: 'New Event',
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        projectId: GlobalVariables.selectedProjectId,
        mode: 'New',
        leadId: leadId,
        users: this.usersList,
        isFollowUpOnly: false,
      },
    });
  }
  public addNewLead(item: any) {
    this.addLeadComponentHeader = 'Add Lead';
    this.lead = new Leads();

    //for current user selected
    const obj = this.usersList.filter(
      (user) => user.id === this.authService?.currentUserValue.id
    );
    this.selectedUers = obj;

    this.lead.statusId =
      GlobalVariables.selectedProjectId === 1
        ? 3
        : this.leadStatusList.map((x) => x.id)[0]; // in case its a RWF project then pick new lead else pick random first lead

    if (item) {
      this.addLeadComponentHeader = 'Edit Lead';
      this.lead = item;
      if (this.lead.userIds && this.lead.userIds.length > 0) {
        const array = this.usersList.filter((item) =>
          this.lead.userIds.includes(item.id)
        );
        this.selectedUers = array;
      } else {
        this.selectedUers = [];
      }
    }
    if (this.lead.groupIds && this.lead.groupIds.length > 0) {
      const array = this.leadGroupList.filter((item) =>
        this.lead.groupIds.includes(item.id)
      );
      this.selectedLeadGroup = array;
    } else {
      this.selectedLeadGroup = [];
    }

    const modalRef = this.dialogService.open(AddupdateleadComponent, {
      modal: true,
      showHeader: true,
      header: this.addLeadComponentHeader,
      width: '60%',
      height: '100%',
      contentStyle: { overflow: 'hidden', paddingRight: 0 },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        users: this.usersList,
        projectId: GlobalVariables.selectedProjectId,
        lead: this.lead,
        leadStatusList: this.leadStatusList,
        selectedUers: this.selectedUers,
        selectedLeadGroup: this.selectedLeadGroup,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.lead = new Leads();
      this.getLeads();
    });
  }

  public viewLead(item: Leads) {
    const modalRef = this.dialogService.open(LeadviewComponent, {
      modal: true,
      showHeader: false,
      header: item.name,
      width: '80%',
      contentStyle: {
        overflow: 'hidden',
        padding: '0px 0.8rem 2rem 0.8rem',
        borderRadius: '4px',
      },
      //baseZIndex: 10000,
      maximizable: true,
      data: {
        item: item,
        projectId: GlobalVariables.selectedProjectId,
        users: this.usersList,
      },
    });

    modalRef.onClose.subscribe(() => {
      this.getLeads();
    });
  }

  //// This is the new pattern to write subscribtion of angular methods
  public getLeadStatus() {
    this.apiService.getLeadStatus(0, this.statusTypeId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.leadStatusList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }

  public getLeads() {
    this.leadsFilter.pageNumber = this.currentPage;
    this.leadsFilter.pageSize = this.pageSize;
    this.apiService.getLeads(this.leadsFilter).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.allleads = [];
        if (resp.status === 'success') {
          this.allleads = resp.data;
          console.log(12,this.allleads);
          if (this.allleads?.length > 0)
            this.totalCount = this.allleads[0].totalRecords;

          this.allleads?.forEach((l) => {
            if (l.assignees) {
              try {
                    const assigneeStrings = l.assignees.split(', ');
                    l.assigneeList = assigneeStrings.map((assigneeStr) => {
                      const [
                        idStr,
                        name,
                        imageUrl
                       
                      ] = assigneeStr.split(';');
                      return {
                        id: parseInt(idStr, 10),
                        name: name.trim(),
                        profileImageUrl: imageUrl.trim()
                        
                      };
                    });
  
                l.firstAssignee = l.assignees.split(',')[0];
                const UserObjects = l.assignees.split(',');
                l.userIds = UserObjects.map((user) =>
                  parseInt(user.split(';')[0])
                );
              } catch (e) {
                console.error(e);
              }
            }
          });
          this.allleads?.forEach((l) => {
            if (l.groups) {
              try {
                // l.firstAssignee = l.assignees.split(',')[0];
                const UserObjects = l.groups.split(',');
                l.groupIds = UserObjects.map((user) =>
                  parseInt(user.split(';')[0])
                );
              } catch (e) {
                console.error(e);
              }
            }
          });
          console.log(this.allleads);
          this.generateDisplayedPages();
          this.sharedService.triggerChildLoaded();
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.usersList = resp.data;
        } else {
          this.usersList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  getLeadGroups() {
    this.apiService
      .getAllLeadGroups(GlobalVariables.selectedProjectId)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.leadGroupList = resp.data;
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
        complete: () => {},
      });
  }
  public GetRemarksByLeadId(id: number) {
    this.apiService.getRemarksByLeadId(id).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.allLogs = resp.data;
        } else {
          this.allLogs = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public deleteLeadById(id: number) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message: 'Do you want to delete this lead?',
      accept: () => {
        this.apiService.deleteLeadById(id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: resp.message,
                life: 3000,
              });
              this.loadData();
            } else {
              this.allLogs = resp.data;
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  selectUsersFilter(e: any) {
    const data = e;
    this.selectedUersFilter.push(data);
  }

  onItemDeSelectUsersFilter(item: any) {
    this.selectedUersFilter?.forEach((value: any, index: any) => {
      if (value == item.id) this.selectedUersFilter?.splice(index, 1);
    });
  }

  onSelectAllUsersFilter(items: any) {
    this.selectedUersFilter = items;
  }

  onDeSelectAllIUsersFilter(items: any) {
    this.selectedUersFilter = items;
  }
  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getLeads();
  }
  public calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  public generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  public onPageSizeChange(): void {
    this.currentPage = 1;
    this.getLeads();
  }

  public addDealModal(item: Leads | null) {
    this.deals = new Deals();
    if (item) {
      this.deals.userIds = item.userIds;
      this.deals.name = item.name;
      this.deals.leadId = item.id;
      this.deals.userIds = item.userIds;
    }
    this.deals.leadId = item.id;
    this.deals.userIds = item.userIds;
    this.deals.name = item.name;
    this.deals.statusId = this.dealStatusList.map((x) => x.id)[0];
    this.deals.probability = 0;
    this.deals.value = 0;
    this.deals.projectId = item.projectId;
    this.dialogService.open(AdddealsComponent, {
      modal: true,
      showHeader: true,
      header: 'Add Deal',
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        deal: this.deals,
        dealStatusList: this.dealStatusList,
        projectId: GlobalVariables.selectedProjectId,
      },
    });
  }
  public getDealstatus() {
    this.apiService
      .getDealStatus(this.leadsFilter.projectId, StatusTypeId.deals)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.dealStatusList = resp.data;
          } else {
            this.dealStatusList = resp.data;
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
        complete: () => {},
      });
  }

  toggleCheck() {
    this.selectedLeads = this.allleads.filter((x) => x.selected);
  }

  toggleAllChecks(chk: any) {
    this.allleads.forEach((x) => (x.selected = chk.checked()));
    this.selectedLeads = this.allleads.filter((x) => x.selected);
  }
  addAssignee() {
    const selectedLeads = this.allleads.filter((x) => x.selected);
    if (selectedLeads.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'No Leads Selected',
        detail: 'You must select at least one lead to continue',
        life: 3000,
      });

      return;
    }
    const modalRef = this.dialogService.open(AssignLeadsComponent, {
      modal: true,
      showHeader: true,
      header: 'Bulk Assign',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        leads: selectedLeads,
        projectId: GlobalVariables.selectedProjectId,
      },
    });
    modalRef.onClose.subscribe((value) => {
      this.getLeads();
      this.selectedLeads.length = 0;
    });
  }
}
