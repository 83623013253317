export class SoftwareTrial {
  id: number;
  dateCreated: Date;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  extendedCount: number;
  orgId: number;
  isActive: boolean;

  constructor() {
    this.id = 0;
    this.dateCreated = new Date();
    this.title = '';
    this.description = '';
    this.startDate = new Date();
    this.endDate = new Date();
    this.extendedCount = 0;
    this.orgId = 0;
    this.isActive = true;
  }

  static map(obj: any): SoftwareTrial {
    if (obj) {
      const trial = new SoftwareTrial();
      trial.id = obj?.id;
      trial.dateCreated = new Date(obj?.dateCreated);
      trial.title = obj?.title;
      trial.description = obj?.description;
      trial.startDate = new Date(obj?.startDate);
      trial.endDate = new Date(obj?.endDate);
      trial.extendedCount = obj?.extendedCount;
      trial.orgId = obj?.orgId;
      trial.isActive = obj?.isActive;
      return trial;
    }
    return null;
  }

  getDaysUntilExpiry(): number {
    const currentDate = new Date();
    const expiryDate = new Date(this.endDate);
    const timeDifference = expiryDate.getTime() - currentDate.getTime();
    const daysUntilExpiry = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysUntilExpiry;
  }

  getExpiryMessage(): string {
    const daysUntilExpiry = this.getDaysUntilExpiry();

    if (daysUntilExpiry < 0) {
      return 'Your trial period has expired';
    }

    if (daysUntilExpiry <= 7) {
      return `Your trial period is about to expire in ${daysUntilExpiry} days.`;
    }
    return `Your trial period is valid for ${daysUntilExpiry} days.`;
  }

  checkIfExpired(whatsApp: string): string {
    const currentDate = new Date();
    const expiryDate = new Date(this.endDate);
    if (currentDate > expiryDate) {
      return `Your trial period has expired. To upgrade, please contact us on WhatsApp at ${whatsApp}.`;
    }
    return null;
  }
}
