import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Result } from 'src/app/models/Result';
import { Project, User } from 'src/app/models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { padZero } from 'src/app/utils/utils';
import { RoleSetupComponent } from '../role-setup/role-setup.component';
import { Role } from 'src/app/models/Role';
import { Permission } from 'src/app/models/Permission';
import { PermissionListComponent } from '../permission-list/permission-list.component';
import { RolePermissionsListComponent } from '../role-permissions-list/role-permissions-list.component';

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.css']
})
export class ManageRolesComponent {

  public roles: Role[] = [];
  public filteredUsers: User[] = [];
  public permissions: Permission[] = [];
  searchKeyword: string = '';
  isLoading: boolean = true;
  isModalVisible: boolean = false;

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    public sharedService: SharedService,
    private confirmationService: ConfirmationService,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
      this.getAllRoles();
    });
  }

  ngOnInit(): void {
    this.getAllRoles();
  }

  getAllRoles() {
    this.apiService.getAllRoles().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.roles = resp.data;
        }

        this.isLoading = false;
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
        this.isLoading = false;
      },
    });
  }

  addNewRole() {
    // if (!this.checkPermission()) {
    //   return;
    // }

    const modalRef = this.dialogService.open(RoleSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'New Role',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        role: new Role(),
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllRoles();
    });
  }

  viewUserProfile(user: User) {}

  disableUser(user: User) {}


  editRole(role: Role) {
    // if (this.authService.currentUserValue.id) {
    //   if (!this.checkPermission()) {
    //     return;
    //   }
    // }

    const modalRef = this.dialogService.open(RoleSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'Edit Role',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        role: role,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllRoles();
    });
  }
  deleteRole(role: Role) {
    // if (!this.checkPermission()) {
    //   return;
    // }

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + role.roleName + '? All assigned roles will be changed to the default role.',
      header: 'Confirmation',
      icon: 'fa-regular fa-circle-info',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.apiService.deleteRole(role.id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.getAllRoles();
              this.messageService.add({
                severity: 'success',
                summary: 'Role Deleted',
                detail: resp.message,
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warnig',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            this.messageService.add({
              severity: 'warn',
              summary: 'Warnig',
              detail: 'Something went wrong',
              life: 3000,
            });
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  rolePermissions(role:Role) {
    // if (!this.checkPermission()) {
    //   return;
    // }
    this.permissions = JSON.parse(role.permissionsJson);
    const modalRef = this.dialogService.open(PermissionListComponent, {
      modal: true,
      showHeader: true,
      header: 'Permissions',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        permissions: this.permissions,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllRoles();
    });
  }
  managePermissions(role:Role){
    this.permissions = JSON.parse(role.permissionsJson);
    const modalRef = this.dialogService.open(RolePermissionsListComponent, {
      modal: true,
      showHeader: true,
      header: role.roleName,
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        role: role,
        permissions:this.permissions
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllRoles();
    });
  }
  // checkPermission() {
  //   if (this.authService.currentUserValue.userTypeId > 1) {
  //     this.messageService.add({
  //       severity: 'warn',
  //       summary: 'Warnig',
  //       detail: 'You are not allowed to perform this action',
  //       life: 3000,
  //     });

  //     return false;
  //   }
  //   return true;
  // }
}
