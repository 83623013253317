<div class="modal-body">
  <form>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="alert alert-warning p-1 mt-3 mb-3 fs-12px" role="alert">
            <i class="fa fa-regular fa-circle-info text-dark mr-1 ml-1"></i>
            <span>Please select assignees below
            </span>
          </div>

          <div>
            <p-multiSelect name="usersselect" [options]="usersList" [(ngModel)]="selectedUsers"
              placeholder="Select Assignees" optionLabel="name" display="chip" appendTo="body">
            </p-multiSelect>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button type="button" (click)="onSubmit()" [disabled]="this.IsInConvertProcess"
        class="border-0 btn btn-primary btn-sm action-button" style="width: 80px;">Save</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-sm action-button-cancel" style="width: 80px;"
        (click)="modalClose()">Cancel</button>
    </div>
  </form>
</div>
