<div class="modal-body pb-5 me-3">
  <form class="mt-3">
    <!-- Section: Client Information -->
    <div id="clientInfoSection" class="section-title mt-3">
      <h5><i class="far fa-user me-1"></i>Client Information</h5>
      <div class="desc">Provide details about the client and their contact information</div>
    </div>
    <div class="form-row m-0">
      <div class="form-group col-md-6">
        <label for="clientName" class="required-label">Client Name</label>
        <input type="text" class="form-control mt-1" name="clientName" id="clientName" placeholder="Enter client name"
          [(ngModel)]="client.clientName" appSpecialCharsBlocker [min]=3 [max]=255 required>
      </div>
      <div class="form-group col-md-6">
        <label for="contactName" class="required-label">Contact Name</label>
        <input type="text" class="form-control mt-1" appSpecialCharsBlocker inputValidator name="contactName"
          id="contactName" placeholder="Enter contact name" [(ngModel)]="client.contactName">
      </div>
    </div>
    <div class="form-row m-0">
      <div class="form-group col-md-6">
        <label for="contactEmail">Contact Email</label>
        <input type="email" class="form-control mt-1" name="contactEmail" id="contactEmail"
          placeholder="Enter contact email" [(ngModel)]="client.contactEmail" emailValidator required>
      </div>
      <div class="form-group col-md-6">
        <label for="contactPhone">Contact Phone</label>
        <input type="tel" class="form-control mt-1" name="contactPhone" id="contactPhone"
          placeholder="Enter contact phone" [(ngModel)]="client.contactPhone" phoneValidator appOnlyNumbers>
      </div>
    </div>

    <!-- Section: Address Information -->
    <div id="addressSection" class="section-title mt-3">
      <h5><i class="far fa-map-marker-alt me-1"></i>Address Information</h5>
      <div class="desc">Enter the client's address details</div>
    </div>
    <div class="form-group">
      <label for="addressLine1">Address Line 1</label>
      <input type="text" class="form-control mt-1" name="addressLine1" id="addressLine1"
        placeholder="Enter address line 1" appSpecialCharsBlocker inputValidator [(ngModel)]="client.addressLine1"
        [required]=true>
    </div>
    <div class="form-group">
      <label for="addressLine2">Address Line 2</label>
      <input type="text" class="form-control mt-1" name="addressLine2" id="addressLine2"
        placeholder="Enter address line 2" appSpecialCharsBlocker inputValidator [(ngModel)]="client.addressLine2">
    </div>
    <div class="form-row m-0 justify-content-between">
      <div class="form-group">
        <label for="country" class="d-block mb-1">Country</label>
        <p-dropdown appendTo="body" [options]="countriesList" name="country" optionLabel="name" [filter]="true"
          [ngModel]="client.countryId" (ngModelChange)="onCountryChange($event)" filterBy="name" [showClear]="false"
          dataKey="id" optionValue="id" placeholder="Select a Country" required>
        </p-dropdown>
      </div>
      <div class="form-group">
        <label for="state" class="d-block mb-1">State</label>
        <p-dropdown appendTo="body" [options]="statesList" name="stateId" [ngModel]="client.stateId"
          (ngModelChange)="onStateChange($event)" optionLabel="name" [filter]="true" filterBy="name" [showClear]="false"
          dataKey="id" optionValue="id" placeholder="Select a State" required>
        </p-dropdown>
      </div>
      <div class="form-group">
        <label for="city" class="d-block mb-1">City</label>
        <p-dropdown appendTo="body" [options]="citiesList" name="cityId" [ngModel]="client.cityId"
          (ngModelChange)="onCityChange($event)" optionLabel="name" [filter]="true" filterBy="name" [showClear]="false"
          dataKey="id" optionValue="id" placeholder="Select a city" required>
        </p-dropdown>
      </div>
      <div class="form-group">
        <label for="postalCode">Zip Code</label>
        <input type="text" class="form-control mt-1" name="zipCode" id="zipCode" placeholder="Enter postal code"
          [(ngModel)]="client.zipCode" appOnlyNumbers>
      </div>
    </div>

    <!-- Section: Business Information -->
    <div id="businessSection" class="section-title mt-3">
      <h5><i class="far fa-briefcase me-1"></i>Business Information</h5>
      <div class="desc">Specify the lead's business type, industry, and specializations</div>
    </div>
    <div class="form-group">
      <label for="clientType">Client Type</label>
      <p-dropdown appendTo="body" name="clientType" [options]="clientTypes" [(ngModel)]="selectedClientType"
        optionLabel="type" placeholder="Select Client Type" required />
    </div>
    <div class="form-group">
      <label for="industryType">Industry Type</label>
      <p-dropdown appendTo="body" name="industryType" [options]="industryTypes" [(ngModel)]="selectedIndustryType"
        optionLabel="type" placeholder="Select Industry Type" required />
    </div>

  </form>
</div>

<div class="modal-footer mt-3">
  <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
    (click)="modalClose()">Cancel</button>
  <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()"
    [disabled]="this.isDataSaving">{{client.id > 0 ? 'Update Client' : 'Create Client'}}</button>
</div>
