import { Role } from 'src/app/models/Role';

export class User {
  id: number;
  dateCreated: Date | null;
  firstName: string;
  lastName: string;
  name: string;
  userName: string;
  email: string;
  password: string;
  profilePictureUrl: string;
  emailAddress: string;
  projectIds: string;
  projects: Project[];
  role: string;
  roleInfo: Role;
  jobTitleId: number;
  jobTitle: string;
  teamId: number;
  teamName: string;
  defaultProjectId: number;
  isActive: boolean = true;
  userTypeId: number;
  userType: string;
  organizationId: number;
  phoneNo: string;
  token: string | null;
  estimatedHours: number | null;
  ratesPerHour: number | null;
  selected: boolean = false;
  hrmsEmpId: number | null;
  isImported: boolean = false;
  errorMessage: string | null;
  roleId: number;
  roleName: string;
  hasError: boolean;
  isDeleted: boolean;
  fCMToken: string;
  inviteAccepted: boolean;
}

export class UserRegisterRequest {
  firstName!: string | null;
  lastName!: string | null;
  emailAddress!: string | null;
  password!: string | null;
}

export class LoginCredentials {
  userName!: string | null;
  password!: string | null;
}

export class Project {
  public id: number | 0;
  public name: string | null;
  public icon: string | null;
  public isDefault: boolean;
  public description: string | null;
}

export class ChangePassword {
  public userId: number | 0;
  public currentPassword :string | null;
  public newPassword :string | null;
}
