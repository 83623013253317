import { AuthService } from './../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { Contact } from 'src/app/models/Contact';
import { ContactAndLeadMapping } from 'src/app/models/ContactAndLeadMapping';
import { ContactLead, Leads } from 'src/app/models/Leads';
import { Result } from 'src/app/models/Result';
import { User } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-convert-to-lead',
  templateUrl: './convert-to-lead.component.html',
  styleUrl: './convert-to-lead.component.css',
})
export class ConvertToLeadComponent {
  // apiUrl: string = 'https://dev-crm-api.softnology.com/route/';
  apiUrl: string = 'https://staging-crm-api.radiantworkforce.com/route/';
  projectId = 0;
  IsInConvertProcess: boolean = false;

  usersList: User[] = [];
  selectedUsers: User[] = [];
  contacts: Contact[] = [];
  leads: ContactLead[] = [];

  constructor(
    private apiService: ApiServiceService,
    private authService: AuthService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    this.contacts = this.dialogConfig.data.contacts;
    this.projectId = this.dialogConfig.data.projectId;
  }

  ngOnInit(): void {
    if (sessionStorage['authorized'] === '1') {
      this.getAllUsers();
    }
  }

  getAllUsers() {
    this.apiService.getAllUsers(this.projectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.usersList = resp.data;
        } else {
          this.usersList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  validate() {
    if (!this.selectedUsers || this.selectedUsers.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'No Assignee Selected',
        detail: 'You must select at least one assignee',
        life: 3000,
      });
      return false;
    }

    return true;
  }

  onSubmit() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'Are you sure you want to continue?',
      accept: () => {
        this.IsInConvertProcess = true;
        const userIds: number[] = this.selectedUsers.map((u) => u.id);
        const contactIds: number[] = this.contacts.map((c) => c.id);

        this.contacts.map((c) => {
          const leadName = `${c.firstName} ${!c.initial ? '' : c.initial} ${
            c.lastName
          }`;
          this.leads.push({
            name: leadName,
            email: c.email,
            concernPerson: leadName,
            contactPerson: leadName,
            statusId: 3,
            phoneNumber: c.phoneNumber,
            mobileNumber: c.cellPhone,
            city: c.city,
            state: c.state,
            zip: c.zip,
            country: c.countyName,
            userIds: userIds,
            projectId: this.projectId,
            createdByUserId: this.authService.currentUserValue.id,
            organizationId: this.authService.currentUserValue.organizationId,
            contactId: c.id,
          });
        });

        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
        });

        this.http
          .post(`${this.apiUrl}${'Leads/BulkCreate'}`, this.leads, {
            headers,
          })
          .subscribe({
            next: (resp: Result) => {
              this.IsInConvertProcess = false;
              this.contacts = [];
              if (resp.status === 'success') {

                let contactAndLeadMapping: ContactAndLeadMapping[] = resp.data;

                this.apiService.updateContacts(contactAndLeadMapping).subscribe({
                  next: (resp: Result) => {
                    if (resp.status === 'success') {
                      this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: resp.message,
                        life: 3000,
                      });
                      this.dialogRef.close(true);
                    } else {
                      this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: resp.message,
                        life: 3000,
                      });
                    }
                    this.IsInConvertProcess = false;
                  },
                  error: (error) => {
                    // Handle error
                    this.IsInConvertProcess = false;
                    console.error('error:', error);
                  },
                });

                this.dialogRef.close(true);
              }
            },
            error: (err) => {
              // Handle error
              this.dialogRef.close(false);
              console.error('error:', err);
            },
          });
      },
      reject: () => {},
    });
  }

  modalClose() {
    this.dialogRef.close(null);
  }
}
