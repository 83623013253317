import { AuthService } from './../../../services/auth.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Contact } from 'src/app/models/Contact';
import { Result } from 'src/app/models/Result';
import { User } from 'src/app/models/Users';

import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { GlobalVariables } from 'src/environments/environment';
import { ConvertToLeadComponent } from '../convert-to-lead/convert-to-lead.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Leads } from 'src/app/models/Leads';
import { City } from 'src/app/Models/Country';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.css'],
})
export class ContactsListComponent {
  password: string = '';
  errorMessage: string = '';
  isAuthorized: boolean = false;
  private correctPassword = '5S4Q3Ef{y#{m';
  public selectedUsers: User[] = [];
  public usersList: User[] = [];
  public cities: City[] = [];
  minHomeValue: number = 10000;
  maxHomeValue: number = 1000000000;
  filterVisible = false;
  isLoading = false;
  totalCount: number = 0;
  totalPages: number = 0;
  currentPage = 1;
  pageSize = 100;
  displayedPages: number[] = [];

  contacts: Contact[] = [];
  selectedContacts: Contact[] = [];
  filters: Contact;
  homeValueRange:  number[] = [0, 100000000];

  constructor(
    private apiService: ApiServiceService,
    private messageService: MessageService,
    private dialogService: DialogService,
    public authService: AuthService
  ) {
    this.filters = new Contact();
  }

  ngOnInit(): void {
    if (sessionStorage['authorized'] === '1') {
      this.isAuthorized = true;
      this.fetchContacts();
      this.getCitiesFromFLDB();
    }
  }
  onCityChange(value: any) {
    this.filters.cityId = value;
  }
  public getCitiesFromFLDB() {
    this.apiService.getCitiesFromFLDB().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.cities = resp.data;
        } else {
          this.cities = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }
  submitPassword() {
    if (this.password === this.correctPassword) {
      sessionStorage.setItem('authorized', '1');
      this.isAuthorized = true;
      this.fetchContacts();
    } else {
      this.errorMessage = 'Incorrect password. Please try again.';
    }
  }

  toggleCheck() {
    this.selectedContacts = this.contacts.filter((x) => x.selected);
  }

  toggleAllChecks(chk: any) {

    this.contacts
      .filter(
        (x) =>
          !x.leadId ||
          x.orgId !== this.authService.currentUserValue.organizationId
      )
      .forEach((x) => (x.selected = chk.checked()));
    this.selectedContacts = this.contacts.filter((x) => x.selected);
  }

  fetchContacts() {
    if (!this.isAuthorized) {
      return;
    }

    this.isLoading = true;
    this.filters.pageNumber = this.currentPage;
    this.filters.pageSize = this.pageSize;
    //this.filters.onlyLead = true;
    this.filters.minHomeValue= this.homeValueRange[0];
    this.filters.maxHomeValue= this.homeValueRange[1];
    this.apiService.getContacts(this.filters).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.contacts = [];
        if (resp.status === 'success') {
          this.contacts = resp.data;
          this.apiService.getContactsCount(this.filters).subscribe({
            next: (resp: Result) => {
              if (resp.status === 'success') {
                this.totalCount = Number(resp.data);

                if (this.totalCount > 0) {
                  this.generateDisplayedPages();
                }
              }
            },
            error: (error) => {
              // Handle error
              console.error('error:', error);
            },
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  applyFilters(): void {
    this.fetchContacts();
  }

  resetFilters() {
    this.filters.name = null;
    this.filters.addressLine1 = null;
    this.filters.cityId = null;
    this.filters.state = null;
    this.filters.zip = null;
    this.filters.countyId = null;
    this.filters.exactAgeId = null;
    this.filters.estAgeId = null;
    this.filters.estIncomeId = null;
    this.filters.lengthOfResidenceId = null;
    this.filters.addrTypeId = null;
    this.filters.homeownerTypeId = null;
    this.filters.propertyTypeId = null;
    this.filters.medHMEVLId = null;
    this.filters.ethnicCDId = null;
    this.filters.genderId = null;
    this.filters.estWealthId = null;
    this.filters.phoneNumber = null;
  }

  convert() {
    const selectedContacts = this.contacts.filter((x) => x.selected);
    if (selectedContacts.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'No Contact Selected',
        detail: 'You must select at least one contact to continue',
        life: 3000,
      });

      return;
    }
    const modalRef = this.dialogService.open(ConvertToLeadComponent, {
      modal: true,
      showHeader: true,
      header: 'Create Leads',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        contacts: selectedContacts,
        projectId: GlobalVariables.selectedProjectId,
      },
    });
    modalRef.onClose.subscribe((value) => {
      if (value) {
        this.fetchContacts();
      }
    });
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.fetchContacts();
  }

  calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }

  generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }

  onPageSizeChange(): void {
    this.currentPage = 1;
    this.fetchContacts();
  }
}
