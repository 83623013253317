import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Result } from '../models/Result';
import { AuthServiceService } from './auth-service.service';
import { id } from 'date-fns/locale';
import { LeadsFilter } from '../models/Leads';
import { User, Project, ChangePassword } from '../models/Users';
import { Contact } from '../models/Contact';
import { Role } from '../models/Role';
import { AddPermissionsRequest } from '../models/Permission';
import { Team } from '../models/Teams';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceService {
  private authToken: string | null = null;

  constructor(
    private http: HttpClient,
    public authService: AuthServiceService
  ) {}

  login(user: any): Observable<any> {
    const url = `${environment.apiUrl}/User/Login`;
    // No need to manually stringify, just pass the object directly
    return this.http.post<Result<any>>(url, user);
  }

  // Example of a GET request with authentication headers

  getAllUsers(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAllUsers`;

    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }

  getAllLeads(): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetAllLeads`;

    return this.http.get<Result<any>>(`${url}`, { withCredentials: true });
  }

  getLeadById(obj: LeadsFilter): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetLeadById`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  addUpdateLeads(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/AddUpdateLeads`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getDealStatus(projectId: number, statusTypeId: number): Observable<any> {
    const url = `${environment.apiUrl}/Deal/GetDealStatus`;

    return this.http.get<Result<any>>(`${url}/${projectId}/${statusTypeId}`, {
      withCredentials: true,
    });
  }
  getLeadStatus(projectId: number, statusTypeId: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetLeadStatus`;

    return this.http.get<Result<any>>(`${url}/${projectId}/${statusTypeId}`, {
      withCredentials: true,
    });
  }

  getRemarksByLeadId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetRemarksByLeadId`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  getRemarksByDealId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Deal/GetRemarksByDealId`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  deleteLeadById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/DeleteLeadById`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }

  deleteLogById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/DeleteLogById`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }

  getAssigneeByLeadId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetAssigneeByLeadId`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }

  addUpdateRemarks(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/AddUpdateRemarks`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  addUpdateDealLogs(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Deal/AddUpdateDealLogs`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  addUpdateDeals(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Deal/AddUpdateDeals`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getLeads(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetAllLeads`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getMatchingLeadNames(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetMatchingLeadNames`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getDeals(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Deal/GetAllDeals`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getEvents(filters: any): Observable<any> {
    const url = `${environment.apiUrl}/Events/GetEvents`;

    return this.http.post<Result<any>>(url, filters, { withCredentials: true });
  }

  insertUpdateEvent(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Events/InsertUpdateEvent`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  addFCMToken(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Home/AddFCMToken`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  deleteEvent(Id: number): Observable<any> {
    const url = `${environment.apiUrl}/Events/DeleteEvent`;

    return this.http.get<Result<any>>(`${url}/${Id}`, {
      withCredentials: true,
    });
  }
  getUserProjects(): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetUserProjects`;

    return this.http.get<Result<any>>(`${url}`, { withCredentials: true });
  }

  getStatusByStatusTypeId(statusTypeId: number): Observable<any> {
    const url = `${environment.apiUrl}/Events/GetStatusByStatusTypeId`;

    return this.http.get<Result<any>>(`${url}/${statusTypeId}`, {
      withCredentials: true,
    });
  }
  bulkLeadsAssignees(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/BulkAssignees`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getCampaignsList(): Observable<any> {
    const url = `${environment.apiUrl}/Campaign/GetCampaignsList`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }
  getAllLeadGroups(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetAllLeadGroups`;
    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }
  getLeadTypes(): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetLeadTypes`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getLeadSources(): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetLeadSources`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getLeadEntityTypes(): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetLeadEntityTypes`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getIndustryTypes(): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetIndustryTypes`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getIndustrySpecializations(): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetIndustrySpecializations`;
    return this.http.get<any>(url, { withCredentials: true });
  }
  //#region Common
  getCountries(): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetCountries`;
    return this.http.get<Result>(`${url}`, { withCredentials: false });
  }
  getStates(countryId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetStates`;
    return this.http.get<Result>(`${url}/${countryId}`, {
      withCredentials: false,
    });
  }
  getCities(stateId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetCities`;
    return this.http.get<Result>(`${url}/${stateId}`, {
      withCredentials: false,
    });
  }
  getCitiesFromFLDB(): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetCitiesFromFLDB`;
    return this.http.get<Result>(`${url}`, {
      withCredentials: false,
    });
  }
  getS3SignedUrl(sourceUrl: string, fileType: string): Observable<any> {
    const url = `${environment.apiUrl}/Common/GenerateS3SignedUrl`;

    return this.http.get<Result<any>>(`${url}/${sourceUrl}/${fileType}`, {
      withCredentials: true,
    });
  }
  getAwsCredentials(password: string): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetAwsCredentials`;
    return this.http.get<Result<any>>(`${url}/${password}`, {
      withCredentials: true,
    });
  }
  getS3SignedUrlForDocuments(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Common/GenerateS3SignedUrlForDocuments`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  setDefaultProject(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/SetDefaultProject`;

    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }

  addOrUpdateProject(project: Project): Observable<any> {
    const url = `${environment.apiUrl}/Common/AddOrUpdateProject`;

    return this.http.post<Result<any>>(url, project, { withCredentials: true });
  }

  deleteProject(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/DeleteProject`;

    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }

  uploadToS3(file: File, presignedUrl: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({
      'Content-Type': file.type, // Example: Set Content-Type based on file type
    });

    const req = new HttpRequest('PUT', presignedUrl, formData, {
      headers: headers,
      reportProgress: true, // Enable progress tracking
    });

    return this.http.request(req);
  }

  //#endregion

  //#region Analytics

  getLeadsMetrics(obj: LeadsFilter): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetLeadsMetrics`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getLeadsConversions(obj: LeadsFilter): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetLeadsConversions`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getTopPerformers(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetTopPerformers`;

    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }

  //#endregion

  //#region User Management

  addOrUpdateUser(user: User): Observable<any> {
    const url = `${environment.apiUrl}/User/AddOrUpdateUser`;

    return this.http.post<Result<any>>(url, user, { withCredentials: true });
  }

  deleteUser(userId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/DeleteUser`;

    return this.http.get<Result<any>>(`${url}/${userId}`, {
      withCredentials: true,
    });
  }

  getUserInvitationStatus(token: string): Observable<any> {
    const url = `${environment.apiUrl}/User/GetUserInvitationStatus`;

    return this.http.get<Result<any>>(`${url}/${token}`, {
      withCredentials: true,
    });
  }

  GetSoftwareTrialInfo(): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetSoftwareTrialInfo`;

    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  acceptInvitation(user: User): Observable<any> {
    const url = `${environment.apiUrl}/User/AcceptInvitation`;

    return this.http.post<Result<any>>(url, user, { withCredentials: true });
  }

  changePassword(userPass: ChangePassword): Observable<any> {
    const url = `${environment.apiUrl}/User/ChangePassword`;

    return this.http.post<Result<any>>(url, userPass, {
      withCredentials: true,
    });
  }
  //#endregion

  //#region Plugins

  activateHrmsPlugin(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/ActivateHrmsPlugin`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  saveHrmsPluginSettings(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/SaveHrmsPluginSettings`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getHrmsLocations(): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/GetHrmsLocations`;
    return this.http.get<any>(`${url}`, { withCredentials: true });
  }

  getHrmsEmployees(locationId: number): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/GetHrmsEmployees`;
    return this.http.get<any>(`${url}/${locationId}`, {
      withCredentials: true,
    });
  }

  getHrmsPluginConfig(): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/GetHrmsPluginConfig`;
    return this.http.get<any>(`${url}`, { withCredentials: true });
  }

  deletePluginConfig(pluginId: number): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/DeletePluginConfig`;
    return this.http.get<any>(`${url}/${pluginId}`, {
      withCredentials: true,
    });
  }

  getImportedHrmsEmployeesCount(): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/GetImportedHrmsEmployeesCount`;
    return this.http.get<any>(`${url}`, {
      withCredentials: true,
    });
  }

  syncHrmsEmployees(): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/SyncHrmsEmployees`;
    return this.http.get<any>(`${url}`, {
      withCredentials: true,
    });
  }
  markAttendanceByHrmsPlugin(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/MarkAttendanceByHrmsPlugin`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  //#endregion

  //#region Roles and permissions
  getAllRoles(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAllRoles`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  addOrUpdateRole(role: Role): Observable<any> {
    const url = `${environment.apiUrl}/User/AddOrUpdateRole`;
    return this.http.post<Result<any>>(url, role, { withCredentials: true });
  }

  deleteRole(roleId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/DeleteRole`;
    return this.http.get<Result<any>>(`${url}/${roleId}`, {
      withCredentials: true,
    });
  }

  getPermissions(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetPermissions`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  addPermissions(
    addPermissionsRequest: AddPermissionsRequest
  ): Observable<any> {
    const url = `${environment.apiUrl}/User/AddPermissions`;
    return this.http.post<Result<any>>(url, addPermissionsRequest, {
      withCredentials: true,
    });
  }

  getUserRoleByUserId(userId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/GetUserRoleByUserId`;

    return this.http.get<Result<any>>(`${url}/${userId}`, {
      withCredentials: true,
    });
  }

  //#endregion

  //#region Job Titles

  getJobTitles(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAllJobTitles`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  //#endregion
  //#region Teams

  getAllTeams(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAllTeams`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  getTeamById(teamId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/GetTeamById`;
    return this.http.get<Result<any>>(`${url}/${teamId}`, {
      withCredentials: true,
    });
  }

  addUpdateTeam(team: Team): Observable<any> {
    const url = `${environment.apiUrl}/User/${
      team?.id > 0 ? 'UpdateTeam' : 'AddTeam'
    }`;
    return this.http.post<Result<any>>(url, team, {
      withCredentials: true,
    });
  }

  updateTeam(team: Team): Observable<any> {
    const url = `${environment.apiUrl}/User/UpdateTeam`;
    return this.http.post<Result<any>>(url, team, {
      withCredentials: true,
    });
  }

  deleteTeam(teamId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/DeleteTeam`;
    return this.http.get<Result<any>>(`${url}/${teamId}`, {
      withCredentials: true,
    });
  }

  getAvailableUsersForTeam(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAvailableUsersForTeam`;

    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  getUsersByTeamId(teamId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/GetUsersByTeamId`;

    return this.http.get<Result<any>>(`${url}/${teamId}`, {
      withCredentials: true,
    });
  }

  //#endregion

  //#region Contacts

  getContacts(contact: Contact): Observable<any> {
    const url = `${environment.apiUrl}/Contacts/GetContacts`;

    return this.http.post<Result<any>>(url, contact, { withCredentials: true });
  }

  getContactsCount(contact: Contact): Observable<any> {
    const url = `${environment.apiUrl}/Contacts/GetContactsCount`;

    return this.http.post<Result<any>>(url, contact, { withCredentials: true });
  }

  convertToLead(obj: any) {
    const url = `${environment.apiUrl}/Contacts/ConvertToLead`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  updateContacts(leads: any) {
    const url = `${environment.apiUrl}/Contacts/UpdateContacts`;

    return this.http.post<Result<any>>(url, leads, {
      withCredentials: true,
    });
  }

  //#endregion
}
