<div class="modal-body p-4">
  <input type="hidden" />
  <div class="row">
    <div class="col-md-3">
      <nav id="sidebar-bootstrap" class="navbar navbar-sticky d-none d-xl-block">
        <nav class="nav">
          <a class="nav-link" href="#basicSection" data-toggle="scroll-to"><i class="far fa-info-circle me-2"></i>Basic Information</a>
          <a class="nav-link" href="#contactSection" data-toggle="scroll-to"><i class="far fa-envelope me-2"></i>Contact Details</a>
          <a class="nav-link" href="#businessSection" data-toggle="scroll-to"><i class="far fa-briefcase me-2"></i>Business</a>
          <a class="nav-link" href="#addressSection" data-toggle="scroll-to"><i class="far fa-map-marker-alt me-2"></i>Addresses</a>
          <a class="nav-link" href="#noteSection" data-toggle="scroll-to"><i class="far fa-sticky-note me-2"></i>Initial Notes</a>
          <a class="nav-link" href="#additionalSection" data-toggle="scroll-to"><i class="far fa-plus-square me-2"></i>Additional Details</a>
        </nav>
      </nav>
    </div>
    <div class="col-md-9">
      <div data-bs-spy="scroll" data-bs-target="#sidebar-bootstrap" data-bs-smooth-scroll="true"
        class="scrollspy-example-2" tabindex="0">
        <!-- Section Title -->
        <div id="basicSection" class="section-title mb-3">
          <h5><i class="far fa-info-circle me-1"></i> Basic Information</h5>
          <div class="desc">Enter the lead's fundamental details like name and group</div>
        </div>

        <div class="mb-4" style="display: grid; grid-template-columns: 30% 70%; gap: 0.5rem;">
          <label for="name">Lead Name</label>
          <input type="text" class="form-control" [(ngModel)]="leads.name" name="name" />

          <label for="leadType">Lead Group</label>
          <p-multiSelect name="usersselect" [options]="leadGroupsList" [(ngModel)]="selectedLeadGroups"
          placeholder="Select Lead Group" optionLabel="groupName" display="chip"></p-multiSelect>


          <label for="leadType">Lead Type</label>
          <select name="leadType" [(ngModel)]="leads.leadTypeId" class="form-control custom-select">
            <option *ngFor="let item of leadTypesList" [value]="item.id">{{ item.type }}</option>
          </select>

          <label for="leadType">Lead Source</label>
          <select name="leadType" [(ngModel)]="leads.leadSourceId" class="form-control custom-select">
            <option *ngFor="let item of leadSourcesList" [value]="item.id">{{ item.source }}</option>
          </select>

          <label for="usersselect">Assigned To</label>
          <p-multiSelect name="usersselect" [options]="usersList" [(ngModel)]="selectedUsers"
            placeholder="Select Assignees" optionLabel="name" display="chip"></p-multiSelect>

          <label for="status">Status</label>
          <select name="status" [(ngModel)]="leads.statusId" class="form-control custom-select">
            <option *ngFor="let item of leadStatusList" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>


        <!-- Section Title -->
        <div id="contactSection" class="section-title mb-3">
          <h5><i class="far fa-envelope me-1"></i>Contact Details</h5>
          <div class="desc">Provide essential contact information for the lead</div>
        </div>

        <!-- Basic Info Row -->
        <div class="row mb-0">

          <div class="col-md-4">
            <div class="form-group">
              <label for="contactPerson">Contact Person</label>
              <input type="text" class="form-control" name="contactPerson" [(ngModel)]="leads.contactPerson" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="concernPerson">Concern Person</label>
              <input type="text" class="form-control" name="concernPerson" [(ngModel)]="leads.concernPerson" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="email">Email Address</label>
              <input type="email" class="form-control" name="email" [(ngModel)]="leads.email" />
            </div>
          </div>
        </div>

        <!-- Contact Info Row -->
        <div class="row mb-0">

          <div class="col-md-4">
            <div class="form-group">
              <label for="phone">Phone</label>
              <input type="tel" class="form-control" placeholder="0000-0000000" [(ngModel)]="leads.phoneNumber" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="mobile">Mobile</label>
              <input type="tel" class="form-control" placeholder="0000-0000000" [(ngModel)]="leads.mobileNumber" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="fax">Fax</label>
              <input type="text" class="form-control" placeholder="0000-0000000" [(ngModel)]="leads.faxNumber" />
            </div>
          </div>
        </div>

        <!-- Section Title -->
        <div id="businessSection" class="section-title mt-3">
          <h5><i class="far fa-briefcase me-1"></i>Business</h5>
          <div class="desc">Specify the lead's business type, industry, and specializations</div>
        </div>

        <!-- Campaign Info Row -->
        <div class="row mb-0">
          <div class="col-md-4">
            <div class="form-group">
              <label for="entityType">Entity Type</label>
              <select name="entityType" [(ngModel)]="leads.leadEntityTypeId" class="form-control custom-select"
                placeholder="Select Entity Type">
                <option *ngFor="let item of leadEntityTypes" [value]="item.id">{{ item.type }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="industry">Industry</label>
              <select name="industry" [(ngModel)]="leads.industryTypeId" (ngModelChange)="onIndustryChange($event)"
                class="form-control custom-select" placeholder="Select Industry">
                <option *ngFor="let item of industryTypesList" [value]="item.id">{{ item.type }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="campaign">Specializations</label>
              <select name="industry" [(ngModel)]="leads.industrySpecializationId" class="form-control custom-select"
                placeholder="Select Industry">
                <option *ngFor="let item of industrySpecializations" [value]="item.id">{{ item.specialization }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Address Info Row -->
        <div id="addressSection" class="section-title mt-3">
          <h5><i class="far fa-map-marker-alt me-1"></i>Addresses</h5>
          <div class="desc">Add and manage multiple addresses associated with the lead</div>
        </div>

        <div *ngFor="let address of addresses; let i = index" class="row mb-3 align-items-end address-container">
          <div class="address-tag">Address {{i+1}} <button class="btn p-0 ml-2" (click)="removeAddress(i)">
              <i class="fa-regular fa-trash-can"></i>
            </button></div>
          <div class="form-group mb-2">
            <label for="name">Name</label>
            <input type="text" class="form-control" [(ngModel)]="address.name" />
          </div>

          <div class="form-group mb-2">
            <label for="address">Address</label>
            <input type="text" class="form-control" [(ngModel)]="address.address" />
          </div>

          <div class="col" *ngIf="isCountryLoaded">
            <div class="form-group mb-0">
              <label for="country">Country </label>
              <select name="country" [(ngModel)]="address.countryId" (ngModelChange)="onCountryChange($event, i)"
                class="form-control custom-select">
                <option *ngFor="let item of countriesList" [value]="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group mb-0">
              <label for="state">State</label>
              <select name="state" [(ngModel)]="address.stateId" (ngModelChange)="onStateChange($event, i)"
                class="form-control custom-select">
                <option *ngFor="let item of address.statesList" [value]="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>

          <div class="col">
            <div class="form-group mb-0">
              <label for="city">City</label>
              <select name="city" [(ngModel)]="address.cityId" (ngModelChange)="onCityChange($event, i)"
                class="form-control custom-select">
                <option *ngFor="let item of address.citiesList" [value]="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>

          <div class="col">
            <div class="form-group mb-0">
              <label for="zip">Zip</label>
              <input type="text" class="form-control" [(ngModel)]="address.zip" />
            </div>
          </div>

        </div>

        <button class="btn btn-primary action-button text-nowrap mb-1 float-end" (click)="addAddress()">
          <i class="fa-regular fa-plus"></i> Add Address
        </button>

        <!-- Additional Info Section -->
        <div id="noteSection" class="section-title mb-3 mt-5">
          <h5><i class="far fa-sticky-note me-1"></i>Initial Notes</h5>
          <div class="desc">Include any extra notes or comments relevant to the leadۢ</div>
        </div>

        <div class="form-group mb-4">
          <textarea class="form-control" rows="3" placeholder="Add initial notes about this lead" [(ngModel)]="leads.comments"></textarea>
        </div>

        <!-- Additional Info Section -->
        <div id="additionalSection" class="section-title mb-3 mt-5">
          <h5><i class="far fa-plus-square me-1"></i>Additional Fields</h5>
          <div class="desc">Include as much information as needed to tailor your lead
            details</div>
        </div>
        <div *ngFor="let field of additionalFields; let i = index">
          <div class="row mb-3 align-items-end address-container" *ngIf="field.isDeleted === false">
          <div class="address-tag" *ngIf="field.isDeleted === false">Additional fields <button class="btn p-0 ml-2" (click)="deleteField(i)" *ngIf="i!==0">
              <i class="fa-regular fa-trash-can"></i>
            </button></div>
            <div class="form-group col mt-2">
              <label for="fieldName{{i}}" class="font-weight-normal">Field Name</label>
              <input type="text" class="form-control" name="fieldName{{i}}" [(ngModel)]="field.fieldName">
            </div>
            <div class="form-group col mt-2">
              <label for="fieldValue{{i}}" class="font-weight-normal">Field Value</label>
              <input type="text" class="form-control" name="fieldValue{{i}}" [(ngModel)]="field.fieldValue">
            </div>
          </div>
          </div>


        <button class="btn btn-primary action-button text-nowrap mb-1 float-end" (click)="addField()">
          <i class="fa-regular fa-plus"></i> Add More
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <!-- Footer Buttons -->
  <div class="text-right">
    <button type="button" class="btn btn-sm action-button-cancel me-2" (click)="modalClose()">Cancel</button>
    <button type="button" (click)="onSubmit()" [disabled]="this.isDataSaving"
      class="btn btn-primary btn-sm action-button">Create Lead</button>&nbsp;&nbsp;
  </div>
</div>
