<div class="container">
  <div class="page-head-container" style="margin: -5px -5px 0.5rem -5px !important;">
    <h4 class="page-title"><i class="fa-regular fa-rectangle-history-circle-user"></i> {{ "Manage Leads" | translate }}
      <span style="
      font-size: 11px;
      color: #fd0075;
      margin-left: 10px;" *ngIf="selectedLeads?.length > 0">{{selectedLeads?.length}} Selected</span>
    </h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">

      <button class="btn btn-primary action-button text-nowrap"
        [hasPermission]="{ access: 'addUpdateLead', action: 'hide' }" (click)="addNewLead(null)"><i
          class="fa-light fa-plus"></i> {{ "New" | translate }}</button>
      <button class="btn btn-sm btn-outline-dark action-button-outline ml-1 " (click)="addAssignee()">
        <i class="fa-regular fa-users"></i>
        Bulk Assign</button>
      <button class="btn btn-sm btn-outline-dark action-button-outline ml-1 " (click)="exportLeads()">
        <img src="../../../../assets/img/icons/excel-225.png" height="11" />
        Export Leads</button>
      <button class="btn btn-sm btn-outline-dark action-button-outline ml-1 " (click)="toggleFilter()"><i
          class="fa-light fa-filter-list"></i>
        {{ "Apply Filters" | translate }}</button>
    </div>
  </div>
  <ng-container *ngIf="sharedService.isProjectChangeInProc">
    <app-project-loading-indicator />
  </ng-container>

  <ng-container *ngIf="!sharedService.isProjectChangeInProc">
    <div [@slide]="slideState" class="filter-container">
      <app-leads-filters (onHideRequest)="onHideFilterRequest()" (onSearchRequest)="onSearchRequest($event)" />
    </div>

    <div class="search-container">
      <div data-toggle="buttons">
        <label class="btn btn-outline-secondary btn-sm form-check-inline" style="border-color: #ccc">
          <input type="radio" name="options" id="option1" (click)="onStatusFilterChange(0)" checked> All
        </label>

        <label *ngFor="let status of leadStatusList"
          class="btn btn-outline-secondary btn-sm form-check-inline btn-status-check"
          [style]="leadsFilter.statusId === status.id ? 'background-color:' +  status.bgColor + ';color:' + status.color + ';border-color: '+status.color+'': 'border-color: #e7e7e7'">
          <input type="radio" name="options" id="option1" (click)="onStatusFilterChange(status.id)"> {{status.name}}
        </label>
        <label class="btn btn-outline-secondary btn-sm form-check-inline" style="border-color: #ccc">
          <input type="radio" name="options" id="option1" (click)="onStatusFilterChange(-1)"> Unassignee
        </label>

      </div>
    </div>

    <div>
      <div class="table-responsive" style="overflow-y: auto; height: calc(100vh - 265px);"
        *ngIf="!isLoading; else loader">
        <table class="table table-hover" *ngIf="
        allleads != null && allleads.length > 0;
      else noData
    ">
          <thead class="sticky-header">
            <tr>
              <th>
                <p-checkbox #headerChk class="filter-checkbox" [binary]="true"
                  (click)="toggleAllChecks(headerChk)"></p-checkbox>
              </th>
              <th style="width: 20px;">#</th>
              <th style="width: 200px;">Lead Name</th>

              <th>Status</th>

              <th style="width: 100px;">Lead Type</th>
              <th style="width: 100px;">Group</th>
              <th style="width: 100px;">Source</th>
              <th style="width: 80px;">Entity Type</th>

              <th style="width: 85px;">Last Updated</th>
              <th style="width: 85px;">Last Remarks</th>
              <th style="width: 85px;">Date Created</th>
              <th>Assignee</th>
              <!-- <th style="width: 130px;">Contact Person</th>
              <th style="width: 130px;">Concern Person</th> -->
              <th style="width: 130px;">Phone No</th>
              <th style="width: 130px;">Mobile No</th>
              <th style="width: 150px;">Email</th>
              <th>Lead Owner</th>
              <th>Updated By</th>
              <!-- <th>Due Date</th> -->
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of allleads;let rowIndex = index">
              <td>
                <ng-container>
                  <p-checkbox name="convertedToLead{{item}}" class="filter-checkbox" (click)="toggleCheck()"
                    [(ngModel)]="item.selected" [binary]="true"></p-checkbox>
                </ng-container>
              </td>
              <td>
                <div class="">{{ item.id }}</div>
              </td>
              <td class="leadname-td">
                <div class="d-flex align-items-center leadname-container">

                  <div class="leadname">{{ item.name }}</div>
                  <div class="leadname-btn-group">
                    <button (click)="viewLead(item)" title="View Lead" class="action-button-tbl-sm"><i
                        class="fa-regular fa-eye"></i>
                    </button>
                    <button (click)="addNewLead(item)" title="Edit Lead" class="action-button-tbl-sm"><i
                        class="fa-solid fa-pencil"></i>
                    </button>
                    <button (click)="openEventAddEditView(item.id)" class="action-button-tbl-sm"
                      title="Schedule an Event"><i class="fa-regular fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </td>

              <td>
                <div class="status bg border badge rounded-pill" [ngStyle]="" [style.backgroundColor]="item.bgColor"
                  [style.borderColor]="item.color + '!important'">
                  <span [style.color]="item.color">{{item.leadStatus}}</span>
                </div>
              </td>

              <td>
                <div class="ellipsis" [style.textAlign]="!item.leadType && 'center'" style="width: 100px;">
                  {{!item.leadType ? '-': item.leadType}}</div>
              </td>
              <td>
                <div class="ellipsis" [style.textAlign]="!item.groups && 'center'" style="width: 100px;">
                  {{!item.groups ? '-': item?.groups?.split(';')[1]}}</div>
              </td>
              <td>
                <div class="ellipsis" [style.textAlign]="!item.leadSource && 'center'" style="width: 100px;">
                  {{!item.leadSource ? '-': item.leadSource}}</div>
              </td>
              <td>
                <div class="ellipsis" [style.textAlign]="!item.leadEntityType && 'center'" style="width: 100px;">
                  {{!item.leadEntityType ? '-': item.leadEntityType}}</div>
              </td>

              <td>
                <div class="ellipsis" style="width: 85px;">{{ item.dateUpdated | date }}</div>
              </td>
              <td>
                <div class="ellipsis" style="width: 85px;"  [pTooltip]="item.lastRemarks ? tooltipContent : null"  tooltipPosition="bottom"> {{!item.lastRemarks ? '-': item.lastRemarks}}
                  <ng-template #tooltipContent>
                    <div class="flex align-items-center" *ngIf="item.lastRemarks">
                    <p>{{item.lastRemarks}}</p>
                    </div>
                  </ng-template>
                </div>
              </td>
              <td>
                <div class="ellipsis" style="width: 85px;">{{ item.dateCreated | date }}</div>
              </td>
              <td> <div class="likers-group" *ngIf="item.firstAssignee; else emptyCell">
                <ng-container *ngFor="let item of item?.assigneeList?.slice(0, 3) ;  let i = index">
                  <img [src]="item.profileImageUrl" [alt]="item.name" [title]="item.name" role="button">
                </ng-container>
              </div>
              <ng-template #emptyCell>
                <b>Unassigned</b>
              </ng-template></td>
              <!-- <td [title]="item?.firstAssignee">
                <div class="d-flex align-items-center ellipsis" *ngIf="item.firstAssignee; else emptyCell">
                  <img [src]="item?.firstAssignee?.split(';')[2]" class="assignee-img" />
                  <span>{{ item?.firstAssignee?.split(';')[1] }}</span>
                </div>
                <ng-template #emptyCell>
                  <b>Unassigned</b>
                </ng-template>
              </td> -->
              <td>
                <div class="ellipsis" style="width: 130px;" [style.textAlign]="!item.phoneNumber && 'center'"><i
                    *ngIf="item.phoneNumber" class="fas fa-phone" style="font-size: 11px;"></i> {{ item.phoneNumber ?
                  (item.phoneNumber | slice: 0:11) :
                  '-' }}</div>
              </td>
              <!-- <td>{{ item.dueDate | date }}</td> -->
              <td>
                <div class="ellipsis" style="width: 130px;" [style.textAlign]="!item.mobileNumber && 'center'"><i
                    *ngIf="item.mobileNumber" class="fas fa-mobile-alt" style="font-size: 11px;"></i> {{
                  item.mobileNumber ? (item.mobileNumber | slice: 0:11) :
                  '-' }}</div>
              </td>
              <td>
                <div class="ellipsis" style="width: 150px;" [style.textAlign]="!item.email && 'center'">{{ !item.email ?
                  '-' : item.email }}
                </div>
              </td>
              <td>
                {{ item.createdBy }}
              </td>

              <td>
                {{ item.updatedBy ? item.updatedBy : 'NA' }}
              </td>
              <td>

                <div class="btn-group">
                  <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" style="height: 25px;"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Action
                  </button>
                  <div class="dropdown-menu">
                    <button class="dropdown-item table-action-btn" (click)="addDealModal(item)"><i
                        class="fa-regular fa-handshake"></i>
                      &nbsp;
                      Convert to Deal</button>
                    <button class="dropdown-item table-action-btn mb-2" (click)="deleteLeadById(item.id)"
                      title="Delete Lead"><i class="fa-regular fa-trash"></i>
                      &nbsp;
                      Delete</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination DIV -->
      <div class="pagination-container">
        <div class="d-flex align-items-center">
          <span class="mr-3">Page {{ currentPage }} of {{ calculateTotalPages(totalCount, pageSize) }} of
            {{totalCount}}</span>
          <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="
        width: 68px;
        text-align: center;
        font-size: 12px;">
            <option class="dropdown-item d-flex align-items-center" value="10">10</option>
            <option class="dropdown-item d-flex align-items-center" value="20">20</option>
            <option class="dropdown-item d-flex align-items-center" value="50">50</option>
            <option class="dropdown-item d-flex align-items-center" value="100">100</option>
          </select>
        </div>



        <div style="max-width: 500px;">
          <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
            <i class="fa-sharp fa-solid fa-angles-left"></i></button>
          <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
            [disabled]="currentPage === 1"><i class="fa-sharp fa-solid fa-angle-left"></i></button>
          <ng-container *ngFor="let page of displayedPages">
            <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
              [class.active]="page === currentPage">
              {{ page }}
            </button>
          </ng-container>
          <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
            [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
              class="fa-sharp fa-solid fa-angle-right"></i></button>
          <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
            [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
              class="fa-sharp fa-solid fa-angles-right"></i></button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="No lead has been created yet." height="calc(100vh - 315px)"></app-nodatafound>
  </ng-template>
</div>
