<div class="container-fluid">
  <div class="bg-body-whitish" *ngIf="overview != null;
      else !isLoading ? noData : loader">
    <div class="page-head-container" style="margin: -5px -5px 0.5rem -5px !important;">
      <h4 class="page-title"><i class="fa-regular fa-bullseye"></i> {{ "Campaign Overview" | translate }}
      </h4>
      <div class="d-flex align-items-center">
      </div>


    </div>
    <div class="row mt-4">

      <!-- Client Information Card -->
      <div class="col-md-6">
        <div class="card box-charts mb-4 h-100">
          <div class="card-body custom-card">
            <div class="client-card client-info flex-grow-1">
              <div class="client-datials flex-grow-1">
                <h5 class=""></h5>
                <h5 class="" *ngIf="overview">
                  <div class="status bg border badge rounded-pill fw-600" style="padding-top: 6px;"
                    [style.backgroundColor]="overview.summary.bgColor"
                    [style.borderColor]="overview.summary.color + '!important'">
                    <span [style.color]="overview.summary.color">
                      Status : {{overview.summary.statusName}}</span>
                  </div>
                </h5>
                <h3 class=" mt-n1 mb-1" style="font-size: 18px; text-transform: capitalize;">
                  {{
                  overview.summary.name}}</h3>
                <p class="mb-1" style="font-size: 12px;">
                  {{overview?.summary.campaignTypeName}}
                </p>
                <p class="mt-1" style="font-size: 11px;" *ngIf="overview?.summary.description">
                  Campaign Description:
                  {{overview?.summary.description}}
                </p>
                <p class="mb-1" style="font-size: 11px;" *ngIf="overview?.summary.clientName">
                  Campaign Client: {{overview?.summary.clientName}}</p>
                <p class="mt-1" style="font-size: 11px;" *ngIf="overview?.summary.productName">
                  Campaign Product: {{overview?.summary.productName}}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <hr class="hr-transparent bg-white-transparent-2 mt-0" style="margin-bottom: 10px;">
                <div class="row" style="margin-bottom: 10px;">
                  <div class="col-8 col-lg-7">
                    <div class="mt-1">
                      <div style="color:#189ee3 ;">Created By</div>
                      <div class="font-weight-600">{{overview?.summary.createdByUser}}</div>
                      <div class="mt-3" style="color: #189ee3 ;">Start Date</div>
                      <div class="font-weight-600">{{overview?.summary.startDate | date}}</div>
                    </div>
                  </div>
                  <div class="col-4 col-lg-5">
                    <div style="color: #189ee3 ;">Created Date:</div>
                    <div class="font-weight-600" style="white-space: nowrap;">{{overview?.summary.dateCreated | date}}
                    </div>
                    <div class="mt-3" style="color: #189ee3 ;">Due Date</div>
                    <div class="font-weight-600 ellipsis">
                      {{overview?.summary.endDate | date}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Case Performance Card (Grid Layout) -->
      <div class="col-md-3">
        <div class="card box-charts mb-4 h-100">
          <h5 class="card-heading-1">
            Campaign Budget
          </h5>
          <ul class="list-group borderless">
            <li class="list-group-item borderless d-flex justify-content-between align-items-center">
              <label>Allocated Budget </label>
              <span>{{overview.summary.allocatedBudget | currency: 'Rs '}}</span>
            </li>
            <li class="list-group-item borderless d-flex justify-content-between align-items-center">
              <label>Total Budget Used</label>
              <span>{{overview.summary.totalBudgetUsed? 0: overview.summary.totalBudgetUsed | currency: 'Rs '}}</span>
            </li>
            <li class="list-group-item borderless d-flex justify-content-between align-items-center">
              <label>Remaining Budget </label>
              <span>{{overview.summary.remainingBudget | currency: 'Rs '}}</span>
            </li>
          </ul>


        </div>
      </div>
      <!-- Case Performance Card (Grid Layout) -->
      <div class="col-md-3">
        <div class="card box-charts mb-4 h-100">
          <h5 class="card-heading-1">
            Campaign Statistics
          </h5>
          <div class="card-body">
            <!-- Task Completion Rate -->
            <div class="mb-4">
              <div class="list-group-item borderless d-flex justify-content-between align-items-center">
                <h6>Total Active Leads </h6>
                <div>
                  {{overview.summary.totalActiveLeads}}
                </div>
              </div>
            </div>
            <!-- Resource Utilization -->
            <div class="mb-4">
              <div class="list-group-item borderless d-flex justify-content-between align-items-center">
                <h6>Qualified Leads</h6>
                <div>
                  {{overview.summary.qualifiedLeads}}
                </div>
              </div>
            </div>
            <!-- Client Satisfaction Score -->
            <div class="mb-4">
              <div class="list-group-item borderless d-flex justify-content-between align-items-center">
                <h6>Converted Leads</h6>
                <div>
                  {{overview.summary.convertedLeads}}
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="list-group-item borderless d-flex justify-content-between align-items-center">
                <h6>Total Active Agents</h6>
                <div>
                  {{overview.summary.totalActiveAssignees}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Analytics Card (Line Chart) -->
      <!-- Daily Time Tracking Chart -->
      <div class="col-md-6 mt-3" *ngIf="this.overview.campaignleadStats[0].dayNumber > 0">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title card-heading">Daily Lead Tracking</h5>
            <p-chart type="line" [data]="dailyTimeTrackingData" [options]="chartOptions" height="200"></p-chart>
          </div>
        </div>
      </div>
      <!-- Monthly Actual vs Estimated Cost Chart -->
      <div class="col-md-6 mt-3" *ngIf="this.overview.campaignleadStats[0].monthNumber > 0">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Monthly Lead Tracking</h5>
            <p-chart type="line" [data]="monthlyActualvsEstimatedCostData" [options]="chartOptions"
              height="200"></p-chart>
          </div>
        </div>
      </div>

      <!-- Analytics Card (Line Chart) -->
      <div class="col-md-6 mt-3">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title card-heading">Campaign Assets</h5>
              <ng-container *ngIf="overview?.campaignAssets?.length > 0; else noClientsData">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset Type</th>
                      <th>Asset URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let assets of overview?.campaignAssets">
                      <td>{{ assets.assetName }}</td>
                      <td>{{ assets.assetTypeName }}</td>
                      <td>
                        <a [href]='assets.assetURL' target="_blank" rel="noopener noreferrer"
                          class="btn btn-sm btn-outline-dark action-button-outline text-nowrap"><i
                            class="fa-regular fa-eye"></i> View</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>

              <ng-template #noClientsData>
                <div class="no-track" style="height: 200px;">
                  <h5>Nothing tracked yet</h5>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>
  <ng-template #noData>
    <app-nodatafound message="{{'No statistics are there to show.' | translate }}"
      height="calc(100vh - 285px)"></app-nodatafound>
  </ng-template>
</div>
