<div class="modal-body">
  <div class="d-flex mb-3 mt-3">
    Create a lead group to enhance collaboration and prioritize outreach, ensuring
    efficient management of your leads.
  </div>

  <form class="mt-3">
    <input type="hidden" />
    <div class="form-group">
      <label for="name">Name</label>
      <input type="text" class="form-control" id="name" [(ngModel)]="leadGroup.groupName" name="name"
        appSpecialCharsBlocker inputValidator [required]=true [min]=3 [max]=255>
    </div>
    <div class="form-group">
      <label for="groupName">Group Type</label>
      <select name="group" [(ngModel)]="leadGroup.groupType" class="form-control custom-select">
        <option *ngFor="let item of groupTypes" [value]="item.type">{{item.type}}</option>
      </select>
    </div>
    <div *ngIf="priorityLevels && priorityLevels.length > 0" class="form-group">
      <label for="priorityName">Priority</label>
      <select name="priority" [(ngModel)]="leadGroup.priorityLevelId" class="form-control custom-select">
        <option *ngFor="let item of priorityLevels" [value]="item.id">{{ item.priorityName }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="description">Description</label>
      <textarea class="form-control" id="description" [(ngModel)]="leadGroup.description" name="description" rows="5"
        appSpecialCharsBlocker inputValidator [min]=3 [max]=1000></textarea>
    </div>

    <hr class="mb-4">

    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">{{leadGroup.id > 0 ?
        'Update Group' : 'Add Group'}}</button>
      <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
    </div>
  </form>
</div>
