import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Result } from 'src/app/models/Result';
import { User, Project } from 'src/app/models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables, environment } from 'src/environments/environment';
import { UserSetupComponent } from '../../users/user-setup/user-setup.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { CheckInRequestDetail } from 'src/app/models/CheckInRequestDetail';
import { AttendanceConfirmationComponent } from '../../common/attendance-confirmation/attendance-confirmation.component';
import { MessageService } from 'primeng/api';
import { ToastComponent } from '../../common/toast/toast.component';
import { Task, TaskCheckInState } from 'src/app/models/Task';
import { SoftwareTrial } from 'src/app/models/SoftwareTrial';
import { TrialAlertDialogComponent } from '../../modals/trial-alert-dialog/trial-alert-dialog.component';
import { ChangePasswordComponent } from '../../password/change-password/change-password.component';
@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css'],
})
export class MasterComponent {
  @ViewChild('toast') toast!: ToastComponent;
  @ViewChild(AttendanceConfirmationComponent)
  attendanceConfirmationModal: AttendanceConfirmationComponent;
  markAttendanceDetails: CheckInRequestDetail;
  private title = 'Client Track';
  whatsApp = '+923468221032';
  selectedProject: Project = null;
  userInfo: User;
  userProjects: Project[];
  trial: SoftwareTrial = null;
  languageList: any;
  isConfirm: boolean = false;
  isLocation: boolean = false;
  clientId:number;
  checkType: string;

  show = false;
  message = '';

  @Output() retry = new EventEmitter<void>();

  constructor(
    public router: Router,
    public authService: AuthService,
    public apiService: ApiService,
    private messageService: MessageService,
    private sharedService: SharedService,
    private dialogService: DialogService,
    private translate: TranslateService
  ) {
    this.languageList = GlobalVariables.languageList;
    this.userInfo = authService.currentUserValue;
    this.userInfo.roleId = this.userInfo.roleInfo.id;
    this.getUserProjects();
    this.validateLicense();
    this.loadPermissions();
    this.sharedService.onProjectUpdates.subscribe(() => {
      this.getUserProjects();
    });
    this.clientId = 0;
    this.sharedService.onTaskCheckIn.subscribe((check: TaskCheckInState) => {
      if(check){
        this.clientId = check.clientId;

      if (check.automark === true) {

        this.isConfirm = false;
        this.isLocation = true;
        this.markAttendance('check-in');
      } else if (check.automark === false) {
        this.isConfirm = true;
        this.isLocation = true;
        setTimeout(() => {
          if (this.attendanceConfirmationModal) {
            this.attendanceConfirmationModal.message =
              'The task has been started. Would you like to mark your attendance for the day as well?';
            this.attendanceConfirmationModal.showModal('check-in');
          } else {
            console.error('attendanceConfirmationModal is not initialized.');
          }
        });
      }
    }
  });

    this.sharedService.onTaskCheckOut.subscribe((check: TaskCheckInState) => {
      if(check){
        this.clientId = check.clientId;

      if (check.automark === true) {
        this.isConfirm = false;
        this.markAttendance('check-out');
      } else if (check.automark === false) {
        this.isConfirm = true;
        setTimeout(() => {
          if (this.attendanceConfirmationModal) {
            this.attendanceConfirmationModal.message =
              'The task has been stopped. Would you like to check out for the day?';
            this.attendanceConfirmationModal.showModal('check-out');
          } else {
            console.error('attendanceConfirmationModal is not initialized.');
          }
        });
      }
    }
    });
  }
  ngAfterViewInit(): void {
    this.getSoftwareTrialInfo();
  }

  isDevelopment(): boolean {
    if (environment.apiUrl === 'https://dev-crm-api.softnology.com/api') {
      return true;
    }
    return false;
  }

  changeSiteLanguage(localeCode: string): void {
    const selectedLanguage = GlobalVariables.languageList
      .find((language) => language.code === localeCode)
      ?.label.toString();
    if (selectedLanguage) {
      GlobalVariables.siteLanguage = selectedLanguage;
      this.translate.use(localeCode);
    }
    this.sharedService.triggerLangUpdates(this.translate.currentLang);
    const currentLanguage = this.translate.currentLang;
  }


  getUserProjects(): void {
    this.apiService.getUserProjects().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.userProjects = resp.data;
          this.selectedProject = this.userProjects.find((x) => x.isDefault);
          GlobalVariables.selectedProjectId = this.selectedProject.id;
          GlobalVariables.selectedProject = this.selectedProject;

          this.sharedService.triggerProjectChange(this.selectedProject.id);
        } else {

        }
      },
      error: (error) => {
        console.error('error:', error);
        if (error.status === 401 || error.status === 403) {
          this.router.navigate(['login']);
        }
      },
      complete: () => {},
    });
  }
  getSoftwareTrialInfo(): void {
    this.apiService.GetSoftwareTrialInfo().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.trial = SoftwareTrial.map(resp.data);
          if (this.trial) {
            const expired = this.trial.checkIfExpired(this.whatsApp);
            if (expired) {
              this.dialogService.open(TrialAlertDialogComponent, {
                modal: true,
                showHeader: false,
                width: '40%',
                contentStyle: {
                  overflow: 'auto',
                  padding: '0',
                  borderRadius: '10px',
                },
                baseZIndex: 10000,
                maximizable: true,
                data: {
                  title: 'Trial Expired',
                  message: expired,
                },
              });
            }
          }
        } else {
          console.log(resp);
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }

  upgradeNow() {
    alert(`To upgrade, please contact us on WhatsApp at ${this.whatsApp}.`);
  }

 
  onProjectChange(p: Project): void {
    this.selectedProject = p;
    GlobalVariables.selectedProjectId = p.id;
    GlobalVariables.selectedProject = p;
    this.sharedService.triggerProjectChange(p.id);
  }

  editProfile(user: User) {
    const modalRef = this.dialogService.open(UserSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'Edit Profile',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        user: user,
      },
    });
    modalRef.onClose.subscribe(() => {});
  }

  loadPermissions() {
    this.apiService
      .getUserRoleByUserId(this.authService.currentUserValue.id)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            if (resp.data) {
              var role = JSON.parse(resp.data);
              const permissions = role.permissions;
              // Globally Setting Permissions
              this.authService.setCurrentPermissions(permissions);
              // End Globally Setting Permissions
            }
          }
        },
        error: (error) => {
          console.error('error:', error);
        },
      });
  }
  
  validateLicense() {
    this.apiService
      .validateLicense(this.authService.currentUserValue.id)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'InValid') {
              this.dialogService.open(TrialAlertDialogComponent, {
                modal: true,
                showHeader: false,
                width: '40%',
                contentStyle: {
                  overflow: 'auto',
                  padding: '0',
                  borderRadius: '10px',
                },
                baseZIndex: 10000,
                maximizable: true,
                data: {
                  title: 'Oops!! Authentication Error',
                  message: resp.message,
                },
              });
            this.authService
            .setAuthToken('')
            .then((result) => {
              localStorage.clear();
              this.router.navigate(['/login']);
            })
            .catch((error) => {
              console.error(error);
            });
            
          }
        },
        error: (error) => {
          console.error('error:', error);
        },
      });
  }

  changePassword(user: User) {
    const modalRef = this.dialogService.open(ChangePasswordComponent, {
      modal: true,
      showHeader: true,
      header: 'Change password',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        user: user,
      },
    });
    modalRef.onClose.subscribe(() => {});
  }
  logout() {
    this.authService
      .setAuthToken('')
      .then((result) => {
        localStorage.clear();
        this.router.navigate(['/login']);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  confirmMarkAttendance(obj) {
    if (obj.confirm === true) {
      this.markAttendance(obj.type);
    }
    if (navigator.geolocation) {
      this.attendanceConfirmationModal.hideModal();
    }
  }

  markAttendance(checkType: 'check-in' | 'check-out') {
    const formattedCheckType =
      checkType === 'check-in' ? 'Check In' : 'Check Out';
    this.checkType = formattedCheckType;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.markAttendanceDetails = new CheckInRequestDetail();
            if (this.clientId) {
             this.markAttendanceDetails.clientId = this.clientId;
            }
          this.markAttendanceDetails.employeeID =
            this.authService.currentUserValue.hrmsEmpId;
          this.markAttendanceDetails.checkType = formattedCheckType;
          this.markAttendanceDetails.source = 'TaskTrack';
          this.markAttendanceDetails.latitude =
            position.coords.latitude.toString();
          this.markAttendanceDetails.longitude =
            position.coords.longitude.toString();

          this.apiService
            .markAttendanceByHrmsPlugin(this.markAttendanceDetails)
            .subscribe({
              next: (data: any) => {
                this.isLocation = false;
                if (data && data.status === 'success') {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Info',
                    detail: data.message,
                    life: 3000,
                  });
                }
                else{
                  this.messageService.add({
                    severity: 'error',
                    summary: 'error',
                    detail: data.message,
                    life: 3000,
                  });
                }
              },
              error: (error) => {
                this.toast.showToast(
                  'An error occurred while marking attendance.'
                );
              },
            });
        },
        (err) => {
          this.isLocation = true;
          this.handleGeolocationError(err);
        }
      );
    } else {
      this.toast.showToast('Geolocation is not supported by this browser.');
    }
  }

  handleGeolocationError(err: GeolocationPositionError) {
    this.isLocation = true;
    switch (err.code) {
      case err.PERMISSION_DENIED:
        this.toast.showToast(
          'You denied the location. You cannot mark attendance until you allow location.'
        );
        break;
      case err.POSITION_UNAVAILABLE:
        this.toast.showToast('Location information is unavailable.');
        break;
      case err.TIMEOUT:
        this.toast.showToast('The request to get user location timed out.');
        break;
      default:
        this.toast.showToast('An unknown error occurred.');
        break;
    }
  }

  showPosition(position: GeolocationPosition) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.markAttendanceDetails = new CheckInRequestDetail();
          this.markAttendanceDetails.employeeID =
            this.authService.currentUserValue.hrmsEmpId;
          this.markAttendanceDetails.checkType = this.checkType;
          this.markAttendanceDetails.source = 'TaskTrack';
          this.markAttendanceDetails.latitude =
            position.coords.latitude.toString();
          this.markAttendanceDetails.longitude =
            position.coords.longitude.toString();

          this.apiService
            .markAttendanceByHrmsPlugin(this.markAttendanceDetails)
            .subscribe({
              next: (data: any) => {
                if (data && data.status === 'success') {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Info',
                    detail: data.message,
                    life: 3000,
                  });
                }
              },
              error: (error) => {
                this.toast.showToast(
                  'An error occurred while marking attendance.'
                );
              },
            });
        },
        (err) => {
          this.isLocation = true;
          this.handleGeolocationError(err);
        }
      );
    } else {
      this.toast.showToast('Geolocation is not supported by this browser.');
    }
  }

  requestLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition.bind(this),
        this.handleGeolocationError.bind(this)
      );
    } else {
      this.toast.showToast('Geolocation is not supported by this browser.');
    }
  }
}
